import moment from "moment";

import ConfigService from "./ConfigService";
import HttpService from "./HttpService";




export default class getAuthResourceServiceHost
{
    constructor(authResourceDomain)
    {
        this.configService = new ConfigService();
        this.domain = authResourceDomain || this.configService.getAuthResourceServiceHost();
    }



    async fetchVersion(token)
    {
        const path = "/api/admin/version";
        var response = await HttpService.get(this.domain, path, token);
        return response;   
    }

    async pingCore(token) 
    {
        const path = "/api/admin/ping";
        var response = await HttpService.get(this.domain, path, token);
        return response;
      }
    
  


      async getScopesByRoles(roles, token)
      {
        var path = "/api/resources/gcauthscope/role/{firstrole}".replace("{firstrole}", roles[0]);
        var additionalRoles = roles.splice(1);
        if (additionalRoles.length > 0)
        {
            var queryString = additionalRoles.map((v, i) => "role=" + v).join("&");
            path = path + "?" + queryString;
        }
        // any additional roles are added as query string values
        var response = await HttpService.get(this.domain, path, token);
        return response;
      }


      async getAppUsers(token)
      {
        var path = "/api/users/gcid/lgs/gcauthclient";
        var response = await HttpService.get(this.domain, path, token);
        return response;
      }

      async getAppRoles(token)
      {
        var path = "/api/resources/gcauthrole/app/lgs";
        var response = await HttpService.get(this.domain, path, token);
        return response;
      }

      async postAppUser(user, token)
      {
        var path = "/api/users/gcid/lgs/gcauthclient";
        var response = await HttpService.postJson(this.domain, path, token, JSON.stringify(user));
        return response;
      }

      async deleteAppUser(userId, token)
      {
        var path = "/api/users/gcid/lgs/gcauthclientdeleterequest/id/" + userId;
        var response = await HttpService.postJson(this.domain, path, token, JSON.stringify(userId));
        return response;
      }

      async postAppUserEnabledFlag(userId, newFlagStatusBool, token)
      {
        var path = "/api/users/gcid/lgs/gcauthclientenabledflagrequest/id/" + userId + "/enabled/" + newFlagStatusBool.toString();
        var response = await HttpService.postJson(this.domain, path, token, JSON.stringify(newFlagStatusBool));
        return response;
      }

}