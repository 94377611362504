export default class InOutGridModel
{
    constructor(lotData, groupByRule, totalSales, totalBoxes, totalPallets, hasVarieties, hasMethods, hasActiveQuantities, hasActiveSaleAmounts, inOutGridLines)
    {
        this.lotData = lotData || null;
        this.groupByRule = groupByRule || "";
        this.totalSales = totalSales || 0;
        this.totalBoxes = totalBoxes || 0;
        this.totalPallets = totalPallets || 0;
        this.hasVarieties = hasVarieties || false;
        this.hasMethods = hasMethods || false;
        this.hasActiveQuantities = hasActiveQuantities || false;
        this.hasActiveSaleAmounts = hasActiveSaleAmounts || false;
        this.inOutGridLines = inOutGridLines || [];

        
    }

    static toInOutGridModel(lotData, groupByRule, inOutAdjustments)
    {
        var model = new InOutGridModel(lotData, groupByRule);
        var reducedRepackLines = InOutGridModel.toReducedRepackLines(lotData, groupByRule);
        var reducedByKeyLines = InOutGridModel.toReducedByKey(reducedRepackLines, inOutAdjustments);
        // If we are showing receipts, then let's "true them up" to the actual receipts in case we 
        // ended up allocating a different amount than the actual receipt.
        var finalizedLines = (groupByRule === 'receipts') ? 
            InOutGridModel.toTruedUpDiff(lotData.receipts.lines, reducedByKeyLines, groupByRule)
            : InOutGridModel.toTruedUpDiff(lotData.sales.lines, reducedByKeyLines, groupByRule);
        model.inOutGridLines = finalizedLines;
        model.totalBoxes = InOutGridModel.toTotalBoxes(finalizedLines);
        model.totalSales = InOutGridModel.toTotalSales(finalizedLines);
        model.totalPallets = (lotData.lot.palletCount) ? lotData.lot.palletCount: 0;
        model.hasVarieties = finalizedLines.some(r => r.varietyId !== "0");
        model.hasMethods = finalizedLines.some(r => r.methodId !== "0");
        model.hasActiveQuantities = finalizedLines.some(r => r.quantity !== r.activeQuantity);
        model.hasActiveSaleAmounts = finalizedLines.some(r => r.saleAmount !== r.activeSaleAmount);
        // set the sort
        // Commodity Size, Style, Variety, Method
        var defaultSortedGridLines = model.inOutGridLines.sort((a, b) => (
            a.commodity.localeCompare(b.commodity) ||
            a.size.localeCompare(b.size) ||
            a.style.localeCompare(b.style) ||
            a.variety.localeCompare(b.variety) ||
            a.method.localeCompare(b.method)
        ));
        model.inOutGridLines = defaultSortedGridLines;
        return model;
    }


    static toTruedUpDiff(lotDataSourceLines, reducedKeyLines, groupByRule)
    {
        var result = [];
        // We need to figure out - by key, how many total receipts there are
        var reduced = InOutGridModel.toSourceReducedByKey(lotDataSourceLines);
        reduced.forEach(r =>
            {
                var key = (groupByRule==="receipts") ? "receipt-": "sale-";
                key = key + InOutGridModel.toItemKey(r);
                var keyLine = reducedKeyLines.find(k => k.lineKey === key);
                if (keyLine.quantity !== r.qty)
                {
                    // check to see if an adjustment on quantity was already made on this record. 
                    // If not, then we want to set the activeQuantity and recalculate the average price!
                    if (keyLine.activeQuantity === keyLine.quantity)
                    {
                        keyLine.activeQuantity = r.qty;
                        keyLine.price =
                            (keyLine.activeQuantity === 0 || keyLine.activeSaleAmount === 0) ? 
                            0
                            : keyLine.activeSaleAmount / keyLine.activeQuantity;
                    }
                    keyLine.quantity = r.qty;
                }
                result.push(keyLine);
            }
        );
        return result;
    }


    static toTotalBoxes(reducedByKeyLines)
    {
        var result = reducedByKeyLines.reduce((r, o) => 
        {
            return r + o.activeQuantity
        }, 0);
        return result;
    }

    static toTotalSales(reducedByKeyLines)
    {
        var result = reducedByKeyLines.reduce((r, o) => 
        {
            return r + o.activeSaleAmount
        }, 0);
        return result;
    }

    static toReducedRepackLines(lotData, groupByRule)
    {
        var isGroupedByReceipt = (groupByRule === "receipts");
        var result = lotData.reducedRepacks.map((l, i) => 
        {
            return {
              lineKey: (isGroupedByReceipt) ? InOutGridModel.toReceiptKey(l): InOutGridModel.toSaleKey(l),
              groupByRule: this.groupByRule,
              //itemId: (isGroupedByReceipt) ? l.inputItem.item.id: l.outputItem.item.id,
              commodity: (isGroupedByReceipt) ? l.inputItem.commodity.name : l.outputItem.commodity.name,
              //description: (isGroupedByReceipt) ? l.inputItem.item.name: l.outputItem.item.name,
              sizeId: (isGroupedByReceipt) ? l.inputItem.size.id: l.outputItem.size.id,
              size: (isGroupedByReceipt) ? l.inputItem.size.name: l.outputItem.size.name,
              styleId: (isGroupedByReceipt) ? l.inputItem.style.id: l.outputItem.style.id,
              style: (isGroupedByReceipt) ? l.inputItem.style.name: l.outputItem.style.name,
              varietyId: (isGroupedByReceipt) ? l.inputItem.variety.id: l.outputItem.variety.id,
              variety: (isGroupedByReceipt) ? l.inputItem.variety.name: l.outputItem.variety.name,
              methodId: (isGroupedByReceipt) ? l.inputItem.method.id: l.outputItem.method.id,
              method: (isGroupedByReceipt) ? l.inputItem.method.name: l.outputItem.method.name,
              quantity: (isGroupedByReceipt) ? l.receiptQty: l.saleQty,
              price: l.unitPrice,
              saleAmount: l.saleExtAmount,
              activeQuantity: (isGroupedByReceipt) ? l.receiptQty: l.saleQty,
              activeSaleAmount: l.saleExtAmount
            };
          });
          return result;
    }

    static lineHasAdjustments(lineKey, adjustments)
    {
        if (!adjustments) return false;
        if (adjustments.length < 1) return false;
        if (!adjustments.some(a => a.adjustmentKey === lineKey)) return false;
        return true;
        //return ((adjustments) && (adjustments.find(a => a.adjustmentKey === lineKey)));
    }

    static toLineAdjustment(lineKey, adjustments)
    {
        return adjustments.find(a => a.adjustmentKey === lineKey);
    }


    static toReducedByKey(reducedRepackLines, inOutAdjustments)
    {
        var helper = {};
        var result = reducedRepackLines.reduce(function (r, o) 
        {
          var key = o.lineKey;
          // have we already initialized this line
          var linePreviouslyExists = (helper[key]);
          var lineHasAdjustments = InOutGridModel.lineHasAdjustments(key, inOutAdjustments);
          if (!linePreviouslyExists)
          {
                // generate the line!
                helper[key] = Object.assign({}, o);
          }
          else
          {
              // aggregate values
              helper[key].quantity += o.quantity;
              helper[key].saleAmount += o.saleAmount;
          }
          helper[key].activeQuantity = (lineHasAdjustments) ? InOutGridModel.toLineAdjustment(key, inOutAdjustments).adjustedQuantity: helper[key].quantity;
          helper[key].activeSaleAmount = (lineHasAdjustments) ? InOutGridModel.toLineAdjustment(key, inOutAdjustments).adjustedSaleAmount: helper[key].saleAmount;
          helper[key].price =
            (helper[key].activeQuantity === 0 || helper[key].activeSaleAmount === 0) ? 0
            : helper[key].activeSaleAmount / helper[key].activeQuantity;
          if (!linePreviouslyExists)
          {
              // push it onto the return stack
              r.push(helper[key]);
          }
        return r;
    }, []);
    return result;
}

    static toSourceReducedByKey(sourceLines)
    {
        var helper = {};
        var result = sourceLines.reduce(function (r, o) 
        {
          var key = InOutGridModel.toItemKey(o);
          // have we already initialized this line
          var linePreviouslyExists = (helper[key]);
          if (!linePreviouslyExists)
          {
                // generate the line!
                helper[key] = Object.assign({}, o);
          }
          else
          {
              // aggregate values
              helper[key].qty += o.qty;
          }
          if (!linePreviouslyExists)
          {
              // push it onto the return stack
              r.push(helper[key]);
          }
        return r;
    }, []);
    return result;
    }

    static toSalesReducedByKey(saleLines)
    {
        var helper = {};
        var result = saleLines.reduce(function (r, o) 
        {
          var key = InOutGridModel.toItemKey(o);
          // have we already initialized this line
          var linePreviouslyExists = (helper[key]);
          if (!linePreviouslyExists)
          {
                // generate the line!
                helper[key] = Object.assign({}, o);
          }
          else
          {
              // aggregate values
              helper[key].quantity += o.quantity;
          }
          if (!linePreviouslyExists)
          {
              // push it onto the return stack
              r.push(helper[key]);
          }
        return r;
    }, []);
    return result;
    }

  

    static toItemKey(o)
    {
        var l = o.item;
        return l.commodity.id + "-" + l.size.id + "-" + l.style.id + "-" + l.variety.id + "-" + l.method.id;
    }

    static toReceiptKey(o)
    {
        var l = o.inputItem;
        return "receipt-" + l.commodity.id + "-" + l.size.id + "-" + l.style.id + "-" + l.variety.id + "-" + l.method.id;
    }

    static toSaleKey(o)
    {
        var l = o.outputItem;
        return "sale-" + l.commodity.id + "-" + l.size.id + "-" + l.style.id + "-" + l.variety.id + "-" + l.method.id;
    }
}