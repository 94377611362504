import React, { Component } from "react";
import InOutDetailGrid from "./InOutDetailGrid";
import ReceiptsDetailGrid from "./ReceiptsDetailGrid";
import SalesDetailGrid from "./SalesDetailGrid";
import ServicesDetailGrid from "./ServicesDetailGrid";
import RepackDetailGrid from "./RepackDetailGrid";

export default class LotDetailPanel extends Component 
{
  constructor(props) {
    super(props);
    this.state = {
    };


  }

  componentDidMount() {
    this.refreshDisplayData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedLotId !== this.props.selectedLotId) {
      this.refreshDisplayData();
    }
  }

  refreshDisplayData() {
    if (!this.props.lotData) return;
    console.group("Refreshing Detail Data: ");
    console.groupEnd("done refreshing Detail Data");
  }

 
  render() {
    return (
      <div style={{ marginLeft: "2%", marginRight: "2%" }}>
        <InOutDetailGrid data={this.props.lotData.reducedRepacks} />
        <RepackDetailGrid data={this.props.lotData.repacks.transactions} />
          <ReceiptsDetailGrid
            data={this.props.lotData.receipts.lines}
            onAdjustmentRequest={this.onAdjustmentRequest}
          />
          <SalesDetailGrid
            data={this.props.lotData.sales.lines}
            onAdjustmentRequest={this.onAdjustmentRequest}
          />
          <ServicesDetailGrid
            data={this.props.lotData.services.lines}
            onAdjustmentRequest={this.onAdjustmentRequest}
          />
          
      </div>
    );
  }
}
