import React, { Component } from "react";
import { toUsDollars } from "../../../components/lib/Javascript/jsUtils";




export default class LotReturnTable extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }


    render() {

        const tableData = (this.props.data) ? this.props.data : [{}];
        console.log("Lot Return Data Changed:");
        console.info(tableData);
        return (

            <div
            style={{
              minWidth: "30vw",
              height: "min-content",
              margin: "1vw",
              marginLeft: "60vw"
            }}
          >
            <div>Lot Return
            </div>

            <table
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                width: "98%",
                textAlign: "left",
                backgroundColor: "white"
              }}
            >
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Grower Return:</td>
                <td>{toUsDollars(tableData.growerReturn)}</td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Less Marketing Fee:</td>
                <td>{toUsDollars(tableData.commission)}</td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td>Less Advances:</td>
                <td>{toUsDollars(tableData.advances)}</td>
              </tr>
              <tr style={{ borderBottom: "1px solid black" }}>
                <td style={{fontWeight: "bold"}}>Net Proceeds:</td>
                <td style={{fontWeight: "bold"}}>{toUsDollars(tableData.netProceeds)}</td>
              </tr>
            </table>
          </div>
        


        );
    }
}