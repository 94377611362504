import React, { Component } from "react";
import { Button, Modal, InputGroup, FormControl } from "react-bootstrap";
//import "bootstrap/dist/css/bootstrap.min.css";


export default class AddLotDialog extends Component {

    constructor()
    {
        super();
        this.state = 
        {
            lotId: "",
            lotIdLabel: "Enter a LotId that exists in Famous",
            isError: false 
        }

        this.onAddLotButtonClick = this.onAddLotButtonClick.bind(this);
        this.onLotChange = this.onLotChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState)
    {
        if (prevProps.show !== this.props.show)
        {
            this.setState(
                {
                    lotId: "",
                    lotIdLabel: "Enter a LotId that exists in Famous",
                    isError: false 
                }
            )
        }
    }


    onLotChange(e)
    {
        var newIsError = (e.target.value === "");
        var newLotIdLabel = (newIsError) ? "You must enter a Lot Id": "Enter Lot Id";
        this.setState({
            isError: newIsError,
            lotIdLabel: newLotIdLabel,
            lotId: e.target.value
        });
    }
  
    onAddLotButtonClick(e)
    {
        if (this.state.lotId === "")
        {
            this.setState({
                isError: true,
                lotIdLabel: "You must enter a Lot Id"
            })
        }
        if ((this.state.isError) || (this.state.lotId === "")) return;
        var currLotId = this.state.lotId;
        this.props.onAddLotIdRequest(currLotId);
    }



    render() 
  {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onRequestAddLotDialogClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Lot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <label htmlFor="basic-url" style={{ color: (this.state.isError) ? "red": "black"}}>{this.state.lotIdLabel}</label>
        <InputGroup className="mb-3">
    <FormControl
        id="lotId"
      placeholder="Lot Id"
      aria-label="lotId"
      aria-describedby="basic-addon1"
      onChange={(e) => this.onLotChange(e)}
    />
  </InputGroup>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => this.onAddLotButtonClick(e)}
          >
            Ok
          </Button>
          <Button
            variant="secondary"
            onClick={this.props.onRequestAddLotDialogClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
