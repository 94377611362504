import React, { Component } from "react";
import MultiToggle from "react-multi-toggle";
import "../../../assets/css/multitoggle.css";






const inOutGroupByOptions = [
    { displayName: 'receipts', value: 'receipts' },
    { displayName: 'sales', value: 'sales' }
];

const showSaleDetailOptions = [
    { displayName: 'show', value: 'show' },
    { displayName: 'hide', value: 'hide' }
]

/*
const showContainerOptions = [
    {displayName: 'show', value: 'show'},
    {displayName: 'hide', value: 'hide'}  
]
*/

const netSalesOptions = [
    { displayName: 'pre-repack', value: 'prerepack' },
    { displayName: 'post-repack', value: 'postrepack' }
]



export default class RulesCard extends Component {


    onInOutGroupSelect = value => {
        this.setState({ inOutGroupBy: value });
        this.onRuleChange("inOutGroupBy", value);
        this.onRuleChange("showSaleDetail", "hide")
    }


    onShowSaleDetailSelect = value => {
        this.setState({ showSaleDetail: value });
        this.onRuleChange("showSaleDetail", value);
    }

    onShowContainersSelect = value => {
        this.setState({ showContainers: value });
        this.onRuleChange("showContainer", value);
    }

    onNetSalesSelect = value => {
        this.setState({ netSales: value });
        this.onRuleChange("netSales", value);
    }

    render() {

        var saleConfigMultiToggle = (this.props.inOutGroupBy === "sales") ?
            <div style={{  fontSize: "1.2vw", width: "45%" }}>
                <MultiToggle
                    options={showSaleDetailOptions}
                    selectedOption={this.props.showSaleDetail}
                    onSelectOption={(value) => this.props.onSingleRuleChange("showSaleDetail", value)}
                    label="Sales"
                />
            </div>
            : <span />


        return (

            <div style={{ marginLeft: "2vw", maxWidth: "75vw", minWidth: "60vw", display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                <div style={{ fontSize: "1.2vw", width: "30%" }}>
                    <MultiToggle
                        options={inOutGroupByOptions}
                        selectedOption={this.props.inOutGroupBy}
                        onSelectOption={(value) => this.props.onSingleRuleChange("inOutGroupBy", value)}
                        label="Group By"
                    />
                </div>
                {saleConfigMultiToggle}
                <div style={{ fontSize: "1.2vw", width: "45%" }}>
                    <MultiToggle
                        options={netSalesOptions}
                        selectedOption={this.props.netSales}
                        onSelectOption={(value) => this.props.onSingleRuleChange("netSales", value)}
                        label="Net Sales"
                    />
                </div>
            </div>

        )
    }

}
