import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faPlusCircle, faRecycle, faSlash, faUserEdit, faUmbrellaBeach, faUserClock } from "@fortawesome/free-solid-svg-icons";
import { Box, Flex, Spacer, HStack, Card, CardBody, Text, SimpleGrid } from '@chakra-ui/react';
import UserGrid from "./UserGrid.jsx";
import ModalUserDialog from "./ModalUserDialog";

import ConfigService from "../../../services/ConfigService.js";
import AuthResourceService from "../../../services/AuthResourceService.js";

import AuthUser from "../../../models/AuthUser.js";


const DelSwal = withReactContent(Swal);


export default class UserPanel extends Component {
  constructor(props) {
    super(props);

    this.syncTimer = null;
    this.configService = new ConfigService();
    this.authResourceService = new AuthResourceService();

    this.state = {
      editMode: "add",
      userData: null,
      currentUser: null,
      activeStatus: null,
      isLoadingGrid: false,
      orgLocations: [],
      selectableRoles: []
    };

    this.refreshData = this.refreshData.bind(this);
    this.refreshRoles = this.refreshRoles.bind(this);
    this.onRowSelectChange = this.onRowSelectChange.bind(this);
    this.onNewRequest = this.onNewRequest.bind(this);
    this.onEditRequest = this.onEditRequest.bind(this);
    this.onDeleteRequest = this.onDeleteRequest.bind(this);

    this.closeNewModal = this.closeNewModal.bind(this);
    this.postNew = this.postNew.bind(this);
    this.postUpdated = this.postUpdated.bind(this);
    this.onDeleteRequest = this.onDeleteRequest.bind(this);
    this.onBtnToggleRetireSelectedUser = this.onBtnToggleRetireSelectedUser.bind(this);

  }

  async componentDidMount() {
    await this.refreshData();
    await this.refreshRoles();

  }

  async refreshData() {
    this.setState({ isLoadingGrid: true });
    var gridDataResponse = await this.authResourceService.getAppUsers(this.props.userSession.token);
    // TBD - error handling
    var allUsers = gridDataResponse.dataResult;
    // suppress system users.
    var systemUsers = this.configService.getSystemUsers();
    var displayUsers = allUsers.filter(user => !systemUsers.includes(user.clientId));

    console.log("User Data:");
    console.info(displayUsers);
    this.setState({
      userData: displayUsers,
      selectedRow: null, // reset
      isLoadingGrid: false,
      syncing: false
    });
  }


  async refreshRoles() {
    // get roles lookup
    var rolesListResponse = await this.authResourceService.getAppRoles(this.props.userSession.token);
    console.log("Roles:");
    console.info(rolesListResponse);
    // TBD - error handling
    var allRoles = rolesListResponse.dataResult.map(role => {
      return { "value": role.id, "label": role.displayName };
    });
    var systemRoles = this.configService.getSystemRoles();
    // remove system roles
    const selectableRoles = allRoles.filter(role => !systemRoles.includes(role.value));
    console.info(selectableRoles);
    this.setState({ selectableRoles: selectableRoles });
  }



  onRowSelectChange(newSelectedRow) {
    console.log("OnRowSelectChange");
    console.log("set state for selectedRow");
    console.info(newSelectedRow);
    var newActiveStatus = newSelectedRow.enabled;
    this.setState({
      currentUser: newSelectedRow,
      activeStatus: newActiveStatus
    });
  }

  onNewRequest() {
    this.setState({
      editMode: "add",
      showNewSubModal: true
    });
  }

  onEditRequest() {
    if (!this.state.currentUser) {
      DelSwal.fire({ icon: "error", title: "Edit User", text: "Select a user first!" });
      return;
    }
    this.setState({
      editMode: "update",
      showNewSubModal: true
    });
  }

  closeNewModal() {
    this.setState({ showNewSubModal: false })
  }

  async postNew(user) {
    console.log("User to be posted:");
    console.info(user);
    var authUser = new AuthUser(user.gcid, user.clientId, user.clientName, user.description, user.password, "lgs", false, user.roles, [], 60*240);
    console.log("AuthUser to be posted:");
    console.info(authUser);
    var result = await this.authResourceService.postAppUser(authUser, this.props.userSession.token);
    this.closeNewModal();
    if (result.hasError) {
      toast.error("Error posting new user " + user.clientName + ". " + result.messages[0]);
    }
    else {
      toast.success(" new user " + user.clientName + " has been updated.");
    }
    await this.refreshData();


  }



  async postUpdated(user) {
    console.log("User to be posted:");
    console.info(user);
    var authUser = new AuthUser(user.gcid, user.clientId, user.clientName, user.description, user.password, "lgs", false, user.roles, [], 60*240);
    console.log("AuthUser to be posted:");
    console.info(authUser);
    var result = await this.authResourceService.postAppUser(authUser, this.props.userSession.token);
    this.closeNewModal();
    if (result.hasError) {
      toast.error("Error posting user " + user.clientName + ". " + result.messages[0]);
    }
    else {
      toast.success("user " + user.clientName + " has been updated.");
    }
    await this.refreshData();
  }

  async onDeleteRequest() {
    if (!this.state.currentUser) {
      DelSwal.fire({ icon: "error", title: "Remove User", text: "Select a user to remove first!" });
      return;
    }
    DelSwal.fire
      (
        {
          icon: "question",
          title: "Remove User?",
          text: "Confirm you want to permanently remove the existing user " + this.state.currentUser.clientName,
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel"
        }
      ).then(async (result) => {
        if (result.value) {
          await this.authResourceService.deleteAppUser(this.state.currentUser.clientId, this.props.userSession.token);
          await this.refreshData();
          var currentUserName = this.state.currentUser.clientName;
          this.setState({ currentUser: null });
          return DelSwal.fire({ icon: "success", text: currentUserName + " has been permanently deleted." });
        }
      }
      );
  }

  async onBtnToggleRetireSelectedUser() {
    console.log("Current Status: " + this.state.activeStatus);
    console.log("New Status: " + (!this.state.activeStatus));
    var statusWord = (this.state.activeStatus) ? "retire" : "activate";
    if (!this.state.currentUser) {
      DelSwal.fire({ icon: "error", title: "Toggle User", text: "Select a user first!" });
      return;
    }
    console.log("Current User:");
    console.info(this.state.currentUser);
    var userId = this.state.currentUser.clientId;
    DelSwal.fire({
      icon: "question",
      title: statusWord + " User?",
      text: "Confirm you want to " + statusWord + " user " + userId,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel"
    })
      .then(async (result) => {
        if (result.value) {
          var serviceResponse = await this.authResourceService.postAppUserEnabledFlag(userId, !this.state.activeStatus, this.props.userSession.token);
          await this.refreshData();
          this.setState({ currentUser: null });
          if (serviceResponse.hasError) {
            return DelSwal.fire({ icon: "error", text: userId + " has NOT been " + statusWord + "d. There was an error posting: " + serviceResponse.messages[0] });
          }
          else {
            return DelSwal.fire({ icon: "success", text: userId + " has been " + statusWord + "d." });
          }
        }
      });
  }



  render() {

    const loadingGridIcon = this.state.isLoadingGrid ? (
      <FontAwesomeIcon icon={faSyncAlt} color="green" spin />
    ) : (
      ""
    );



    return (
      <div id="admin-panel">

        <div>
          <ModalUserDialog
            showNewModal={this.state.showNewSubModal}
            closeNewModal={this.closeNewModal}
            postNew={this.postNew}
            postUpdated={this.postUpdated}
            userSession={this.props.userSession}
            editMode={this.state.editMode}
            currentUser={this.state.currentUser}
            roles={this.state.selectableRoles}
            gridData={this.state.userData}
            locationList={this.state.orgLocations}
          />
        </div>
        <Card
          overflow='hidden'
          background='white'
        >
          <CardBody>

            <h3 style={{ color: "black",  textAlign: 'center' }}>User Maintenance</h3>

            <div id="adminselectionui">

              <Button
                size="sm"
                variant="outline-info"
                onClick={this.onNewRequest}
              >
                <FontAwesomeIcon icon={faPlusCircle} />
                &nbsp;New
              </Button>

              <Button
                size="sm"
                variant="outline-info"
                onClick={this.onEditRequest}
              >
                <FontAwesomeIcon icon={faUserEdit} />
                &nbsp;Edit
              </Button>

              <Button
                size="sm"
                variant="outline-info"
                onClick={this.onBtnToggleRetireSelectedUser}
              >
                <FontAwesomeIcon icon={(this.state.activeStatus) ? faUmbrellaBeach : faUserClock} />
                &nbsp;{(this.state.activeStatus) ? "Retire" : "Activate"}
              </Button>

              <Button
                size="sm"
                variant="outline-info"
                onClick={this.onDeleteRequest}
              >
                <FontAwesomeIcon icon={faSlash} />
                &nbsp;Delete
              </Button>


              <Button
                size="sm"
                variant="outline-info"
                onClick={this.refreshData}
              >
                <FontAwesomeIcon icon={faRecycle} />
                &nbsp;Refresh
              </Button>

              <span style={{ float: "right", paddingLeft: "20px", paddingRight: "20px" }}>{loadingGridIcon}</span>
            </div>

            <div style={{ width: "100%", height: "100%" }}>
              <UserGrid
                rowData={this.state.userData}
                onRowSelectChange={this.onRowSelectChange}
                onEditRequest={this.onEditRequest}
              />
            </div>
          </CardBody>

        </Card>


      </div>
    );
  }
}
