import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";


export default class UserGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowDoubleClicked = this.onRowDoubleClicked.bind(this);

    this.columnDefs = [
      //{headerName: "Company", field: "gcid", sortable: true, filter: true, resizable: true, minWidth: 100},
      {headerName: "User Id", field: "clientId", sortable: true, filter: true, resizable: true, minWidth: 100, maxWidth: 300},
      {headerName: "Name", field: "clientName", sortable: true, filter: true, resizable: true, minWidth: 100, maxWidth: 250},
      {headerName: "Roles", field: "roles", sortable: true, filter: true, resizable: true, minWidth: 250, maxWidth: 350, wrapText: true, autoHeight: true},
      {headerName: "Description", field: "description", sortable: true, filter: true, resizable: true, minWidth: 100,  wrapText: true, autoHeight: true},
      {headerName: "Active", field: "enabled", sortable: true, filter: true, resizable: true, minWidth: 100, maxWidth: 100},
      //{headerName: "Rights", field: "allowedScopes", sortable: true, filter: true, resizable: true, minWidth: 250, maxWidth: 350, wrapText: true, autoHeight: true},    
     ];
    
  }

  onSelectionChanged() {
    console.log("Changing selected row")
    var selectedNodes = this.gridApi.getSelectedNodes();
    var rowData = selectedNodes[0].data;
    //var rowNode = selectedNodes[0];
    console.info(rowData);
    this.props.onRowSelectChange(rowData); 
  }

  onRowDoubleClicked(row){
    console.group("On Row Double Clicked Row: ");
    console.log(row.data);
    console.groupEnd();
    var rowData = row.data;
    this.props.onSRowSelectChange(rowData);
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit(params)
  };

  render() {
    const divStyle = {
      width: "100%",
      
      maxWidth: "99vw",

      //border: '5px solid black'
    };

    const gridOptions = {
      onRowDoubleClicked: this.onRowDoubleClicked
    }

    /*
    if (this.dataReady){
      console.log(" Lines (this.props.rowData) about to be rendered: ");
      console.log(this.props.rowData);
      this.gridApi.setRowData(this.props.rowData);
    }
    */

    const gridData = (this.props.rowData) ? this.props.rowData : [{}];

    return (
      <div className="ag-theme-balham" style={divStyle}>
        <AgGridReact
          rowSelection="single"
          enableRangeSelection={false}
          pagination={true}
          columnDefs={this.columnDefs}
          rowData={gridData}
          animateRows={true}
          onGridReady={this.onGridReady}
          onSelectionChanged={this.onSelectionChanged.bind(this)}
          gridOptions = {gridOptions}
          domLayout="autoHeight"
          onRowDoubleClicked = {this.props.onEditRequest}
        />
      </div>
    );
  }
}
