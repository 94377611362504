import React, {Component} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophoneAlt, faMicrophoneAltSlash} from "@fortawesome/free-solid-svg-icons";

const newSpeechRecognition = window.webkitSpeechRecognition || null;
const recognition = (newSpeechRecognition) ? new newSpeechRecognition() : null;
if (recognition){
    recognition.continuous = false;
    recognition.autoStart = false;
    recognition.iterimResults = true
    recognition.lang = 'en-US'
}



export default class GcDictaphone extends Component {
    constructor(props){
        super(props);
        this.state = {
            listening: false,
            interimResult: ''
        }

        this.toggleListen = this.toggleListen.bind(this);
        this.handleListen = this.handleListen.bind(this);
    }

    toggleListen(){
        if (!recognition){
            alert("Not available on Firefox or IOS devices");
            return;
        }
        this.setState({
            listening: !this.state.listening},
            this.handleListen);
    }

    handleListen(){
        if (!recognition){
            alert("Not available on Firefox or IOS devices");
            return;
        }
        console.log('listening?', this.state.listening);

        if (this.state.listening){
            recognition.lang = (this.props.lang) ? this.props.lang : 'en-US';
            recognition.start();
            recognition.onend = () => {
                console.log("...continue listening...");
                recognition.start()
            }
        } else {
            recognition.stop()
            recognition.onend = () => {
                console.log("Stopped listening per user");
            }
        }

        recognition.onstart = () => {
            console.log("listening!");
        }

        let finalTranscript = '';
        recognition.onresult = event => {
            let interimTranscript='';
            for (let i=event.resultIndex; i<event.results.length; i++){
                const transcript = event.results[i][0].transcript;
                if (event.results[i].isFinal) finalTranscript += transcript + ' ';
                else {
                    interimTranscript += transcript;
                    this.setState({interimResult: interimTranscript});
                }
            }
            this.props.onTranscriptionChange(finalTranscript);
            finalTranscript = "";


            // here we have results


            // here we can listen for voice commands
            /*const transcriptArr = finalTranscript.split(' ');
            const stopCmd = transcriptArr.slice(-3, -1);
            console.log('Got user voice stop command', stopCmd);

            if (stopCmd[0] === 'stop' && stopCmd[1] === 'listening'){
                recognition.stop()
                recognition.onEnd = () => {
                    console.log('stopped listening per user voice command');
                    const finalText = transcriptArr.slice(0, -3).join(' ');
                    // pass along finalText
                    this.props.onTranscriptChange(finalText);

                }
            }
            */
        }

        recognition.onerror = event => {
            console.log('Error event in recognition: ' + event.error);
        }
    }


    render(){

        return (
            <div>
                <span className="textedit-mic"><FontAwesomeIcon icon={(this.state.listening) ? faMicrophoneAlt: faMicrophoneAltSlash} color={(this.state.listening) ? "lightgreen": "white"} onClick={this.toggleListen} /></span>
                <div id="interim">{this.state.interimResult}</div>
            </div>
  )
}
}

