import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faUpload } from "@fortawesome/free-solid-svg-icons";


export default class LoginPanel extends Component {

    constructor(props) {
        super(props);

        this.state =
        {
            passwordType: "password",
            passwordShowLabel: "show",
            valueBag: {"gcid": "", "uid": ""}

        }

        this.onInputChange = this.onInputChange.bind(this);
        this.togglePasswordState = this.togglePasswordState.bind(this);
    }




    componentDidUpdate(prevProps)
    {
        if (prevProps.lastUserInfo !== this.props.lastUserInfo)
        {
            var newValueBag = this.state.valueBag;
            newValueBag["gcid"] = this.props.lastUserInfo.gcid;
            newValueBag["uid"] = this.props.lastUserInfo.uid;
            this.setState({valueBag: newValueBag});
        }
    }



    onInputChange(e)
    {
        var newValueBag = this.state.valueBag;
        newValueBag[e.target.id] = e.target.value;
        this.setState({valueBag: newValueBag});
        this.props.onDeleteErrorBagKeyRequest(e.target.id);
        this.props.onDeleteErrorBagKeyRequest("auth");
    }





    togglePasswordState() {
        let lbl = this.state.passwordShowLabel;
        if (lbl === "show") {
            this.setState({
                passwordShowLabel: "hide",
                passwordType: "text",
            });
        } else {
            this.setState({
                passwordShowLabel: "show",
                passwordType: "password",
            });
        }
    }



    render() {
        var eyeStatus = (this.state.passwordShowLabel === "show") ? <FontAwesomeIcon icon={faEye} color="grey" /> : <FontAwesomeIcon icon={faEyeSlash} color="grey" />;
 
        return (
            <div>
                    <Form id="loginbody">
                        <Form.Group >
                            <Form.Control
                                className={(this.props.errorBag.gcid) ? "error": ""}
                                id= "gcid"
                                placeholder="Gcid"
                                type="text"
                                defaultValue={this.state.valueBag["gcid"]}
                                onChange={(ev) => this.onInputChange(ev)}
                            />
                            
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                className={(this.props.errorBag.uid) ? "error": ""}
                                id="uid"
                                placeholder="User Id"
                                type="text"
                                defaultValue={this.state.valueBag["uid"]}
                                onChange={(ev) => this.onInputChange(ev)}
                            />
                            
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                className={(this.props.errorBag.pwd) ? "error": ""}
                                id="pwd"
                                placeholder="Password"
                                type={this.state.passwordType}
                                autoComplete="off"
                                onChange={(ev) => this.onInputChange(ev)}
                                class="errorinput"
                            />
                            <span onClick={this.togglePasswordState} id="pwdeye">
                                {eyeStatus}
                            </span>                  
                        </Form.Group>
                    </Form>

                    <Button
                        className={(this.props.errorBag.auth) ? "error": ""}
                        variant="primary"
                        id="loginbtn"
                        disabled={(!this.props.isSupportedDevice)}
                        onClick={(ev) => this.props.onLoginAttempt(this.state.valueBag)}
                    >
                        Log In
                    </Button> 
                    <div style={{ color: "lightgrey", fontSize: "8pt" }}>Browser: {this.props.deviceInfo.browser.browserName} Version {this.props.deviceInfo.browser.fullBrowserVersion}&nbsp;&nbsp;{(this.props.deviceInfo.browser.browserSubTypes.length > 0) ? this.props.deviceInfo.browser.browserSubTypes.join('-') : ""}</div>
                    <div style={{ color: "lightgrey", fontSize: "8pt" }}>Build: {this.props.buildVersion}{(this.props.isNewBuild) ? "*": ""} </div>
                    <div style={{ color: "lightgrey", fontSize: "8pt" }}>Version: {this.props.appVersion} </div>

            </div>
        )
    }

}