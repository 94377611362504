
import ConfigService from "./ConfigService";
import HttpService from "./HttpService";
import AuthService from "./AuthService";


export default class CoreService 
{
  constructor(coreDomain) 
  {
    this.configService = new ConfigService();
    this.domain = coreDomain || this.configService.getCoreServiceHost();
    this.authService = new AuthService();
  }

  async fetchVersion(token) 
  {
    const path = "/api/admin/version";
    var response = await HttpService.get(this.domain, path, token);
    return response;
  }

  async pingCore(token) 
  {
    const path = "/api/admin/ping";
    var response = await HttpService.get(this.domain, path, token);
    return response;
  }

  async fetchUpdatedLotList(lastUpdated,maxLotsToDisplay, token)
  {
    // convert local last updated to UTC last updated
    var path = "/api/lgs/gcid/lgs/lotupdateflag/lastupdatedfrom/" + lastUpdated + "?maxlots=" + maxLotsToDisplay;
    var response = await HttpService.get(this.domain, path, token);
    return response;
  }


  async fetchLotList(token)
  {
      const path = "/api/lgs/gcid/lgs/lots";
      var response = await HttpService.get(this.domain, path, token);
      return response;
  }

  async fetchLotLiquidationSummary(lotId, token)
  {
    const path = "/api/lgs/gcid/lgs/lotid/" + lotId + "/liquidationsummary";
    var response = await HttpService.get(this.domain, path, token);
    return response;
  }

  async fetchLotStatusSummary(lotId, token)
  {
    const path = "/api/lgs/gcid/lgs/lotstatussummary/lotid/" + lotId;
    let response = await HttpService.get(this.domain, path, token);
    return response;
  }
    
  async fetchLotData(lotId, token)
  {
    const path = "/api/lgs/gcid/lgs/lotliquidation/lotid/" + lotId;
    let response = await HttpService.get(this.domain, path, token);
    return response;

    }

    async postLotAddRequest(lotId, token)
    {
      var postObj = {"lotId": lotId, "action": "liquidation"};
      const path = "/api/lgs/gcid/lgs/lotrefreshrequest/";
      let response = await HttpService.postJson(this.domain, path, token, postObj);
      return response;
    }


  async postClosedLotsRequest(token)
    {
      var postObj = {"lotId": "", "action": "lotsclosedupdate"};
      const path = "/api/lgs/gcid/lgs/lotrefreshrequest/"; // note that it calls same core call with different refresh object
      let response = await HttpService.postJson(this.domain, path, token, postObj);
      return response;
    }



  async fetchWorkflowLotList(token)
  {
      const path = "/api/lgs/gcid/lgs/workflowlots";
      var response = await HttpService.get(this.domain, path, token);
      return response;
  }

  async fetchLotWorkflow(lotId, token)
  {
    const path = "/api/lgs/gcid/lgs/lot/" + lotId + "/lotworkflowstatus";
    var response = await HttpService.get(this.domain, path, token);
    return response;
  }

  async postLotWorkflow(newWorkflowStatus, token)
  {
    const path = "/api/lgs/gcid/lgs/lot/" + newWorkflowStatus.lotId + "/lotworkflowstatusrequest";
    var response = await HttpService.postJson(this.domain, path, token, JSON.stringify(newWorkflowStatus));
    return response;
  }


  async fetchServiceMap(token)
  {
    const path = "/api/lgs/gcid/lgs/servicemap";
    var response = await HttpService.get(this.domain, path, token);
    return response;
  }

  async postServiceMapItem(gcid, serviceMapItem, token) 
  {
    const path = "/api/Lgs/gcid/lgs/servicemapitem";
    console.log(JSON.stringify(serviceMapItem));
    let postResult = await HttpService.postJson(this.domain, path, token, JSON.stringify(serviceMapItem), true);
    return postResult;
  }

  async postSupplierConfig(supplierConfigEv, token) 
  {
    const path = "/api/Lgs/gcid/lgs/supplierconfig";
    console.log(JSON.stringify(supplierConfigEv));
    let postResult = await HttpService.postJson(this.domain, path, token, JSON.stringify(supplierConfigEv), true);
    return postResult;
  }


  async hasSupplierConfig(supplierId, token)
  {
    const path = "/api/lgs/gcid/lgs/supplier/" + supplierId;
    var response = await HttpService.get(this.domain, path, token);
    return !response.isRecordNotFound;
  }

  async fetchSupplierConfig(supplierId, token)
  {
    const path = "/api/lgs/gcid/lgs/supplier/" + supplierId;
    var response = await HttpService.get(this.domain, path, token);
    return response;
  }

  async fetchSupplierConfigList(token)
  {
    const path = "/api/lgs/gcid/lgs/supplier";
    var response = await HttpService.get(this.domain, path, token);
    return response;
  }


  async PostInOutAdjustRequest(lot, adjustedInOut, token)
  {
    const path = "/api/lgs/gcid/lgs/inOutAdjustment";
    console.log(JSON.stringify(adjustedInOut));
    let postResult = await HttpService.postJson(this.domain, path, token, JSON.stringify(adjustedInOut), true);
    return postResult;
  }


async PostChargeAdjustRequest(lot, adjustedCharge, token)
  {
    const path = "/api/lgs/gcid/lgs/chargeadjustment";
    console.log(JSON.stringify(adjustedCharge));
    let postResult = await HttpService.postJson(this.domain, path, token, JSON.stringify(adjustedCharge), true);
    return postResult;
  }

  async PostAdjustDeleteRequest(lotId, adjustmentKey, token)
  {
      const path = "/api/lgs/gcid/lgs/lotid/" + lotId + "/adjustmentdeleterequest/key/" + adjustmentKey;
      console.log(JSON.stringify(adjustmentKey));
      let postResult = await HttpService.postJson(this.domain, path, token, JSON.stringify(adjustmentKey), true);
      return postResult;
  }



  /*
  async fetchLog(eventType, hoursBeforeNow, token) {
    var iso8601StartDate = DateTime.now().setZone('America/Los_Angeles').minus({ hours: hoursBeforeNow }).toISO();
    const path = "/api/telemetry/gcid/cg/pollingtelemetryevent?maxCount=1000&startDate=" + iso8601StartDate + "&eventtype=" + eventType;
    var response = await HttpService.get(this.domain, path, token);
    return response;
  }
  */


  async fetchAzureStorageSasUri(imgId, ext, token) 
  {
      const path ="/api/Lgs/gcid/lgs/id/" + imgId + "/ext/" + ext + "/subdir/reports";
      let sasUriResponse = await HttpService.get(this.domain, path, token);
      return sasUriResponse;
  }


  
}