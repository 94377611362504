import JwtToken from "../components/lib/tokens/JwtToken";
import { WorkflowStatus } from "./WorkflowStatusType";
import { AppSecurityKey } from "./AppSecurityKey";


export default class RoleManager
{

    static hasRoleRights(appSecurityKey, token)
    {
        if (!token) return false;
        var result = JwtToken.hasAnyRoles(token, appSecurityKey.roles);
        return result;
    };

    static hasRoleStatusRights(appSecurityKey, workflowStatus, token)
    {
        if ((!token) || (!workflowStatus)) return false;
        if (!JwtToken.hasAnyRoles(token, appSecurityKey.roles)) return false;
        var result = this.appSecurityKeyHasWorkflowStatus(appSecurityKey, workflowStatus);
        return result;
    };


    static toWorkflowStatus(workflowStatusString)
    {
        var result = Object.keys(WorkflowStatus).find((key) => WorkflowStatus[key].id === workflowStatusString);
        return result;
    }

    static toWorkflowStatusList()
    {
        var result = Object.keys(WorkflowStatus).map((key) => WorkflowStatus[key].id);
        return result;
    }

    static appSecurityKeyHasWorkflowStatus(appSecurityKey, workflowStatus)
    {
        var result = Object.keys(appSecurityKey.workflowStatuses).find((key) => appSecurityKey.workflowStatuses[key].id === workflowStatus.toUpperCase());
        return result;
    }

    static toAvailableWorkflowStatusList(workflowStatus, token)
    {
        if ((!token) || (!workflowStatus)) return [];
        var workflow = WorkflowStatus[workflowStatus.toUpperCase()];
        var filtered = workflow.transitions.filter((t) => (JwtToken.hasAnyRoles(token, t.roles)));
        var result = filtered.map((f) => WorkflowStatus[f.status.toUpperCase()]);
        return result;
    }





} 