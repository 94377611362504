import React from "react";
import moment from "moment";
import JavascriptHelpers from "../../../components/lib/Javascript/JavascriptHelpers";

import ModalWorkflowStatusDialog from "./ModalWorkflowStatusDialog";
import LotWorkflowStatus from "../../../models/LotWorkflowStatus";
import RoleManager from "../../../models/RoleManager";
import { WorkflowStatus } from "../../../models/WorkflowStatusType";
import { Box, Flex, Spacer, HStack, Card, CardBody, Text, SimpleGrid, Badge } from '@chakra-ui/react';



export default class HeaderCard extends React.Component 
{
 
  constructor() 
  {
    super();
    this.state = {
      showModal: false,
      groupOptions: []
    };
    this.refreshData = this.refreshData.bind(this);
    this.onModalOpenRequest = this.onModalOpenRequest.bind(this);
    this.onModalCloseRequest = this.onModalCloseRequest.bind(this);
    this.onModalOkRequest = this.onModalOkRequest.bind(this);
  }

  async componentDidMount()
  {
    console.log("mounting lotpage")
    await this.refreshData(this.props.data);
    console.log(this.state.lotData)
  }

  async componentDidUpdate(prevProps, prevState)
  {
    if (prevProps.data.workflowStatus.workflowStatus !== this.props.data.workflowStatus.workflowStatus)
    {
      await this.refreshData();
    }
  }

  async refreshData()
  {
    var groupOptionsList = RoleManager.toAvailableWorkflowStatusList(this.props.data.workflowStatus.workflowStatus, this.props.userSession.token);
    var groupOptions = groupOptionsList.map((g) => g.id);
    console.log("group Options:");
    console.info(groupOptions);
    this.setState({

        groupOptions: groupOptions
    });
  }


  onModalOpenRequest(e)
  {
      if (e)
      {
          e.preventDefault();
          e.stopPropagation();
      }
      if (this.state.groupOptions.length < 1) 
      {
        alert("You have no workflow status options to change to.")
        return;
      }
      this.setState({showModal: true});
  }

  onModalCloseRequest(e)
  {
    if (e)
    {
        e.preventDefault();
        e.stopPropagation();
    }
    this.setState({showModal: false});
  }

  async onModalOkRequest(existingWorkflowStatus, newWorkflowStatus)
  {
      var newWorkflowStatusObj = new LotWorkflowStatus(this.props.userSession.gcid, this.props.data.lotId, newWorkflowStatus, JavascriptHelpers.isoNow(), this.props.userSession.uid);
      console.log("New Workflow Status: ");
      console.info(newWorkflowStatusObj);
      this.props.onLotWorkflowStatusChange(newWorkflowStatusObj, this.props.userSession.token);
      this.onModalCloseRequest();
      await this.refreshData();
  }


      render()
      {
          return(
            <span>
                    <ModalWorkflowStatusDialog 
                        show={this.state.showModal} 
                        onModalCloseRequest={this.onModalCloseRequest}
                        onModalOkRequest={this.onModalOkRequest}
                        existingWorkflowStatus={this.props.data.workflowStatus.workflowStatus}
                        groupOptions={(this.state.groupOptions) ? this.state.groupOptions: []}
                    />

            <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              //backgroundColor: "lightgrey",
              //color: "white",
              marginLeft: "2vw",
              marginRight: "2vw",
              borderRadius: "3px"
            }}
          >
              <div style={{display: "block"}}>
                <div style={{color: "grey", fontSize: "16px"}}>LGS Reference</div><div >{this.props.data.lotId}</div>
              </div>
              <div style={{display: "block"}}>
                <div style={{color: "grey", fontSize: "16px"}}>Carrier</div><div>{this.props.data.vessel}</div>
              </div>
              <div style={{display: "block"}}>
                <div style={{color: "grey", fontSize: "16px"}}>Shipper</div><div>{this.props.data.supplier}</div>
              </div>
              <div style={{display: "block"}}>
                <div style={{color: "grey", fontSize: "16px"}}>Arrived</div><div>{moment(this.props.data.dateArrived).format("YY-MM-DD")}</div>
              </div>
              <div style={{display: "block"}} onClick={(e) => this.onModalOpenRequest(e)}>
                {/* <div style={{color: "grey", fontSize: "12px"}}>Status</div><div style={{ color: WorkflowStatus[this.props.workflowStatus.toUpperCase()].color, backgroundColor: WorkflowStatus[this.props.workflowStatus.toUpperCase()].backgroundColor, fontSize: "12px"}}>{this.props.workflowStatus}</div> */}
                <div style={{color: "grey", fontSize: "16px"}}>Status</div><Text>{this.props.workflowStatus}</Text>
              </div>
          </div>

          </span>
          
          )
      }

}
