import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import CacheBuster from "../../components/lib/caching/CacheBuster";
import DeviceInfo from "../../components/lib/device/DeviceInfo";
import ConfigService from "../../services/ConfigService";
import AuthService from "../../services/AuthService";
import AuthResourceService from "../../services/AuthResourceService";
import LocalStorageService from "../../services/LocalStorageService";
import CoreService from "../../services/CoreService";

import LoginPanel from "./Components/LoginPanel";
import gcLogo from "../../assets/images/logos/glasschain-app-logo.png";

import JwtToken from "../../components/lib/tokens/JwtToken";

import "../../assets/css/auth.css";



export default class AuthPage
  extends Component {
  constructor(props) {
    super(props);

    this.isSupportedDevice = DeviceInfo.isSupportedBrowser(this.props.deviceInfo);
    this.ConfigService = new ConfigService();
    this.authService = new AuthService();
    this.authResourceService = new AuthResourceService();
    this.localStorageService = new LocalStorageService();
    this.coreService = new CoreService();


    this.state =
    {
      buildVersion: "",
      appVersion: "",
      coreVersion: "",
      isNewBuild: false,
      errorBag: {},
      isBusy: false,
      busyMsg: "",
      lastUserSession: null
    }

    this.checkBuild = this.checkBuild.bind(this);
    this.onLoginAttempt = this.onLoginAttempt.bind(this);
    this.deleteErrorBagKey = this.deleteErrorBagKey.bind(this);
    this.busyOn = this.busyOn.bind(this);
    this.busyOff = this.busyOff.bind(this);
    this.busyMsg = this.busyMsg.bind(this);

  }

  async componentDidMount() {
    this.busyOn("Checking your version and device...");
    this.sleep(2000);
    try{
      await this.checkBuild();
      if (!this.isSupportedDevice)
      {
        var newErrorBag = {};
        newErrorBag.device = "You are trying to use an unsupported Device and/or Browser";
        this.setState({errorBag: newErrorBag});
      }
      else 
      {
        if (this.localStorageService.hasLastUserSession())
        {
          var lastUserSession = this.localStorageService.getLastUserSession();
          this.setState({lastUserSession: lastUserSession});
        }
      }
      var appVersion = this.ConfigService.getAppVersion();
      this.setState({appVersion: appVersion});
    }
    finally{
      this.busyOff();
    }
  }


  busyOn(msg)
  {
    this.setState({isBusy: true, busyMsg: msg});
  }

  busyOff()
  {
    this.setState({isBusy: false, busyMsg: ""});
  }

  busyMsg(msg)
  {
    this.setState({busyMsg: msg});
  }

  async sleep(ms)
  {
    await new Promise(r => setTimeout(r, ms));
  }

  async checkBuild() {
    var cacheBuster = new CacheBuster();
    var hasLatestBuild = await cacheBuster.isLatestBuild();
    if (!hasLatestBuild) {
      this.setState({isNewBuild: true});
      this.busyMsg("Found new version(" + cacheBuster.buildVer + "). Refreshing your system");
      await cacheBuster.refreshCacheAndReload();
    }
    this.setState({ buildVersion: cacheBuster.buildVer });
  }



 async onLoginAttempt(valueBag)
 {
   this.busyOn("Validating...");
  try{
    var errorBag = this.getValidationErrors(valueBag);
    if (Object.keys(errorBag).length > 0) {
      this.setState({ errorBag: errorBag });
      return;
    }
    // clear the errorBag messages
    this.setState({errorBag: {}});
    // if we got this far, then let's let the user be able to read the message if it goes really fast
    await this.sleep(1000);

    this.busyMsg("Authorizing...");
    let isAuthorizedResult = await this.authService.isAuthorizedUserLogin(valueBag.gcid, valueBag.uid, valueBag.pwd);
    if (isAuthorizedResult.hasError) {
      errorBag.auth = "There was a problem checking your user authorization. "
      errorBag.auth = errorBag.auth + isAuthorizedResult.messages.join(". ");
      this.setState({ errorBag: errorBag });
      return;
    }
    if (!isAuthorizedResult.dataResult) {
      errorBag.auth = "Unauthorized gcid/uid/password combination"
      this.setState({ errorBag: errorBag });
      return;
    }
    this.busyMsg("Fetching authorization token...");
    // note that the base user scope allows us to "bootstrap" the user up to full role scopes.
    var tokenResult = await this.authService.getUserToken(valueBag.gcid, valueBag.uid, valueBag.pwd, this.ConfigService.getBaseUserScope());
    if (tokenResult.hasError) {
      errorBag.auth = "There was a problem fetching your authorization token. ";
      errorBag.auth = errorBag.auth + tokenResult.messages.join(". ");
      this.setState({ errorBag: errorBag });
      return;
    }
    var sessionToken = tokenResult.dataResult;
    var tokenPayload = JwtToken.getTokenPayload(sessionToken);

    var scopesResult = await this.authResourceService.getScopesByRoles(tokenPayload.roles, sessionToken);
    if (scopesResult.hasError) {
      errorBag.auth = "There was a problem fetching your authorization rights (scopes). ";
      errorBag.auth = errorBag.auth + scopesResult.messages.join(". ");
      this.setState({ errorBag: errorBag });
      return;
    }
    console.log("Login Scopes: ");
    console.info(scopesResult.dataResult);
    var fullTokenResult = await this.authService.getUserToken(valueBag.gcid, valueBag.uid, valueBag.pwd, scopesResult.dataResult)
    if ((fullTokenResult.hasError) || (!fullTokenResult.dataResult)) 
    {
      errorBag.auth = "There was a problem fetching your full authorization token. ";
      errorBag.auth = errorBag.auth + scopesResult.messages.join(". ");
      this.setState({ errorBag: errorBag });
      return;
    }
    // reset sessiontoken and payload
    sessionToken = fullTokenResult.dataResult;
    tokenPayload = JwtToken.getTokenPayload(sessionToken);

    await this.sleep(1000);

    this.busyMsg("Confirming glasschain core services are available...");
    let isCorePingable = await this.coreService.pingCore();
    if (isCorePingable.hasError) 
    {
      errorBag.auth = "There was a problem pinging glasschain core services. ";
      errorBag.auth = errorBag.auth + isCorePingable.messages.join(". ");
      this.setState({ errorBag: errorBag });
      return;
    }
    this.busyMsg("glasschain core services confirmed.");
   

    await this.sleep(1000);

    // get version info
    this.busyMsg("Getting LGS API version information...");
    var coreVersionResponse = await this.coreService.fetchVersion();
    if (coreVersionResponse.hasError){
      errorBag.auth = "There was a problem fetching API versioning information ";
      errorBag.auth = errorBag.auth + coreVersionResponse.messages.join(". ");
      this.setState({ errorBag: errorBag });
      return;
    }

    this.busyMsg("Logging in...")
    // we're good! Pass the results back up to the Auth Page
    this.setState({ errorMessage: null });
    
    var result = {
        "gcid": valueBag.gcid, "uid": valueBag.uid, "isValid": true, token: sessionToken,
        offlineTokenCanExpire: true, allowedLocations: [],  
        currentLocationId: "",
        currentWarehouse: "",
        app: tokenPayload.app,
        roles: tokenPayload.roles,
        buildVer: this.state.buildVersion, appVer: this.ConfigService.getAppVersion(), 
        coreVer: coreVersionResponse.dataResult
      };
    console.group("In Auth - result passed back to HomeLayout onSuccessfulLogin: ");
    console.info(result);
    console.groupEnd();
    this.busyMsg("Saving Login information...");
    await this.props.onSuccessfulLogin(result);
  }
  catch(err){
    this.busyOff();
    var newErrorBag = {};
    newErrorBag.unexpectedError = "The system got an unexpected error while trying to log you in! [" + err.name + ": " + err.message + "]";
    this.setState({errorBag: newErrorBag});
  }
  finally{
    this.busyOff();
  }
 }

 getValidationErrors(valueBag) {
  var result = {};
  var gcidErr = ((!valueBag.gcid) || (valueBag.length < 2)) ? "Invalid gcid" : null;
  var uidErr = ((!valueBag.uid) || (valueBag.uid.length < 6)) ? "Invalid uid" : null;
  var pwdErr = ((!valueBag.pwd) || (valueBag.pwd.length < 6)) ? "Invalid password" : null;
  if (gcidErr) result.gcid = gcidErr;
  if (uidErr) result.uid = uidErr;
  if (pwdErr) result.pwd = pwdErr;
  return result;
}

deleteErrorBagKey(key)
{
  var newErrorBag = this.state.errorBag;
  delete newErrorBag[key];
  this.setState({errorBag: newErrorBag});
}


  render() 
  {

    var errors;
    if (Object.keys(this.state.errorBag).length > 0) 
    {
      var entries = Object.entries(this.state.errorBag);
      var errorBody = entries.map(e => {
        return <div>{e[1]}</div>
      });
      errors = <div id="errorbox">
        {errorBody}
      </div>
    } else {
      errors = <span/>
    }

    var busySpan = (this.state.isBusy) ? <div id="busybox"><FontAwesomeIcon icon={faSyncAlt} color="white" spin/><span style={{ color: "lightgrey", fontSize: "8pt" }}>{this.state.busyMsg}</span></div>
      : <span/>;

    return (

      <div className="auth">
      <div id="authcontainer">
        <img src={gcLogo} alt="LGS Lot Dashboard" id="gclogo"/>

        <LoginPanel
          deviceInfo={this.props.deviceInfo}
          isSupportedDevice={this.isSupportedDevice}
          buildVersion={this.state.buildVersion}
          appVersion={this.state.appVersion}
          isNewBuild={this.state.isNewBuild}
          coreVersion={this.state.coreVersion}
          onLoginAttempt={this.onLoginAttempt}
          onDeleteErrorBagKeyRequest={this.deleteErrorBagKey}
          lastUserInfo={this.state.lastUserInfo}
          errorBag={this.state.errorBag}
        />

        {errors}

        {busySpan}
      </div>

      </div>
    )
  }

}