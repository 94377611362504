

export default class LotWorkflowStatus 
{
    constructor(gcid, lotId, workflowStatus, lastUpdated, lastUpdatedBy)
    {
        this.gcid = gcid || "";
        this.lotId = lotId || "";
        this.workflowStatus = workflowStatus || "";
        this.lastUpdated = lastUpdated || "";
        this.lastUpdatedBy = lastUpdatedBy || "";
    }
}