import React, { Component } from "react";

import UserPanel from "./Components/UserPanel";

import "../../assets/css/main.css";
import "../../assets/css/admin-styles.css";


export default class UserManagementPage extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            showNewSubModal: false,
            forcePanelRefresh: false
        }
    }


    render() {
        return (
            <div className="main">
      <div style={{ overflowX: "hidden" }}>
          <div>
            <UserPanel
              isConnected={this.props.isConnected}
              userSession={this.props.userSession}
              onLogoutRequest={this.props.onLogoutRequest} 
              panelRefreshRequest={this.state.forcePanelRefresh}
              onPanelRefreshComplete={this.onPanelRefreshComplete}
            />
          </div>
        </div>

            </div>
        )

    }
}