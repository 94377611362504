import React, { Component } from "react";
import { Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { SystemErrorLevelType } from "../../models/SystemErrorLevelType";

import "../../assets/css/error.css";

export default class ErrorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="errorpage">

        <div
          style={{
            position: "absolute",
            width: "50vw",
            height: "50vh",
            top: "30%",
            left: "30%",
          }}
        >
          <div id="errormsgbox" style = {{backgroundColor: this.props.systemErrorLevel.backgroundColor}}>
            <div id="errorLabel" style={{ color: this.props.systemErrorLevel.labelColor, fontSize: "24px"}}>
              {this.props.systemErrorLevel.label}
            </div>
            <div id="errormsg" style={{ color: this.props.systemErrorLevel.color }}>
              {this.props.systemErrorMsg}
            </div>
            <div id="authButtonLayer" style={{ alignContent: "center" }}>
              <br />
              <br />
              <Button
                id="authButton"
                onClick={(e) => this.props.onLogoutRequest(e)}
                color="black"
              >
                <FontAwesomeIcon icon={faPowerOff} color="black" />
                &nbsp;Reset
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
