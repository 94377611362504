import InOutGridModel from "./InOutGridModel";

export default class SalesConfigGridModel
{
    constructor(salesConfigGridLines)
    {
        this.salesConfigGridLines = salesConfigGridLines || [];
    }

    static toSalesConfigGridModel(lotData, adjustments)
    {
        var model = new SalesConfigGridModel();
        var configList = SalesConfigGridModel.toConfigList(lotData.sales, adjustments);
        var reducedConfigList = SalesConfigGridModel.toReducedConfigList(configList);
        var defaultSortedConfigList = reducedConfigList.sort((a, b) => (
          a.configuration.localeCompare(b.configuration) 
      ));
        model.salesConfigGridLines = defaultSortedConfigList;
        return model;
    }

    static lineHasAdjustments(lineKey, adjustments)
    {
      if (!adjustments) return false;
      if (adjustments.length < 1) return false;
      if (!adjustments.some(a => a.adjustmentKey === lineKey)) return false;
      return true;
    }

    static toLineAdjustment(lineKey, adjustments)
    {
        return adjustments.find(a => a.adjustmentKey === lineKey);
    }

    static toOrigSalesKey(salesLine)
    {
      return "sale-" + InOutGridModel.toItemKey(salesLine);
    }

    static toConfigList(salesData, adjustments)
    {
        var result = salesData.lines.map((s, i) => {
          var descriptWords = s.item.item.name.split(" ");
          // we are going to make a LOT of assumptions here.
          // NEVER use the first word - which is the commodity
          // ALWAYS use the second word
          // ALWAYS use the third word
          // ONLY use the fourth word if it contains an apostrophe (for size)
          var configuration = descriptWords[1] + " " + descriptWords[2];
          if (descriptWords[3].match(/^\d/)) {
            configuration = configuration + " " + descriptWords[3];
          }
          var key = SalesConfigGridModel.toOrigSalesKey(s);
          var hasAdjustments = SalesConfigGridModel.lineHasAdjustments(key, adjustments);
          // initialize to original line quantity
          var adjustedQuantity = s.qty;
          var adjustedExtAmount = s.extAmount;
          if (hasAdjustments)
          {
            var adj = this.toLineAdjustment(key, adjustments);
            adjustedQuantity = adj.adjustedQuantity;
            adjustedExtAmount = adj.adjustedSaleAmount;
          }
       
      
          return {
            originalKey: key,
            configuration: configuration,
            qty: s.qty,
            extAmount: s.extAmount,
            activeQty: adjustedQuantity,
            activeExtAmount: adjustedExtAmount,
            hasAdjustments: hasAdjustments
          };
        });
        return result;
    }

    static toReducedConfigList(configList)
    {
        var helper = {};
        var result = configList.reduce(function (r, o) {
            var key = o.configuration;
            if (!helper[key]) {
              helper[key] = Object.assign({}, o); // create a copy of o
              helper[key].displayQty = o.activeQty;
              helper[key].dislayExtAmount = o.activeExtAmount;
              helper[key].price = (helper[key].activeExtAmount === 0 && o.activeQty === 0) ?
                0:
                o.activeExtAmount / o.activeQty;
              helper[key].hasAdjustments = o.hasAdjustments;
              helper[key].sourceLineCount = 1;
              r.push(helper[key]);
            } else {
              helper[key].sourceLineCount++;
              // Here is our "special" case. If we have adjustment(s) AND more than 1 line, then
              // we really CANNOT figure out what the quantity/extAmount/price should be!
              if (helper[key].hasAdjustments && (helper[key].sourceLineCount > 1))
              {
                helper[key].qty = "--"
                helper[key].displayQty = "--";
                helper[key].displayExtAmount = "--";
                helper[key].price = "--";
              }
              else 
              {
                helper[key].qty += o.qty;
                helper[key].displayQty += o.activeQty;
                helper[key].displayExtAmount += o.activeExtAmount;
                helper[key].price = (helper[key].activeExtAmount === 0 && helper[key].activeQty === 0) ? 
                  0:
                  helper[key].activeExtAmount / helper[key].activeQty;
            }
          }
            return r;
          }, []);
          return result;
    }
}