import {BlockBlobClient} from '@azure/storage-blob';
import CoreService from "./CoreService";


function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

export default class AzureStorageService {
    constructor()
    {
        this.account = 'gcblobstoredurable1'; 
        //this.blobSasUrl = 'https://gcblobstoredurable1.blob.core.windows.net/?sv=2019-10-10&ss=b&srt=sco&sp=rwdlacx&se=2020-07-04T11:02:14Z&st=2020-07-04T03:02:14Z&spr=https,http&sig=LyLJUi%2BnWHsG1FthUYursp7ahFWhsY8DIxsiWlIkWG8%3D';
        //this.blobServiceClient = new BlobServiceClient(this.blobSasUrl);
        this.coreService = new CoreService();

    }

    async uploadPdf(blob, imageId, token)
    {
        // fetch the sas Uri
        let sasUriResponse = await this.coreService.fetchAzureStorageSasUri(imageId, 'pdf', token);
        //var blobServiceClient = new BlobServiceClient(sasUri);
        var blockBlobClient = new BlockBlobClient(sasUriResponse.dataResult);
        const uploadBlobResponse = await blockBlobClient.upload(blob, blob.size);
        console.log('Uploaded block blob ${blobName} successfully', uploadBlobResponse.requestId); 


}

}