import decode from 'jwt-decode';


export default class JwtToken
{

    static hasAllRoles(token, userRoles)
    {
        var tokenPayload = this.getTokenPayload(token);
        var tokenRoles = tokenPayload.roles;
        var result = userRoles.every(value => tokenRoles.includes(value));
        return result;
    }

    static hasAnyRoles(token, userRoles)
    {
        var tokenPayload = this.getTokenPayload(token);
        var tokenRoles = tokenPayload.roles;
        var result = userRoles.some(value => tokenRoles.includes(value));
        return result;
    }

    static getTokenPayload(token){
        const decoded = decode(token);
        var result = {
            "aud": decoded.aud,
            "nbf": decoded.nbf,
            "exp": decoded.exp, 
            "expired": (decoded.exp < Date.now() / 1000),
            "name": decoded.name,
            "scope": decoded.scope,
            "token": token,
            "iat": decoded.iat,
            "iss": decoded.iss,
            "jti": decoded.jti,
            "locations": decoded.client_location ?? [],
            "app": decoded.client_app ?? "",
            "roles": decoded.client_role ?? []
        };
        // convert values that may not be arrays into arrays
        result.locations = Array.isArray(result.locations) ? result.locations : result.locations.split("nonsensedelimeter");
        result.roles = Array.isArray(result.roles) ? result.roles : result.roles.split("nonsensedelimeter");
        return result;
    }

    static isValidToken(token)
    {
        // this is lame. There are a lot of different tokens. We're describing the one we use specifically.
        var decoded = this.getTokenPayload(token);
        return ((decoded.exp) && (decoded.exp !== ""));
    }

    static isTokenExpired(token) {
        try {
            const decoded = decode(token);
            let now = (new Date() / 1000);
            if (decoded.exp < now) { // Checking if token is expired. 
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return true;
        }
    }

    static isTokenAlmostExpired(token, withinSeconds)
    {
        
    }

    static toExpiredDateTime(token)
    {
        const decoded = decode(token);
        let tokenExp = decoded.exp;
        var expDate = new Date(tokenExp * 1000);
        return expDate;
    }


}