import React, { Component } from "react";
import MultiToggle from "react-multi-toggle";
import "../../../assets/css/multitoggle.css";


const viewSelectionOptions = [
    {displayName: 'liquidation', value: 'liquidation'},
    {displayName: 'details', value: 'details'}
];


export default class DetailsToggleCard extends Component {


    onViewSelectionSelect = value => {
        this.setState({viewSelection: value});
        this.onRuleChange("viewSelection", value);
    }



      render()
      {
          return(

            <div style={{marginLeft: "15vw", maxWidth: "75vw", display: "flex", flexDirection: "row", justifyContent: "space-evenly", fontSize: "1.2vw", width: "100%"}}>

                <MultiToggle
                    options={viewSelectionOptions}
                    selectedOption={this.props.panelView}
                    onSelectOption={(value) => this.props.onSingleRuleChange("panelView", value)}
                />
            </div>
          
          )
      }

}
