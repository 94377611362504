import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { currencyFormatter, activeChargeAmountFormatter } from "../../../components/lib/agGridRenderers/agGridFormatters";
import { toUsDollars } from "../../../components/lib/Javascript/jsUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench, faUnlink } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import CoreService from "../../../services/CoreService";

import { AppSecurityKey } from "../../../models/AppSecurityKey";
import RoleManager from "../../../models/RoleManager";

import ModalAdjustmentDialog from "../Components/ModalAdjustmentDialog";

const DelSwal = withReactContent(Swal);

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";

const columnDefs = [
  {
    headerName: "Category",
    field: "charge.serviceGroup",
    sortable: true,
    filter: true,
    resizable: true,
    minWidth:120,
    maxWidth:160,
    cellStyle: params => {
      if ((!params.value) || (params.value.trim()=== ""))
      {
        return {backgroundColor: "red"}
      }
    }
  },
  {
    headerName: "Amount",
    headerClass: "ag-right-aligned-header",
    field: "extAmount",
    sortable: true,
    filter: true,
    resizable: true,
    maxWidth:120,
    valueFormatter: currencyFormatter,
    cellStyle: (params) => {
      if (params.data.activeExtAmount !== params.data.extAmount) {
        return { color: "lightgrey", "text-align": "right" };
      } else {
        return { "text-align": "right" };
      }
    },
  },
  {
    headerName: "Adj Amount",
    headerClass: "ag-right-aligned-header",
    field: "activeExtAmount",
    sortable: true,
    filter: true,
    resizable: true,
    maxWidth:120,
    cellStyle: (params) => {
      if (params.data.activeExtAmount !== params.data.extAmount) {
        return {
          color: "darkgreen",
          backgroundColor: "lightblue",
          "text-align": "right",
        };
      } else {
        return { "text-align": "right" };
      }
    },
    cellRenderer: activeChargeAmountFormatter
  },
];

export default class ServicesGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridSchema: [],
      schema: null,
      gridData: [],
      isLoading: true,
      selectedRow: null,
      propsDataChanged: false,
      showAdjust: false,
      hasAdjustment: false
    };

    this.coreService = new CoreService();

    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.showAdjustmentDialog = this.showAdjustmentDialog.bind(this);
    this.hideAdjustmentDialog = this.hideAdjustmentDialog.bind(this);
    this.onAdjustmentComplete = this.onAdjustmentComplete.bind(this);
    this.onAdjustmentDeleteRequest = this.onAdjustmentDeleteRequest.bind(this);
  }

  async onAdjustmentDeleteRequest()
  {
    if (!this.state.selectedRow)
    {
      DelSwal.fire({icon: "error", title: "Remove Adjustment", text: "Select a row first!"});
        return;
    }
    if (!this.state.hasAdjustment)
    {
      DelSwal.fire({icon: "error", title: "Remove Adjustment", text: "It looks like this line doesn't have an adjustment to remove?"});
      return;
    }
    DelSwal.fire
    (
      {
        icon: "question",
        title: "Remove Adjustment?",
        text: "Confirm you want to permanently remove the exiting adjustment",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel"
      }
    ).then ( async (result) =>
      {
        if (result.value)
        {
          var lotId = this.props.lot.lotId;
          var adjustmentKey = this.state.selectedRow.charge.serviceGroup;
          var deleteResult = await this.coreService.PostAdjustDeleteRequest(lotId, adjustmentKey, this.props.userSession.token);
          await this.props.onRefreshLotDataRequest();
          this.props.onToastRequest("success", "Adjustment (" + adjustmentKey + ") has been removed");
          //return DelSwal.fire({icon: "success", text: "The adjustment has been removed"});
        }
      }
    );

  }

  onAdjustmentRequest() 
  {
    if (!RoleManager.hasRoleStatusRights(AppSecurityKey.ADJUST_LOT, this.props.lot.workflowStatus.workflowStatus, this.props.userSession.token))
    {
        alert(AppSecurityKey.ADJUST_LOT.errorMessage);
        return;
    }
    if (!this.state.selectedRow) {
      DelSwal.fire({icon: "error", title: "Remove Adjustment", text: "Select a row first!"});
      return;
    }
    if (!RoleManager.hasRoleStatusRights(AppSecurityKey.ADJUST_LOT, this.props.lot.workflowStatus.workflowStatus, this.props.userSession.token))
    {
        alert(AppSecurityKey.ADJUST_LOT.errorMessage);
        return;
    }
    this.showAdjustmentDialog();
  }

  onRowDoubleClicked() 
  {
    if (!RoleManager.hasRoleStatusRights(AppSecurityKey.ADJUST_LOT, this.props.lot.workflowStatus.workflowStatus, this.props.userSession.token))
    {
        alert(AppSecurityKey.ADJUST_LOT.errorMessage);
        return;
    }
    this.showAdjustmentDialog();
  }

  showAdjustmentDialog() {
    this.setState({ showAdjust: true });
  }

  hideAdjustmentDialog() {
    this.setState({ showAdjust: false });
  }

  async onAdjustmentComplete(adjustment) {
    var origDataRow = this.state.selectedRow;
    this.setState({ selectedRow: origDataRow });
    if (adjustment.adjustType === "charge") {
      await this.coreService.PostChargeAdjustRequest(this.props.lot, adjustment, this.props.userSession.token);
    }
    await this.props.onRefreshLotDataRequest();
    this.props.onToastRequest("success", "Adjustment has been completed");
    this.hideAdjustmentDialog();
  }

  onSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();
    var rowData = selectedNodes[0].data;
    var hasAdjustment = (rowData.extAmount !== rowData.activeExtAmount); 
    this.setState({ 
      selectedRow: rowData,
      hasAdjustment: hasAdjustment
    });
  }

  onEditGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  render() {
    const divStyle = {
      width: "100%",
      height: "32vh",
    };

    const gridData = this.props.data ? this.props.data : [{}];
    const totalExtAmount = this.props.totalServices
      ? this.props.totalServices
      : 0;
    return (
      <div style={{ minWidth: "28vw", margin: "1vw" }}>
        <ModalAdjustmentDialog
          show={this.state.showAdjust}
          onRequestModalAdjustmentDialogClose={this.hideAdjustmentDialog}
          adjustmentType="charge"
          lot={this.props.lot}
          dataRow={this.state.selectedRow}
          onAdjustmentComplete={this.onAdjustmentComplete}
        />
        <Card>
          <Card.Header style={{ display: "flex", flexDirection: "row" }}>
            Expenses&nbsp;&nbsp;
            <p
              style={{ marginBottom: "5px", marginRight: "5px" }}
              data-tip="Adjust"
            >
              <Button
                size="sm"
                variant="primary"
                disabled={!RoleManager.hasRoleStatusRights(AppSecurityKey.ADJUST_LOT, this.props.lot.workflowStatus.workflowStatus, this.props.userSession.token)}
                onClick={(e) => this.onAdjustmentRequest(e)}
              >
                <FontAwesomeIcon icon={faWrench} />
                &nbsp;&nbsp;Adjust
              </Button>
            </p>
            <p
              style={{ marginBottom: "5px", marginRight: "5px" }}
              data-tip="Remove Adjustment"
            >
              <Button
                size="sm"
                variant="primary"
                hidden={!this.state.hasAdjustment}
                onClick={(e) => this.onAdjustmentDeleteRequest(e)}
              >
                <FontAwesomeIcon icon={faUnlink} />
                &nbsp;&nbsp;Remove Adjustment
              </Button>
            </p>
          </Card.Header>
          <Card.Body>
            <div className="ag-theme-balham" style={divStyle}>
              <AgGridReact
                rowSelection="single"
                enableRangeSelection={false}
                pagination={true}
                columnDefs={columnDefs}
                rowData={gridData}
                animateRows={true}
                onGridReady={this.onGridReady}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
                onRowDoubleClicked={this.onRowDoubleClicked.bind(this)}
              />
            </div>
          </Card.Body>
          <Card.Footer style={{ display: "flex", justifyContent: "space-between" }}><span>&nbsp;</span><span>Total Amount: <span style={{fontWeight: "bold"}}>{toUsDollars(totalExtAmount)}</span></span></Card.Footer>
        </Card>
      </div>
    );
  }
}
