import React, { Component } from "react";
import { Card, CardBody, CardFooter, Stack, StackDivider, Text, Box, Spacer, Divider, HStack } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartPulse } from "@fortawesome/free-solid-svg-icons";
import HealthFunctions from "../pages/Main/Components/Health/HealthFunctions";


export default class Healthcard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {
        return (

            <Card
                direction="column"
                overflow='hidden'
                size='lg'
                background='white'
                height="100%"
            >
                <Stack divider={<StackDivider borderColor='gray.200' width="90%" alignSelf="center"/>}  align="center">
                    <CardBody padding="2">
                        <HStack spacing="5vw" justify="center">
                            <FontAwesomeIcon icon={faHeartPulse} color={HealthFunctions.getHealthColor(this.props.healthStatus)} size="3x"/>
                            <Box justify="right">
                                <Text style={{fontStyle:"italic",  textAlign:"end"}}>{this.props.cardTitle}</Text>
                                <Text fontSize="28px">{this.props.healthStatus}</Text>
                            </Box>
                        </HStack>

                    </CardBody>
                    <CardFooter justify="left" w="100%" padding="10px">
                        <Text style={{fontSize: "12px", fontStyle:"italic", color:"#898787bd"}}>{this.props.statusText}</Text>
                    </CardFooter>
                </Stack>
            </Card>
        )

    }
}