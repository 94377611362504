export default class LotReturnModel
{
    constructor(netSalesRule, totalSales, totalRepackServices, totalServices, totalAdvances, commissionPct, netSales, commission, totalExpenses, growerReturn, netProceeds)
    {
        this.netSalesRule = netSalesRule || "postrepack";
        this.totalSales = totalSales || 0;
        this.totalRepackServices = totalRepackServices || 0;
        this.totalServices = totalServices || 0;
        this.totalAdvances = totalAdvances || 0;
        this.commissionPct = commissionPct || 0.0;
        this.netSales = netSales || 0;
        this.commission = commission || 0.0;
        this.totalExpenses = totalExpenses || 0;
        this.growerReturn = growerReturn || 0;
        this.netProceeds = netProceeds || 0;
        this.toLotReturnData = this.toLotReturnData.bind(this);
    }

    static toLotReturnModel(inOutData, servicesData, netSalesRule, commissionPct)
    {
        var model = new LotReturnModel(netSalesRule, inOutData.totalSales, servicesData.totalRepackServices, 
            servicesData.totalServices, servicesData.totalAdvances, commissionPct);
        model.netSales = (netSalesRule === "prerepack")
          ? inOutData.totalSales - servicesData.totalRepackServices
          : inOutData.totalSales;
        model.commission = (model.netSales * commissionPct);
        model.totalExpenses = servicesData.totalServices;
        model.growerReturn = inOutData.totalSales - model.totalExpenses;
        model.netProceeds = model.growerReturn - model.commission - servicesData.totalAdvances;
        return model;     
    }

    toLotReturnData()
    {
        var result = {
            totalSales: this.totalSales,
            netSales: this.netSales,
            advances: this.totalAdvances,
            repackCosts: this.totalRepackServices,
            serviceCosts: this.totalServices,
            commissionPct: this.commissionPct,
            commission: this.commission,
            totalExpenses: this.totalExpenses,
            growerReturn: this.growerReturn,
            netProceeds: this.netProceeds,
          };
          return result;
    }
}