const changeLog = [
  {
    version: "05.01.23",
    issues: [
      { id: "", description: "Login Image change" },
    ]
  },
  {
    version: "05.01.21",
    issues: [
      { id: "LGSGAA-341", description: "Modified Workflow to allow lgs.approver to move from approved status to preparing (not just pending)" },
      { id: "LGSGAA-298", description: "Fixed bug in PDF Sales Configurations to use adjusted quantity, not unadjusted quantity." },
      { id: "LGSGAA-340", description: "Modified sales summary and sales configuration boxes to filter out (post adjustment) zero-quantity lines per user request." },
    ]
  },
  {
    version: "05.01.20",
    issues: [
      { id: "LGSGAA-336", description: "Expanded History Grid Date Arrived Column" },
      { id: "LGSGAA-337", description: "Allow all roles that can update lots to refresh lot grid." },
      { id: "LGSGAA-333", description: "Approver role was not loading liquidations. Fixed issue with required supplierconfig reader and servicemap reader." },
      { id: "LGSGAA-334", description: "Sender role can now view liquidations (required to send PDF)" },
      { id: "LGSGAA-332", description: "Fixed issue with User dialog retaining values from previous entry." },
      { id: "LGSGAA-331", description: "Removed system roles so admins do not assign them." }
    ]
  }
];

export default changeLog;
