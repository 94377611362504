import React, { Component } from "react";
import { Card } from "react-bootstrap";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";

const columnDefs = [
    { headerName: "Container", field: "containerId", sortable: true, filter: true, resizable: true, minWidth: 100,  },
    { headerName: "Date", field: "receivedDate", sortable: true, filter: true, resizable: true, minWidth: 100, maxWidth: 120 }
];



export default class ContainerGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridSchema: [],
            schema: null,
            gridData: [],
            isLoading: true,
            selectedRow: null
        }
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.totalContainerAmount = this.totalContainerAmount.bind(this);
    }



    onSelectionChanged() {
        var selectedNodes = this.gridApi.getSelectedNodes();
        var rowData = selectedNodes[0].data;
        this.setState({ selectedRow: rowData });
    }

    onEditGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit(params)
    };


    totalContainerAmount(data){
        var result = data.reduce((r, o) => 
        {
            return r + 1
        }, 0);
        return result;
    }




    render() {

    
        const divStyle = {
            width: "100%",
            height: "32vh"
        };

        console.log("Container data: ");
        console.info(this.props.data);
        const gridData = (this.props.data) ? this.props.data : [{}];
        const totalContainerAmount = (this.props.data) ? this.totalContainerAmount(this.props.data): 0;
        return (
            <div style={{ minWidth: "28vw", margin: "1vw" }}>
                <Card>
                    <Card.Header>Containers</Card.Header>
                    <Card.Body>
                        <div className="ag-theme-balham" style={divStyle}>
                            <AgGridReact
                                rowSelection="single"
                                enableRangeSelection={false}
                                pagination={true}
                                columnDefs={columnDefs}
                                rowData={gridData}
                                animateRows={true}
                                onGridReady={this.onGridReady}
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        Total: <span style={{fontWeight: "bold"}}>{totalContainerAmount}</span>
                    </Card.Footer>
                </Card>
            </div>
        );
    }
}