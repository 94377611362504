import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import InOutAdjustCard from "./InOutAdjustCard";
import ChargeAdjustCard from ".//ChargeAdjustCard";

export default class ModalAdjustmentDialog extends Component 
{
  constructor() {
    super();
    this.state = {
      adjustment: null
    };

    this.onOkButtonClick = this.onOkButtonClick.bind(this);
    this.onInOutAdjustment = this.onInOutAdjustment.bind(this);
    this.onChargeAdjustment = this.onChargeAdjustment.bind(this);
  }



  onInOutAdjustment(adjustment)
  {
    adjustment.lotid = this.props.lot.lotId;
    adjustment.adjustType = "inOut";
    console.log("InOut Value Changes: ");
    console.info(adjustment);
    this.setState({adjustment: adjustment});
  }


    onChargeAdjustment(adjustment)
    {
      adjustment.lotid = this.props.lot.lotId;
      adjustment.adjustType = "charge";
      console.log("Charge Value Changes: ");
      console.info(adjustment);
      this.setState({adjustment: adjustment});
    }

  async onOkButtonClick(e) {
    await this.props.onAdjustmentComplete(this.state.adjustment);
  }

  render() {
    console.info(this.props.dataRow);
    var adjustForm = (this.props.show && this.props.dataRow) ? 
        (this.props.adjustmentType === "inOut") ?
          <InOutAdjustCard
              dataRow={this.props.dataRow}
              onInOutAdjustment={this.onInOutAdjustment}
          />
          : 
            <ChargeAdjustCard
              dataRow={this.props.dataRow}
              onChargeAdjustment={this.onChargeAdjustment}
          />
        : <span/>

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onModalCloseRequest}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.adjustmentType}&nbsp;Adjustment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {adjustForm}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => this.onOkButtonClick(e)}
          >
            Ok
          </Button>
          <Button
            variant="secondary"
            onClick={(e) => this.props.onRequestModalAdjustmentDialogClose(e)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
