import moment from "moment";

export function currencyFormatter(params) {
  try {
    var val = params.value ? params.value : 0;
    var sign = "$";
    var sansDec = val.toFixed(2);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return sign + `${formatted}`;
  } catch (error) {
    console.group("Currency Formatter error");
    console.log("Params Value: " + params.value);
    console.info(params);
    console.groupEnd();
  }
}

export function commaFormatter(params) {
  try {
    var val = params.value ? params.value : 0;
    var sansDec = val.toFixed(0);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${formatted}`;
  } catch (error) {
    console.group("Currency Formatter error");
    console.log("Params Value: " + params.value);
    console.info(params);
    console.groupEnd();
  }
}

export function activeQuantityFormatter(params) {
  try {
    if (params.data.activeQuantity === params.data.quantity) {
      return "";
    }
    var val = params.value ? params.value : 0;
    var sansDec = val.toFixed(0);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${formatted}`;
  } catch (error) {
    console.group("Currency Formatter error");
    console.log("Params Value: " + params.value);
    console.info(params);
    console.groupEnd();
  }
}

export function activeSaleAmountFormatter(params) {
  if (params.data.activeSaleAmount === params.data.saleAmount) {
    return "";
  }
  return currencyFormatter(params);
}

export function activeChargeAmountFormatter(params) {
  if (params.data.activeExtAmount === params.data.extAmount) {
    return "";
  }
  return currencyFormatter(params);
}

export function ifClosedDateOnlyFormatter(params) {
  var isClosed = params.data.isClosed ? params.data.isClosed : false;
  var dateOnly =
    !params.value || !isClosed ? "" : moment(params.value).format("yyyy-MM-DD");
  return dateOnly;
}

export function quantityFormatter(params) {
  if (params.data.activeQuantity !== params.data.quantity) {
    return { color: "grey", backgroundColor: "#c5f4e3", "text-align": "right" };
  } else {
    return { color: "black", "text-align": "right" };
  }
}

export function ifAdjustExtAmountFormatter(params) {
  if (params.data.activeExtAmount === params.data.extAmount) {
    return { color: "black", "text-align": "right" };
  } else {
    return {
      color: "black",
      backgroundColor: "#c5f4e3",
      "text-align": "right",
    };
  }
}

export function ifAdjustQtyFormatter(params) {
  if (params.data.activeQty === params.data.qty) {
    return { color: "black", "text-align": "right" };
  } else {
    return {
      color: "black",
      backgroundColor: "#c5f4e3",
      "text-align": "right",
    };
  }
}

export function dateOnlyFormatter(params) {
  return params.value ? moment(params.value).format("yyyy-MM-DD") : "";
}

export function dateTimeFormatter(params) {
  return moment(params.value).format("yyyy-MM-DD  HH:mm:ss");
}

export function decimalLengthFormatter(params) {
  return params.value.toFixed(4);
}
