import React, { Component } from "react";
import {  Form } from "react-bootstrap";
//import "bootstrap/dist/css/bootstrap.min.css";
//import InOutSummaryLine from "../Models/InOutSummaryLine";
import {toUsDollars} from "../../../components/lib/Javascript/jsUtils";

export default class InOutAdjustCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adjQuantity: null,
      adjPrice: null,
      adjSaleAmount: null
    }
  }






  onAdjustedQuantityChange = (e) => {
    //console.log("New Qty");
    //console.info(e.target.value);
    var adjQtyInt = parseInt(e.target.value);
    //console.info(adjQtyInt);
    // establish the adjSaleAmount
    var adjSaleAmountDecimal = (this.state.adjSaleAmount) ? +(this.state.adjSaleAmount): +(this.props.dataRow.activeSaleAmount);
    // recalculate the price
    var newPrice = adjSaleAmountDecimal / adjQtyInt;
    this.setState({
      adjQuantity: adjQtyInt,
      adjPrice: newPrice
    });
    if (!this.state.adjSaleAmount)
    {
      this.setState({adjSaleAmount: adjSaleAmountDecimal});
    }
    var result = {"id": "0", "lotId": "", "adjustmentKey": this.props.dataRow.lineKey, 
    "quantity": this.props.dataRow.quantity, "adjustedQuantity": adjQtyInt, 
    "saleAmount": this.props.dataRow.saleAmount, "adjustedSaleAmount": adjSaleAmountDecimal};
    console.log("result: ");
    console.info(result);
    this.props.onInOutAdjustment(result);
  };

  onAdjustedSaleAmountChange = (e) => {
    var adjSaleAmountDecimal = +(e.target.value);
    var adjQtyInt = (this.state.adjQuantity) ? parseInt(this.state.adjQuantity): parseInt(this.props.dataRow.activeQuantity);
    // recalculate price
    var newPrice = adjSaleAmountDecimal / adjQtyInt;
    this.setState({
      adjSaleAmount: adjSaleAmountDecimal,
      adjPrice: newPrice
    });
    if (!this.state.adjQuantity)
    {
      this.setState({adjQuantity: adjQtyInt});
    }
    var result = {"id": "0", "lotId": this.props.dataRow.lotId, "adjustmentKey": this.props.dataRow.lineKey, 
      "quantity": this.props.dataRow.quantity, "adjustedQuantity": adjQtyInt, 
      "saleAmount": this.props.dataRow.saleAmount, "adjustedSaleAmount": adjSaleAmountDecimal};
    console.log("Result: ");
    console.info(result);//if (!Number.isInteger(e.target.value)) return;
    this.props.onInOutAdjustment(result);
    
  };

  render() 
  {

    var price = +(this.props.dataRow.saleAmount) / parseInt(this.props.dataRow.quantity)
    const priceDisplay = toUsDollars(price);

    var adjPrice = (this.state.adjSaleAmount) ?
       +(this.state.adjSaleAmount) / parseInt(this.state.adjQuantity)
      :       this.props.dataRow.price;
    const adjPriceDisplay = toUsDollars(adjPrice);

    return (

      <span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            backgroundColor: "lightgrey",
            color: "white",
            marginLeft: "1vw",
            marginRight: "1vw",
            borderRadius: "1px",
          }}
        >
          <div style={{ display: "block" }}>
            <div>Commodity</div>
            <div style={{ color: "grey", fontSize: "12px" }}>
              {this.props.dataRow.commodity}
            </div>
          </div>
          <div style={{ display: "block" }}>
            <div>Size</div>
            <div style={{ color: "grey", fontSize: "12px" }}>
              {this.props.dataRow.size}
            </div>
          </div>
          <div style={{ display: "block" }}>
            <div>Style</div>
            <div style={{ color: "grey", fontSize: "12px" }}>
              {this.props.dataRow.style}
            </div>
          </div>
          <div style={{ display: "block" }}>
            <div>Variety</div>
            <div style={{ color: "grey", fontSize: "12px" }}>
              {this.props.dataRow.variety}
            </div>
          </div>
          <div style={{ display: "block" }}>
            <div>Method</div>
            <div style={{ color: "grey", fontSize: "12px" }}>
              {this.props.dataRow.method}
            </div>
          </div>
        </div>



        <div>
           <br/>
          <div>Original Values</div>
          <hr/>
          <Form style={{ display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
            <Form.Group>
              <Form.Label style={{color: "grey"}}>Original Qty</Form.Label>
              <Form.Control
                id="saleQty"
                type="text"
                placeholder="qty"
                readOnly
                disabled
                value={this.props.dataRow.quantity}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label style={{color: "grey"}}>Orig Price</Form.Label>
              <Form.Control
                id="price"
                type="text"
                placeholder="Price"
                readOnly
                disabled
                value={priceDisplay}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label style={{color: "grey"}}>Orig Extended Amt$</Form.Label>
              <Form.Control
                id="saleExtAmount"
                type="text"
                placeholder="Sale Ext Amt"
                readOnly
                disabled
                value={this.props.dataRow.saleAmount}
              />
            </Form.Group>
          </Form>


          <div>Adjusted Values</div>
          <hr/>
          <Form style={{ display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
            <Form.Group>
              <Form.Label style={{color: "grey"}}>Adj Qty</Form.Label>
              <Form.Control
                id="adjustedQty"
                placeholder={this.props.dataRow.activeQuantity}
                type="number"
                onChange={(e) => this.onAdjustedQuantityChange(e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label style={{color: "grey"}}>Adj Price</Form.Label>
              <Form.Control
              id="adjustedPrice"
                type="number"
                readOnly
                disabled
                placeholder={adjPriceDisplay}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label style={{color: "grey"}}>Adj Extended Amt$</Form.Label>
              <Form.Control
              id="adjustedExtAmount"
                type="number"
                placeholder={this.props.dataRow.activeSaleAmount}
                onChange={(e) => this.onAdjustedSaleAmountChange(e)}
              />
            </Form.Group>
          </Form>


        </div>

      </span>
    );
  }
}
