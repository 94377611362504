import ConfigService from "./ConfigService";
import HttpService from "./HttpService";




export default class getNotificationServiceHost
{
    constructor(domain)
    {
        this.configService = new ConfigService();
        this.domain = domain || this.configService.getNotificationServiceHost();
    }



    async fetchVersion(token)
    {
        const path = "/api/admin/version";
        var response = await HttpService.get(this.domain, path, token);
        return response;   
    }

    async pingCore(token) 
    {
        const path = "/api/admin/ping";
        var response = await HttpService.get(this.domain, path, token);
        return response;
      }
    
      async postEmailRequest(emailRequest, token) 
      {
        const path = "/api/notification/gcid/lgs/directemailrequest";
        var url = path;
        // add host/prefixes to all links
        if (emailRequest.links) {
          var fullUrlLinks = emailRequest.links.map((link) => {
            return {
              description: link.description,
              url:
                this.configService.getLotReportHostAddr("lgs") + link.url,
            };
          });
          emailRequest.from = this.configService.getDefaultEmailFromAddress();
          emailRequest.links = fullUrlLinks;
        }
        console.log(JSON.stringify(emailRequest));
        let postResult = await HttpService.postJson
        (
          this.domain,
          url,
          token,
          JSON.stringify(emailRequest)
        );
        return postResult;
      }
    
    

}