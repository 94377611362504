
import {parseISO, formatISO, isAfter, differenceInDays} from 'date-fns';

export default class JavascriptHelpers
{

    //https://stackoverflow.com/questions/15329503/conversion-of-a-hash-function-from-c-sharp-to-javascript-has-issues-with-bitwise
    static getStableHash(s, hashlength) {
      var hash = 0;
      var bytes = Uint8Array.from(s);
      //var bytes = stringToBytes(s);   // this function just grabs a byte array for the given input string
      for (var i = 0; i < bytes.length; i++) {
        hash += bytes[i];
        hash += hash << 10;
        hash ^= hash >> 6;
      }
      // final avalanche
      hash += hash << 3;
      hash ^= hash >> 11;
      hash += hash << 15;
  
      //return Math.round(hash % hashlength);
      return (hash >>> 0) % hashlength;
    }

    static toCamel(o) {
        var newO, origKey, newKey, value;
        if (o instanceof Array) {
          return o.map(function (value) {
            if (typeof value === "object") {
              value = JavascriptHelpers.toCamel(value);
            }
            return value;
          });
        } else {
          newO = {};
          for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
              newKey = (
                origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
              ).toString();
              value = o[origKey];
              if (
                value instanceof Array ||
                (value !== null && value.constructor === Object)
              ) {
                value = JavascriptHelpers.toCamel(value);
              }
              newO[newKey] = value;
            }
          }
        }
        return newO;
      }


      static toIso(date)
      {
        return formatISO(date);
      }

      static isoNow()
      {
        return formatISO(new Date());
      }

      static isoUtcNow()
      {
        return (new Date()).toISOString();
      }

      static isoDateIsAfter(dt, comparedToDt)
      {
          const thisDate = parseISO(dt);
          const compareToDate = parseISO(comparedToDt);
          return isAfter(thisDate, compareToDate);
      };

     static daysFromNow(isoStartDt)
     {
      const startDate = parseISO(isoStartDt);
      var result = differenceInDays(new Date(), startDate);
      return result;
     }

    static areDaysFromNowBetween(isoStartDt, fromDaysOld, toDaysOld)
    {
      var daysFromNow = this.daysFromNow(isoStartDt); 
      var result = (daysFromNow >= fromDaysOld && daysFromNow <= toDaysOld);
      return result;
    }





}