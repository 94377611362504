import React, { Component } from "react";
import {  Button} from "react-bootstrap";
import _ from "lodash";

import { AgGridReact } from "ag-grid-react";
// import "ag-grid-community/dist/styles/ag-grid.css";

import "ag-grid-community/dist/styles/ag-theme-balham.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faRedoAlt, faMap } from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from 'react-tooltip';
import {Card, CardBody, Text } from '@chakra-ui/react';

import CoreService from "../../../services/CoreService";

import ModalServiceGroupMapDialog from "./ModalServiceGroupMapDialog";

/*
const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";
*/

const columnDefs = [
    { headerName: "Famous Description", field: "descript", sortable: true, filter: true, resizable: true, minWidth:220, maxWidth:350  },
    { headerName: "LGS Service Group", field: "serviceGroup", sortable: true, filter: true, resizable: true,         
        cellStyle: params => {
            if ((!params.value) || (params.value.trim()=== ""))
            {
                return {backgroundColor: "red"}
            }
        }
    }, 
    { headerName: "Famous Id", field: "id", sortable: true, filter: true, resizable: true, minWidth:80, maxWidth:120  },
    { headerName: "Famous Idx", field: "erpId", sortable: true, filter: true, resizable: true, minWidth:80, maxWidth:120 }


  
];



export default class ServiceMapGrid extends Component
{
    constructor(props) {
        super(props);
        this.coreService = new CoreService();
        this.state = {
            gridSchema: [],
            schema: null,
            gridData: [],
            isLoading: true,
            selectedRow: null,
            selectedServiceDescript: "",
            selectedServiceGroup: "",
            showModal: false,
            groupOptions: []
        }
        this.onModalOpenRequest = this.onModalOpenRequest.bind(this);
        this.onModalCloseRequest = this.onModalCloseRequest.bind(this);
        this.onModalOkRequest = this.onModalOkRequest.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.requestGridDataRefresh = this.requestGridDataRefresh.bind(this);
        this.refreshGridData = this.refreshGridData.bind(this);
    }

    async componentDidMount() {
        await this.refreshGridData();
    }

    onModalOpenRequest(e)
    {
        if (e)
        {
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.state.selectedRow == null)
        {
            alert("Please Select a Service Detail to Map first!");
            return;
        }
        this.setState({showModal: true});
    }

    onModalCloseRequest()
    {
      this.setState({showModal: false});
    }

    async onModalOkRequest(selectedServiceDescript, existingServiceGroup, newServiceGroup)
    {
        var existingServiceMapItem = this.state.gridData.find(d => d.descript === selectedServiceDescript);
        if (!existingServiceMapItem)
        {
            alert("Unexpected problem finding existing service map item to update!");
            return;
        }
        var updatedServiceMapItem = _.clone(existingServiceMapItem);
        updatedServiceMapItem.serviceGroup = newServiceGroup.trim();
        await this.coreService.postServiceMapItem("lgs", updatedServiceMapItem, this.props.userSession.token);
        await this.refreshGridData();
        this.onModalCloseRequest();
        this.props.onToastRequest("information", "Service Description " + selectedServiceDescript + " Service Group changed from " + existingServiceGroup + " to " + newServiceGroup);
    }

    async refreshGridData() {
        this.setState({ isLoading: true });
        var gridDataResponse = await this.coreService.fetchServiceMap(this.props.userSession.token);
        var newGridData = gridDataResponse.dataResult.services;
        var newGroupOptions = Array.from(new Set(newGridData.map((m => m.serviceGroup))));
        console.log("the new grid data");
        console.log(newGridData);
        this.setState({
            gridData: newGridData,
            isLoading: false,
            groupOptions: newGroupOptions
        });
    }

    onRowDoubleClicked()
    {
        this.onModalOpenRequest();
    }

    onSelectionChanged() {
        var selectedNodes = this.gridApi.getSelectedNodes();
        var rowData = selectedNodes[0].data;
        this.setState({ 
            selectedRow: rowData, 
            selectedServiceDescript: rowData.descript,
            selectedServiceGroup: rowData.serviceGroup 
        });
        console.log("RowData: ");
        console.info(rowData);
        this.props.onSelectionChange(rowData.supplierId);
    }

    onEditGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit(params)
    };

    async requestGridDataRefresh(e)
    {
        await this.refreshGridData();
        this.props.onToastRequest("information", "Service Group Mapping Refreshed");
    }





    render() {

        const divStyle = {
            width: "100%",
            height: "40vh"
        };

        const gridData = (this.state.gridData) ? this.state.gridData : [{}];
        const loadingIcon = this.state.isLoading ? (
            <FontAwesomeIcon icon={faSyncAlt} color="green" spin />
          ) : (
            ""
          );

        return (
                <span >
                    <Tooltip/>
                    <ModalServiceGroupMapDialog 
                        show={this.state.showModal} 
                        onModalCloseRequest={this.onModalCloseRequest}
                        onModalOkRequest={this.onModalOkRequest}
                        serviceDescription={this.state.selectedServiceDescript}
                        existingServiceGroup={this.state.selectedServiceGroup}
                        groupOptions={(this.state.groupOptions) ? this.state.groupOptions: []}
                    />
                <Card style={{ width: "93vw", marginBottom: "10px"}}
                overflow='hidden'
                background='white'
                >
                    <Text as="h4" style={{ textAlign: "center" }}>Service Group Map Listing</Text>
                    <CardBody>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            &nbsp;
                            <p data-tip="Map Current Service Description">
                                <Button variant="primary" onClick={(e)=>this.onModalOpenRequest(e)}>
                                    <FontAwesomeIcon icon={faMap} />
                                    &nbsp;&nbsp;Map to Service Group
                                </Button>
                            </p>
                            &nbsp;
                            <p data-tip="Refresh List">
                                <Button variant="primary" onClick={(e)=>this.requestGridDataRefresh(e)}>
                                    <FontAwesomeIcon icon={faRedoAlt} />
                                    &nbsp;&nbsp;Refresh
                                </Button>
                            </p>

                            <span style={{ marginLeft: "48vw" }}>{loadingIcon}</span>
                        </div>

                        <div className="ag-theme-balham" style={divStyle}>
                            <AgGridReact
                                rowSelection="single"
                                enableRangeSelection={false}
                                pagination={true}
                                columnDefs={columnDefs}
                                rowData={gridData}
                                animateRows={true}
                                onGridReady={this.onGridReady}

                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                                onRowDoubleClicked={this.onRowDoubleClicked.bind(this)}
                            />
                        </div>
                    </CardBody>
                </Card>

                </span>
        );
    }
}