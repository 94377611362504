import React, { Component } from "react";
import {
    Box,
    Flex,
    Menu,
    Spacer,
    MenuButton,
    MenuItem,
    MenuList,
    MenuDivider,
    Button,
    Image,
    Text
} from '@chakra-ui/react';

import LGSLogo from "../../../assets/images/logos/lgs-logo.png";
import GlassbitLogo from "../../../assets/images/logos/mainheader-logo-left-transparency-2.png";
import "../../../assets/css/navbar.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSatelliteDish } from "@fortawesome/free-solid-svg-icons";

import ConfigService from "../../../services/ConfigService";

import RoleManager from "../../../models/RoleManager";
import { AppSecurityKey } from "../../../models/AppSecurityKey";
import JavascriptHelpers from "../../../components/lib/Javascript/JavascriptHelpers";
import "../../../assets/css/timer.css";
import ExpirationTimer from "./ExpirationTimer";


export default class Navbar extends Component {
    constructor(props) {
        super(props);
        this.configService = new ConfigService();
        this.state = {
            isDrawerOpen: false,
            customerLogoImgSrc: ""
        }
        this.toggleDrawer = this.toggleDrawer.bind(this)
    }

    componentDidMount() {
        let customerLogoImgSrc = this.configService.getCustomerLogoImagePath(this.props.userSession.gcid);
        this.setState({
            customerLogoImgSrc: customerLogoImgSrc
        });
    }

    toggleDrawer() {
        this.setState({
            isDrawerOpen: !this.state.isDrawerOpen
        })
    }

    render() {
        return (
            <div className={(this.props.isVisible) ? "navbar" : "navbar-hidden"}>
                <Flex w='100vw' justify={'space-between'}>
                    <Image
                        src={LGSLogo}
                        alt="Customer Logo"
                        boxSize='80px'
                        fit='scale-down'
                        marginLeft={'20px'}
                    />
                    <Text
                        fontSize='xl'
                        padding='1%'
                    >
                        Liquidation Control Center
                    </Text>
                    {/* <Spacer/>
                    <Image
                        src={GlassbitLogo}
                        alt="Glassbit"
                        boxSize='80px'
                        objectFit='scale-down'
                    /> */}
                    <Spacer></Spacer>
                    
                    <Box p='3'>

                    </Box>
                    {/* <Spacer></Spacer> */}
                    <span>&nbsp;<FontAwesomeIcon icon={faSatelliteDish} color={(this.props.lotWatcherRunning) ? "lightgreen" : "grey"} /></span>

                    <Box p='2'>

                        <Flex direction='column'>
                            <Text>{this.props.userSession.uid}</Text>
                            {/* <Text style={{fontSize: "10px", color: "darkgray"}}>[{(this.props.userSession.roles) ? this.props.userSession.roles.join(", ") : ""}]</Text> */}
                            {/* <Text>{(this.props.sessionExpiration) ? JavascriptHelpers.toIso(this.props.sessionExpiration) : ""}</Text> */}
                            <div >
                                <ExpirationTimer
                                    expires={this.props.sessionExpiration}
                                    alertSecondsLeft={this.props.alertSecondsLeft}
                                />
                            </div>
                        </Flex>
                    </Box>
                    {/* <Spacer></Spacer> */}
                    <Box p='3'>
                        {/* <Button variant='outline' onClick={this.toggleDrawer}>Filter Options</Button>
                         */}
                        <Menu>
                            <MenuButton as={Button} colorScheme="blue">
                                <FontAwesomeIcon icon={faBars} color="white" />
                            </MenuButton>
                            <MenuList>
                                <MenuItem color="black" onClick={(e) => this.props.onHomePageRequest()}>Home</MenuItem>
                                <MenuDivider />
                                <MenuItem color="black" isDisabled={!RoleManager.hasRoleRights(AppSecurityKey.VIEW_LIQUIDATION, this.props.userSession.token)} onClick={(e) => this.props.onLiquidationPageRequest()}>Liquidation</MenuItem>
                                <MenuItem color="black" isDisabled={!RoleManager.hasRoleRights(AppSecurityKey.EDIT_SERVICEMAPS, this.props.userSession.token)} onClick={(e) => this.props.onDataManagementPageRequest()}>Data Management</MenuItem>
                                <MenuItem color="black" isDisabled={!RoleManager.hasRoleRights(AppSecurityKey.EDIT_USERS, this.props.userSession.token)} onClick={(e) => this.props.onUserManagementPageRequest()}>User Management</MenuItem>
                                <MenuDivider />
                                <MenuItem color="black" onClick={(e) => this.props.onLogoutRequest()}>Log Out</MenuItem>
                                <MenuItem color="black" onClick={(e) => this.props.onUserGuideRequest()}>User Guide</MenuItem>
                                <MenuItem color="black" onClick={(e) => this.props.onAboutOpen(e)}>About</MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                </Flex>
            </div>
        )
    }
}