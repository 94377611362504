import React, { Component } from "react";
import {
    Box,
    Flex,
    Spacer,
    Image,
    Text
} from '@chakra-ui/react';


    function toDisplayString(seconds)
    {
        if (!seconds) return "--";
        let x = new Date(seconds * 1000).toISOString();
        return  (seconds < 3600) ? 
            new Date(seconds * 1000).toISOString().substring(14, 19)
            :
            new Date(seconds * 1000).toISOString().substring(11, 19)
    };



export default class ExpirationTimer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isRunning: false,
            expirationDate: new Date(),
            secondsRemaining: 0,
            expirationDisplay: ""
        }

        this.reset = this.reset.bind(this);
        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.update = this.update.bind(this);
        this.interval = null;
    }


    componentDidUpdate(prevProps, prevState)
    {
        if (this.props.expires !== prevProps.expires)
        {
            this.stop();
            this.reset(this.props.expires);
            this.start();
        }
    }


    reset(expirationDate)
    {
        // how many seconds? 
        let secondsRemaining = (expirationDate - new Date()) / 1000;
        let expirationDisplay = toDisplayString(secondsRemaining);
        this.setState({
            isRunning: false,
            expirationDate: expirationDate,
            secondsRemaining: secondsRemaining,
            expirationDisplay: expirationDisplay
        });
    }


    start()
    {
        if (this.state.isRunning) return;
        this.interval = setInterval(() => {
            this.update(this.state.secondsRemaining - 1);
          }, 1000);
    }

    stop()
    {
        this.setState({
            isRunning: false
        })
        clearInterval(this.interval);
    }

    update(secondsRemaining)
    {
        this.setState({secondsRemaining: secondsRemaining});
        this.setState({expirationDisplay : toDisplayString(secondsRemaining)});
    }



    render() {

        var inAlert = (this.props.alertSecondsLeft > this.state.secondsRemaining);
        var expToDisplay = (this.state.secondsRemaining < 1) ? "" : "Your session will expire in " + this.state.expirationDisplay;
        return (
            <div style={{color: (inAlert) ? "red": "lightgrey", fontSize: (inAlert) ? "24px" : "12px"}}>
                {expToDisplay}
            </div>
        )
    }
}