import {compress, decompress} from 'lz-string';

export default class LocalStorageService 
{

    // Initializing important variables
    /*
    constructor() {
        this.configService = new ConfigService();
        this.useCompression = this.configService.getValue("localcompression");
    }
    */

    prefix()
    {
        return "gcdex";
    }

    setKey(key, val){
        var storedKey = (this.useCompression) ? compress(key): key;
        var storedVal = (this.useCompression) ? compress(val): val;
        localStorage.setItem(storedKey, storedVal);
    }

    getKey(key){
        let storedKey = (this.useCompression) ? decompress(key): key;
        let val = localStorage.getItem(storedKey);
        let storedVal = (this.useCompression) ? decompress(val): val;
        return storedVal;
    }

    keyExists(key){
        let storedKey = (this.useCompression) ? decompress(key): key;
        return (this.getKey(storedKey)!=null);
    }

    removeKey(key){
        let storedKey = (this.useCompression) ? decompress(key): key;
        localStorage.removeItem(storedKey);    
    }



    saveSingleVal(key, val, shouldCompress=false)
    {
        let storedVal = (shouldCompress) ? compress(val) : val;
        localStorage.setItem(key, storedVal);
    }

    getSingleVal(key, shouldDecompress=false)
    {
        var item = localStorage.getItem(key);
        return (shouldDecompress) ? decompress(item) : item;
    }

    saveObjectVal(key, obj, shouldCompress=false)
    {
        let storedObj = (shouldCompress) ? compress(JSON.stringify(obj)) : JSON.stringify(obj);
        localStorage.setItem(key, storedObj);
    }

    getObjectVal(key, shouldDecompress=false)
    {
        let storedObj = localStorage.getItem(key);
        var result = (shouldDecompress) ? JSON.parse(decompress(storedObj)) : JSON.parse(storedObj);
        return result;
    }

    hasKey(key)
    {
        return (localStorage.getItem(key)!=null);
    }


    toUserKey(gcid, uid, keyTypeId="")
    {
        return this.prefix() + "~" + gcid + "~" + uid +
            (keyTypeId !== "") ? "~" + keyTypeId.toLowerCase().trim(): "";
    }

  

    hasLastUserSession()
    {
        const KEYTYPEID = this.prefix() + "~lastusersession";
        return (this.hasKey(KEYTYPEID));
    }

    getLastUserSession()
    {
        const KEYTYPEID = this.prefix() + "~lastusersession";
        return (this.hasKey(KEYTYPEID)) ? this.getObjectVal(KEYTYPEID, false) : {isValid: false};
    }

    saveLastUserSession(userSession)
    {
        const KEYTYPEID = this.prefix() + "~lastusersession";
        this.saveObjectVal(KEYTYPEID, userSession, false);
    }

    removeLastUserSession(userSession)
    {
        const KEYTYPEID = this.prefix() + "~lastusersession";
        this.removeKey(KEYTYPEID);
    }

    hasLastUserInfo()
    {
        const KEYTYPEID = this.prefix() + "~lastuserinfo";
        return (this.hasKey(KEYTYPEID));
    }

    getLastUserInfo()
    {
        const KEYTYPEID = this.prefix() + "~lastuserinfo";
        return (this.hasKey(KEYTYPEID)) ? this.getObjectVal(KEYTYPEID, false) : {isValid: false};
    }

    saveLastUserInfo(userInfo)
    {
        const KEYTYPEID = this.prefix() + "~lastuserinfo";
        this.saveObjectVal(KEYTYPEID, {"gcid": userInfo.gcid, "uid": userInfo.uid, "lastlocationid": userInfo.currentLocationId, "locationinfo": userInfo.locationInfo}, false);
    }

    hasAppInfo(gcid, uid)
    {
        const KEYTYPEID = this.prefix() + "~" + gcid + "~" + uid + "~appinfo";
        return this.hasKey(KEYTYPEID);
    }

    saveAppInfo(gcid, uid, appInfo)
    {
        const KEYTYPEID = this.prefix() + "~" + gcid + "~" + uid + "~appinfo";
        return this.saveObjectVal(KEYTYPEID, appInfo, false);
    }

    getAppInfo(gcid, uid, appInfo)
    {
        const KEYTYPEID = this.prefix() + "~" + gcid + "~" + uid + "~appinfo";
        return this.getObjectVal(KEYTYPEID, false);
    }

}