import React, { Component } from "react";
import { Container, Button, Modal, Form } from "react-bootstrap";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";





function cleanValue(val) {
    return (val) ? val.trim() : "";
}


export default class ModalUserDialog extends Component 
{

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            isWorking: false,
            userId: "",
            userIdErr: "",
            userName: "",
            userPwd: "",
            userPwd2: "",
            userNameErr: "",
            userDescript: "",
            userDescriptErr: "",
            userPwdErr: "",
            userRolesErr: "",
            passwordSelectable: true,
            passwordEditable: false,
            passwordType: "password",
            passwordShowLabel: "show",
            selectedLocations: [],
            selectedRoles: []
        }

        this.clearDialog = this.clearDialog.bind(this);
        this.initDialog = this.initDialog.bind(this);
        this.onFormValueChange = this.onFormValueChange.bind(this);
        this.onRoleValueChange = this.onRoleValueChange.bind(this);
        this.validate = this.validate.bind(this);
        this.postUser = this.postUser.bind(this);
        this.toRoleValues = this.toRoleValues.bind(this);
        this.userExists = this.userExists.bind(this);
        this.onResetPasswordClick = this.onResetPasswordClick.bind(this);
        this.togglePasswordShow = this.togglePasswordShow.bind(this);

        this.onLocationValueChange = this.onLocationValueChange.bind(this);
        this.onRoleValueChange = this.onRoleValueChange.bind(this);
    }

    componentDidMount()
    {
        this.clearDialog();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showNewModal !== this.props.showNewModal) {
            if (this.props.showNewModal) 
            {
                this.initDialog();
            }
        }
    }

    clearDialog()
    {
        console.log("Clearing User Dialog values...")
        this.setState({
            userIdErr: "",
            userNameErr: "",
            userDescriptErr: "",
            userRolesErr: "",

            userPwdErr: "",
            passwordSelectable: true,
            passwordEditable: false,
            isWorking: false,
            userId: "",
            userName: "",
            userDescript: "",
            userPwd: "",
            userPwd2: "",

            selectedRoles: [],

        });
    }

    initDialog() {
        // clear out state values

        if (this.props.editMode === "update") 
        {
            console.log("Initializing User Dialog values for Edit...")
            // update. Set the values
            this.setState(
                {
                    userId: this.props.currentUser.clientId,
                    userName: this.props.currentUser.clientName,
                    userDescript: this.props.currentUser.description,
                    userPwd: this.props.currentUser.secret,
                    userPwd2: this.props.currentUser.secret,
                    selectedRoles: Array.isArray(this.props.currentUser.roles) ? this.toRoleValues() : [],
                    passwordSelectable: true,
                    passwordEditable: false
                }
            );
        }
        else
        {
            console.log("Clearing User Dialog values for " + this.props.editMode + "...");
            this.clearDialog();
        }

    }

    onFormValueChange(e) {
        console.log("Form change: ");
        console.log("id: " + e.target.id);
        console.log("value: " + e.target.value);
        this.setState({ [e.target.id]: e.target.value })
    }


    onRoleValueChange(opts) {
        //var newOpts = (Array.isArray(opts) ? opts.map(x => x.value) : []);
        this.setState({
            selectedRoles: opts
        })
    }


    onLocationValueChange(opts) {
        //var newOpts = (Array.isArray(opts) ? opts.map(x => x.value) : []);
        this.setState({
            selectedLocations: opts
        })
    }

    onResetPasswordClick(checked) {
        if (!checked) {
            // reset password values
            this.setState({
                userPwd: this.props.currentUser.secret,
                userPwd2: this.props.currentUser.secret,
            });

        }
        this.setState({ passwordEditable: checked });
    }

    togglePasswordShow() {
        let lbl = this.state.passwordShowLabel;
        if (lbl === "show") {
            this.setState({
                passwordShowLabel: "hide",
                passwordType: "text"
            });
        } else {
            this.setState({
                passwordShowLabel: "show",
                passwordType: "password"
            });
        }
    }

    validate(user) {
        var userIdErr = (user.clientId === "") ? "*You must enter a User Id" : "";
        userIdErr = (user.clientId !== "" && user.clientId.length < 6) ? "* Your id must be at least 6 characters" : userIdErr;
        var userRolesErr = (user.roles.length < 1) ? "*You must select at least role for this user" : "";
        var userNameErr = "";
        var userDescriptErr = "";
        var userPwdErr = "";
        if (this.state.passwordEditable) {
            if ((user.password === "") || (this.state.userPwd2 === "")) {
                userPwdErr = "You must enter your password twice.";
            }
            else if (user.password !== this.state.userPwd2) {
                userPwdErr = "Please make sure your password entries match.";
            }
            else if (user.password.includes("**")) {
                userPwdErr = "To change the password, enter actual values in the password fields";
            }
            else if (user.password.length < 6) {
                userPwdErr = "Your password must be at least 6 characters";
            }
        }

        if (this.props.editMode === "add" && this.userExists(user.clientId)) {
            userIdErr = "A User with Id " + user.clientId + " already exists!";
        }

        this.setState(
            {
                userIdErr: userIdErr,
                userNameErr: userNameErr,
                userDescriptErr: userDescriptErr,
                userRolesErr: userRolesErr,
                userPwdErr: userPwdErr
            }
        )

        return ((userIdErr === "") && (userNameErr === "") && (userDescriptErr === "")
            && (userRolesErr === "") && (userPwdErr === ""));
    }



    async postUser() {
        this.setState({ isWorking: true });
        // validate
        var gcid = (this.props.userSession) ? this.props.userSession.gcid : "";
        var app = (this.props.userSession) ? this.props.userSession.app : "";
        var clientName = cleanValue(this.state.userName);
        var descript = cleanValue(this.state.userDescript);
        var user = {
            "clientId": (this.props.editMode === "add") ? this.state.userId : this.props.currentUser.clientId, // new
            "gcid": gcid,
            "clientName": clientName,
            "description": descript,
            "password": (this.state.passwordEditable) ? this.state.userPwd : "", // empty the password so api knows we don't want it changed.
            "roles": (Array.isArray(this.state.selectedRoles) ? this.state.selectedRoles.map(d => d.value) : []),
            "app": app
        };
        if (!this.validate(user)) {
            this.setState({ isWorking: false });
            return;
        }

        var postResult = (this.props.editMode === "add") ?
            await this.props.postNew(user)
            : await this.props.postUpdated(user);

        this.setState({ isWorking: false });
    }

    toRoleValues() 
    {
        var filtered = this.props.roles.filter(nd => this.props.currentUser.roles.indexOf(nd.value) >= 0);
        var result = filtered.map(d => {
            return d
        }
        );
        console.log("User Prop Roles:");
        console.info(this.props.currentUser.roles);
        console.log("This props roles available:");
        console.info(this.props.roles);
        console.log("User default roles:");
        console.info(result);
        return result;
    }


    userExists(userId) {
        var lUserId = userId.toLowerCase();
        for (var i = 0; i < this.props.gridData.length; i++) {
            if (this.props.gridData[i].clientId.toLowerCase() === lUserId) {
                return true;
            }
        }
        return false;
    }

    render() {


        const workingButtonIcon = this.state.isWorking ? (
            <FontAwesomeIcon icon={faSyncAlt} color="green" spin />
        ) : (
            "Ok"
        );

        const passwordCheckbox = (this.state.passwordSelectable) ?
            <Form.Check type="checkbox" label="Reset Password" onClick={e => this.onResetPasswordClick(e.target.checked)} /> : <span />;

        const showIcon = (this.state.passwordShowLabel === "show") ? faEye : faEyeSlash;

        const showPasswordButton = (this.state.passwordEditable) ?
            <Button
                variant="outline-secondary"
                onClick={this.togglePasswordShow}>
                <FontAwesomeIcon icon={showIcon} />
            </Button> : <span />;


        return (
            <Modal
                show={this.props.showNewModal}
                onHide={this.props.closeNewModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>User Entry/Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Text className="text-muted">
                                    user id&nbsp;<span style={{ color: "red" }} >{this.state.userIdErr}</span>
                                </Form.Text>
                                <Form.Control id="userId" type="text" placeholder="Id" value={this.state.userId} onChange={(e) => this.onFormValueChange(e)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Text className="text-muted">
                                    name
                                </Form.Text>
                                <Form.Control id="userName" type="text" placeholder="Name" value={this.state.userName} onChange={(e) => this.onFormValueChange(e)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Text className="text-muted">
                                    description
                                </Form.Text>
                                <Form.Control id="userDescript" type="text" placeholder="Description" value={this.state.userDescript} onChange={(e) => this.onFormValueChange(e)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Text className="text-muted">
                                     roles&nbsp;<span style={{ color: "red" }}>{this.state.userRolesErr}</span>
                                </Form.Text>
                                <Select
                                    autoFocus
                                    placeholder={"Select user roles"}
                                    options={this.props.roles}
                                    onChange={opts => this.onRoleValueChange(opts)}
                                    isSearchable={true}
                                    isMulti={true}
                                    value={this.state.selectedRoles}
                                />
                            </Form.Group>
                            
                            {/* 
                            <Form.Group className="mb-3">
                                <Form.Text className="text-muted">
                                    allowed locations&nbsp;<span style={{ color: "red" }}>{this.state.userLocationsError}</span>
                                </Form.Text>
                                <Select
                                    autoFocus
                                    placeholder={"Select allowed locations"}
                                    options={this.props.locationList}
                                    onChange={opts => this.onLocationValueChange(opts)}
                                    isSearchable={true}
                                    isMulti={true}
                                    value={this.state.selectedLocations}
                                />
                            </Form.Group>
                        */}


                            <Form.Group className="mb-3">
                                {passwordCheckbox}
                                <Form.Text className="text-muted">
                                    password&nbsp;<span style={{ color: "red" }} >{this.state.userPwdErr}</span>
                                </Form.Text>
                                <span style={{ display: "flex", flexDirection: "row" }}>
                                    <Form.Control
                                        type={this.state.passwordType} autoComplete="off"
                                        readOnly={!this.state.passwordEditable} id="userPwd"
                                        placeholder="Password"
                                        value={this.state.userPwd}
                                        onChange={(e) => this.onFormValueChange(e)}
                                    />
                                    {showPasswordButton}
                                </span>

                                <Form.Text className="text-muted">
                                    re-enter password&nbsp;
                                </Form.Text>
                                <Form.Control
                                    type={this.state.passwordType}
                                    autoComplete="off" readOnly={!this.state.passwordEditable}
                                    id="userPwd2"
                                    placeholder="Password"
                                    value={this.state.userPwd2}
                                    onChange={(e) => this.onFormValueChange(e)}
                                />
                            </Form.Group>

                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        disabled={this.state.isWorking}
                        onClick={(e) => this.postUser(e)}
                    >
                        {workingButtonIcon}
                    </Button>
                    <Button
                        variant="secondary"
                        disabled={this.state.isWorking}
                        onClick={this.props.closeNewModal}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
