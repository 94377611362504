import { m } from "framer-motion";

export const WorkflowStatus = Object.freeze({

    NONE:   { id: "NONE", name: "Preparing", color: "black", backgroundColor: "lightyellow", transitions: 
      [
        {status: "PENDING", roles: ["lgs.preparer", "lgs.approver"]},
        {status: "APPROVED", roles: ["lgs.approver"]}
      ]
    },
    PREPARING:   { id: "PREPARING", name: "Preparing", color: "black", backgroundColor: "lightyellow", transitions: 
      [
        {status: "PENDING", roles: ["lgs.preparer", "lgs.approver"]},
        {status: "APPROVED", roles: ["lgs.approver"]}
      ]
    },
    PENDING:  { id: "PENDING", name: "Pending Approval", color: "black", backgroundColor: "yellow", transitions: 
      [
        {status: "PREPARING", roles: ["lgs.preparer", "lgs.approver"]},
        {status: "APPROVED", roles: ["lgs.approver"]}
      ]
    },
    APPROVED: { id: "APPROVED", name: "Approved", color: "black", backgroundColor: "lightgreen", transitions: 
      [
        {status: "PENDING", roles: ["lgs.approver"]},
        {status: "PREPARING", roles: ["lgs.approver"]}
      ]
    },
    REPORTSENT: { id: "REPORTSENT", name: "Report Sent", color: "yellow", backgroundColor: "green", transitions:
      [
        {status: "APPROVED", roles: ["lgs.sender"]}
      ]
    }
  });

export function getWorkflowStatusForegroundColor(workflowStatus)
{
    if (!(workflowStatus in WorkflowStatus)) return "red";
    return WorkflowStatus[workflowStatus].color;
}

export function getWorkflowStatusBackgroundColor(workflowStatus)
{
    if (!(workflowStatus in WorkflowStatus)) return "lightyellow";
    return WorkflowStatus[workflowStatus].backgroundColor;
}

