import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import HomeLayout from "../src/layouts/Home/HomeLayout";




const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render
(
  <HashRouter>
    <Switch>
      <Route key="home" path="/home" render={props => <HomeLayout {...props}/>} />
      <Redirect from="/" to="/home" />
    </Switch>
  </HashRouter>,
);

