import React, { Component } from "react";
import { Box, Flex, Spacer, HStack, Card, CardBody, Text, SimpleGrid } from '@chakra-ui/react';
import { DateTime } from "luxon";
import LogHealthCard from "./LogHealthCard.jsx";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartPulse, faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";

import HealthFunctions from "./HealthFunctions.js";

import "../../../../assets/css/healthfolder.css";

export default class HealthFolder extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            overallHealth: "Healthy",
            lastRefreshed: ""
        }
        this.cardHealth = {
            adapter: "Healthy",
            core: "Healthy",
            eb: "Healthy"
        };
        this.toggleFull = this.toggleFull.bind(this)
        this.onCardHealthUpdate = this.onCardHealthUpdate.bind(this);
    }

    onCardHealthUpdate(cardId, health) {
        var newOverallHealth = "Healthy";
        this.cardHealth[cardId] = health;
        Object.keys(this.cardHealth).forEach(key => {
            if (this.cardHealth[key] === "Problem") {
                newOverallHealth = "Problem";
            }
            else if ((this.cardHealth[key] === "Warning") && (newOverallHealth !== "Problem")) {
                newOverallHealth = "Warning";
            }
        })

        this.setState({
            overallHealth: newOverallHealth,
            lastRefreshed: DateTime.now().setZone('America/New_York').toLocaleString(DateTime.DATETIME_SHORT)
        });
    }


    toggleFull() {
        this.setState({
            open: !this.state.open
        })
    }

    render() {
        var hide = "none";
        if (this.state.open) {
            hide = "block"
        } else {
            hide = "none"
        }


        return (


            <div style={{ width: "100%" }}>
                <Box p="4">
                    <Card
                        overflow='hidden'
                        background='white'
                    >
                        <CardBody>
                            <HStack justify="left">
                                <Text className="lastCheckedText" fontSize='xs' style={{ fontStyle: "italic", color: "#898787bd" }}>Last Checked: {<br/>} {this.state.lastRefreshed}</Text>
                                <FontAwesomeIcon icon={faHeartPulse} color={HealthFunctions.getHealthColor(this.state.overallHealth)} size="2x" />
                                <Text>{this.state.overallHealth}</Text>
                                <Spacer style={{ margin: "-10%" }} />
                                <Spacer />
                                <FontAwesomeIcon icon={faArrowCircleDown} color="grey" onClick={() => this.toggleFull()} />
                            </HStack>

                        </CardBody>
                    </Card>
                </Box>

                {/* <Flex wrap='wrap' display={hide}> */}
                <SimpleGrid columns={[1, 2, 3]}>
                    <Box p='3' display={hide}>
                        < LogHealthCard
                            userSession={this.props.userSession}
                            onCardHealthUpdate={this.onCardHealthUpdate}
                            title="On-Prem Adapter"
                            eventType="adapter"
                        />
                    </Box>
                    
                    <Box p='3' display={hide}>
                        < LogHealthCard
                            userSession={this.props.userSession}
                            onCardHealthUpdate={this.onCardHealthUpdate}
                            title="API Core"
                            eventType="core"
                        />
                    </Box>
                  
                    <Box p='3' display={hide}>
                        < LogHealthCard
                            userSession={this.props.userSession}
                            onCardHealthUpdate={this.onCardHealthUpdate}
                            title="LGS DB"
                            eventType="database"
                        />
                    </Box>
                 

                </SimpleGrid>
                {/* </Flex> */}
            </div>
        )

    }
}