import React, { Component } from "react";
import {  Button} from "react-bootstrap";
import {Tooltip, TooltipProvider, TooltipWrapper} from 'react-tooltip';
import { Box, Flex, Spacer, HStack, Card, CardBody, Text, SimpleGrid } from '@chakra-ui/react';

import { AgGridReact } from "ag-grid-react";
// import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from 'react-tooltip';

import CoreService from "../../../services/CoreService";
import { throwStatement } from "@babel/types";

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";

const columnDefs = 
[
    { headerName: "Supplier", field: "supplier", sortable: true, filter: true, resizable: true, minWidth: 300 },
    { headerName: "Default Message", field: "defaultMsg", sortable: true, filter: true, minWidth: 350, cellStyle: {'white-space': 'normal'}, resizable: true, flex: 1, wrapText: true, autoHeight: true },
    { headerName: "Default Recipients", field: "defaultRecipients", sortable: true, filter: true, resizable: true, maxWidth: 220, cellStyle: {'white-space': 'normal'}, flex: 1, wrapText: true, autoHeight: true },
    { headerName: "Group By", field: "rules.inOutGroupBy", sortable: true, filter: true, resizable: true, maxWidth: 120 },
    { headerName: "Show Configuration", field: "rules.showSaleDetail", sortable: true, filter: true, resizable: true, maxWidth: 160 },
    //{ headerName: "Show Containers", field: "rules.showContainers", sortable: true, filter: true, resizable: true, maxWidth: 150 },
    { headerName: "Net Sales Rule", field: "rules.netSales", sortable: true, filter: true, resizable: true, maxWidth: 130 },
    { headerName: "Commission %", field: "rules.commissionPct", sortable: true, filter: true, resizable: true, maxWidth: 140 }
];


export default class SupplierConfigGrid extends Component {
    constructor(props) {
        super(props);
        this.coreService = new CoreService();
        this.state = {
            gridSchema: [],
            schema: null,
            gridData: [],
            isLoading: true,
            selectedRow: null,
            showAddLotDialog: false
        }
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.requestGridDataRefresh = this.requestGridDataRefresh.bind(this);
        this.refreshGridData = this.refreshGridData.bind(this);
    }

    async componentDidMount() {
        await this.refreshGridData();
    }


    toDisplayList(rawList)
    {
        var result = rawList.map(c => 
            {
                return {
                    "supplier": c.configuration.id, 
                    "defaultMsg": c.configuration.msg, 
                    "defaultRecipients": c.configuration.notificationRecipients.join(),
                    "rules": c.configuration.rules
                };
            }
        )
        return result;
    }

    async refreshGridData() {
        this.setState({ isLoading: true });
        var gridDataResponse = await this.coreService.fetchSupplierConfigList(this.props.userSession.token);
        var displayList = this.toDisplayList(gridDataResponse.dataResult);
        var newGridData = displayList;
        console.log("the new grid data");
        console.log(newGridData);
        this.setState({
            gridData: newGridData,
            isLoading: false
        });
    }

    

    onSelectionChanged() {
        var selectedNodes = this.gridApi.getSelectedNodes();
        var rowData = selectedNodes[0].data;
        this.setState({ selectedRow: rowData });
        console.log("RowData: ");
        console.info(rowData);
        this.props.onSelectionChange(rowData.supplierId);
    }

    onEditGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.resetRowHeights();
    };

    async requestGridDataRefresh(e)
    {
        await this.refreshGridData();
        this.props.onToastRequest("information", "Supplier Configuration List Refreshed");
    }





    render() {

        const divStyle = {
            width: "100%",
            height: "40vh"
            
        };

        const gridData = (this.state.gridData) ? this.state.gridData : [{}];
        const loadingIcon = this.state.isLoading ? (
            <FontAwesomeIcon icon={faSyncAlt} color="green" spin />
          ) : (
            ""
          );

        return (
                <span>
                <Tooltip/>
                <Card style={{ width: "93vw" }}
                overflow='hidden'
                background='white'
                >
                    <Text as="h4" style={{ textAlign: "center" }}>Supplier Configuration Listing</Text>
                    <CardBody>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            &nbsp;
                            <p data-tip="Refresh List">
                                <Button variant="primary" onClick={(e)=>this.requestGridDataRefresh(e)}>
                                    <FontAwesomeIcon icon={faRedoAlt} />
                                    &nbsp;&nbsp;Refresh
                                </Button>
                            </p>

                            <span style={{ marginLeft: "48vw" }}>{loadingIcon}</span>
                        </div>

                        <div className="ag-theme-balham" style={divStyle}>
                            <AgGridReact
                                rowSelection="single"
                                enableRangeSelection={false}
                                pagination={true}
                                columnDefs={columnDefs}
                                rowData={gridData}
                                animateRows={true}
                                onGridReady={this.onGridReady}

                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                            />
                        </div>
                    </CardBody>
                </Card>

                </span>
        );
    }
}