import React, { Component } from "react";
import HealthCard from "../../../../components/Healthcard";
import { DateTime } from "luxon";



import CoreService from "../../../../services/CoreService";


export default class LogHealthCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            healthStatus: "Healthy",
            lastCheckedDateTime: "not checked",
            statusText: ""
        }
        this.coreService = new CoreService();
    }


    async componentDidMount()
    {
        var newLastCheckedDateTime = DateTime.now().setZone('America/New_York').toISO();
        var newHealthStatus = "Healthy";
        var newStatusText = "";
        /*
        var logResult = await this.fetchLogEntries();
        if (logResult.dataResult.length < 1)
        {
            newHealthStatus = "Problem";
            newStatusText = "No log entries for last 36 hours"
        }
        else 
        {
            newHealthStatus =  (logResult.dataResult[0].successful) ? "Healthy": "Warning";
            newStatusText = logResult.dataResult[0].messages[0];
        }
        */
        this.setState({
            lastCheckedDateTime: newLastCheckedDateTime,
            healthStatus: newHealthStatus,
            statusText: newStatusText
        });
        this.props.onCardHealthUpdate(this.props.eventType, newHealthStatus);
    }

    async fetchLogEntries()
    {
        return await this.coreService.fetchLog(this.props.eventType, 36, this.props.userSession.token);
    }

    render() {
        return (
            <HealthCard
                cardTitle = {this.props.title}
                healthStatus = {this.state.healthStatus}
                lastChecked = {this.state.lastCheckedDateTime}
                statusText = {this.state.statusText}
            />
        )

    }
}