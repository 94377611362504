import React, { Component } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Image
} from '@chakra-ui/react';


import gcLogo from "../../../assets/images/logos/glasschain-lgs-logo.png";
import changeLog from "../../../changelog";

export default class ModalAbout extends Component 
{
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    render() 
    {

        var browserString = <span>Browser: {this.props.deviceInfo.browser.browserName} Version: {this.props.deviceInfo.browser.fullBrowserVersion}&nbsp;&nbsp;{(this.props.deviceInfo.browser.browserSubTypes.length > 0) ? this.props.deviceInfo.browser.browserSubTypes.join('-') : ""}</span>
        var appVer = <span>Version: {(this.props.appInfo.version.app) ? this.props.appInfo.version.app : ""}</span>
        var appBuild = <span>Build: {(this.props.appInfo.version.build) ? this.props.appInfo.version.build : ""}</span>
        var coreVer = <span>API Version: {(this.props.appInfo.version.core) ? this.props.appInfo.version.core.appVersion : ""}</span>
        var coreLastBuild = <span>API Last Build: {(this.props.appInfo.version.core) ? this.props.appInfo.version.core.lastBuild.toString() : ""}</span>
        var deviceType = "Unknown";
        if ((this.props.deviceInfo.device.isDesktop)) { deviceType = "Desktop Browser" }
        else if (this.props.deviceInfo.device.isTablet) { deviceType = "Tablet Browser" }
        else if (this.props.deviceInfo.device.isMobile) { deviceType = "Mobile Browser" }



        return (


            <Modal
                isOpen={this.props.isOpen} 
                onClose={(e)=>this.props.onClose(e)} 
                isCentered
                scrollBehavior="outside"
                size="md"
            >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader style={{  color: "#898787bd", textAlign: "center" }}>
                       About
                       <Image src={gcLogo} alt="glasschain logo" style={{borderRadius: '4px'}} />
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >

                <div style={{ color: "gray", fontSize: "8pt" }}>
                
                <div center="true">LGS Liquidation Control Center</div>
                <div center="true">Powered by Glassbit</div>
                <div center="true">{appVer}</div>
                <div center="true">{appBuild} </div>
                <div center="true">{coreVer} </div>
                <div center="true">{coreLastBuild} </div>
                <hr/>

                <div center="true">Glasschain Id: {(this.props.userSession.gcid) ? this.props.userSession.gcid : ""}</div>

    
                <div center="true">Device Type: {deviceType}</div>
                <div center="true">{browserString} </div>
              <hr/>
              <div style={{ color: "gray", fontSize: "10pt" }}>Change Log</div>
              <div>
                {changeLog.map((version) => 
                {
                  return <div style={{ color: "gray", fontSize: "8pt" }} key={version.version}>Version {version.version}
                    {version.issues.map(issue =>
                      {
                        return <div style={{ color: "gray", fontSize: "8pt" }} key={issue.id}>{issue.id}-{issue.description}</div>
                      })}
                      <br/>
                  </div>
                })}

              </div>
            </div>

                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>

        )

    }
}