export const SystemErrorLevelType = Object.freeze(
    {
        INFO: {id: "INFO", label: "INFORMATION", labelColor: "black", color: "black", backgroundColor: "lightgreen"},
        WARNING: {id: "WARNING", label: "WARNING", labelColor: "black", color: "black", backgroundColor: "lightyellow"},
        ERROR: {id: "ERROR", label: "ERROR", labelColor: "black", color: "yellow", backgroundColor: "lightred"}
    }
)





