import React, { Component } from "react";
import { Button } from "react-bootstrap";
import RulesCard from "../Components/RulesCard";
import InOutGrid from "../Components/InOutGrid";
import ServicesGrid from "../Components/ServicesGrid";
import SalesGrid from "../Components/SalesGrid";
import LotReturnTable from "../Components/LotReturnTable";
import ContainerGrid from "../Components/ContainerGrid";
import { faFilePdf, faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pdf } from "@react-pdf/renderer";
///////BIG!!! CHANGE THIS BACK AFTER TESTING TO SUMMARYPDF without the 2
import SummaryPdf from "../Components/SummaryPdf2";
import ModalSendMsgDialog from "../Components/ModalSendMsgDialog";
import InOutGridModel from "../Models/InOutGridModel";
import ServicesGridModel from "../Models/ServicesGridModel";
import SalesConfigGridModel from "../Models/SalesConfigGridModel";
import LotReturnModel from "../Models/LotReturnModel";


import CoreService from "../../../services/CoreService";
import BlobStorageService from "../../../services/BlobStorageService";
import NotificationService from "../../../services/NotificationService";


import { AppSecurityKey } from "../../../models/AppSecurityKey";
import LotWorkflowStatus from "../../../models/LotWorkflowStatus";
import { WorkflowStatus } from "../../../models/WorkflowStatusType";
import JavascriptHelpers from "../../../components/lib/Javascript/JavascriptHelpers";

import ModalWorkflowStatusDialog from "./ModalWorkflowStatusDialog";
import RoleManager from "../../../models/RoleManager";

export default class LotLiquidationSumPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adjustments: null,
      inOutGroupBy: "receipts",
      showSaleDetail: "hide",
      showContainers: "show",
      netSales: "postrepack",
      commissionPct: 0.08,
      defaultRecipients: [],
      defaultMsg: "",
      inOutGridData: null,
      servicesGridData: null,
      salesConfigGridData: null,
      lotReturnData: null,
      // OBSOLETE START
      totalBoxes: 0,
      totalPallets: 0,
      totalSales: 0,
      totalServices: 0,
      totalRepackServices: 0,
      totalAdvances: 0,
      serviceLotData: null,
      saleLotData: null,
      //lotReturnData: { growerReturn: 0, advances: 0, netProceeds: 0 },
      // OBSOLETE END

      showSendMsgDialog: false,
      showUpdateStatusDialog: false,
      groupOptions: [],
      showVarieties: true,
      showMethods: true,
      showActiveQuantities: true,
      showActiveSaleAmounts: true
    };

    this.coreService = new CoreService();
    this.notificationService = new NotificationService();

    this.onRefreshLotDataRequest = this.onRefreshLotDataRequest.bind(this);
    this.refreshDisplayData = this.refreshDisplayData.bind(this);
    this.fetchInOutGridData = this.fetchInOutGridData.bind(this);
    this.fetchServiceLotData = this.fetchServiceLotData.bind(this);
    this.fetchSaleLotData = this.fetchSaleLotData.bind(this);
    this.onViewPdfRequest = this.onViewPdfRequest.bind(this);
    this.onSingleRuleChange = this.onSingleRuleChange.bind(this);
    this.onSendPdfDialogRequest = this.onSendPdfDialogRequest.bind(this);
    this.onSendPdfCompleteRequest = this.onSendPdfCompleteRequest.bind(this);
    this.onSendMsgCancelRequest = this.onSendMsgCancelRequest.bind(this);
    this.setSupplierConfigInfo = this.setSupplierConfigInfo.bind(this);

    this.onUpdateStatusCloseRequest = this.onUpdateStatusCloseRequest.bind(this);
    this.onUpdateStatusOpenRequest = this.onUpdateStatusOpenRequest.bind(this);
    this.onUpdateStatusOkRequest = this.onUpdateStatusOkRequest.bind(this);
  }

  async componentDidMount() {
    await this.setSupplierConfigInfo();
    this.refreshDisplayData();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedLotId !== this.props.selectedLotId) {
      await this.setSupplierConfigInfo();
      this.refreshDisplayData();
      return;
    }
    if (prevState.adjustments !== this.state.adjustments) {
      this.refreshDisplayData();
    }
    if (prevState.inOutGroupBy !== this.state.inOutGroupBy) {
      this.refreshDisplayData();
    }
    if (prevState.netSales !== this.state.netSales) {
      this.refreshDisplayData();
    }

  }

  refreshDisplayData() {
    if (!this.props.lotData) return;
    console.group("Refreshing Component Data: ");
    var adj = (this.state.adjustments) ? this.state.adjustments : this.props.lotData.adjustments;
    var inOutData = this.fetchInOutGridData(this.props.lotData, this.state.inOutGroupBy, adj.inOutAdjustments);
    var servicesData = this.fetchServiceLotData(this.props.lotData, adj.chargeAdjustments);
    var salesConfigData = this.fetchSaleLotData(this.props.lotData, adj.inOutAdjustments);
    var lotReturnData = this.fetchLotReturnData(inOutData, servicesData);
    console.groupEnd("done refreshing Component Data");
    this.setState({
      inOutGridData: inOutData,
      servicesGridData: servicesData,
      salesConfigGridData: salesConfigData,
      lotReturnData: lotReturnData
    });
    // In/Out
    this.setState({
      summaryLotData: inOutData.inOutGridLines,
      totalBoxes: inOutData.totalBoxes,
      totalPallets: inOutData.totalPallets,
      totalSales: inOutData.totalSales,
      showVarieties: inOutData.hasVarieties,
      showMethods: inOutData.hasMethods,
      showActiveQuantities: inOutData.hasActiveQuantities,
      showActiveSaleAmounts: inOutData.hasActiveSaleAmounts
    });
    // Services
    this.setState({
      summaryServiceData: servicesData.servicesGridLines,
      totalServices: servicesData.totalServices,
      totalRepackServices: servicesData.totalRepackServices,
      totalAdvances: servicesData.totalAdvances
    });
    // Sales Config
    this.setState({ saleLotData: salesConfigData.salesConfigGridLines });

    //Workflow
    var groupOptionsList = RoleManager.toAvailableWorkflowStatusList(this.props.data.workflowStatus.workflowStatus, this.props.userSession.token);
    var groupOptions = groupOptionsList.map((g) => g.id);
    console.log("group Options:");
    console.info(groupOptions);
    this.setState({
        groupOptions: groupOptions
    });

  }

  // replaces refreshSummaryLotData
  // 2021-09-14cs
  fetchInOutGridData(lotData, groupByRule, inOutAdjustments) {
    var model = InOutGridModel.toInOutGridModel(lotData, groupByRule, inOutAdjustments);
    return model;
  }


  async setSupplierConfigInfo() {
    var hasSupplierConfigData = await this.coreService.hasSupplierConfig(this.props.lotData.lot.supplier, this.props.userSession.token);
    if (!hasSupplierConfigData) {
      var supplierConfig = {
        "id": this.props.lotData.lot.supplier, "name": this.props.lotData.lot.supplier,
        "notificationRecipients": [],
        "msg": "",
        "rules":
        {
          "inOutGroupBy": this.state.inOutGroupBy,
          "showSaleDetail": this.state.showSaleDetail,
          "showContainers": this.state.showContainers,
          "netSales": this.state.netSales,
          "commissionPct": this.state.commissionPct
        }
      };
      var supplierConfigEv = { "config": supplierConfig, "saveRecipients": true, "saveMsg": true }
      await this.coreService.postSupplierConfig(supplierConfigEv, this.props.userSession.token);
    }
    var supplierResponse = await this.coreService.fetchSupplierConfig(this.props.lotData.lot.supplier, this.props.userSession.token);
    // TBD - error-handling
    var supplier = supplierResponse.dataResult;
    this.setState(
      {
        "defaultRecipients": supplier.configuration.notificationRecipients,
        "defaultMsg": supplier.configuration.msg,
        "inOutGroupBy": supplier.configuration.rules.inOutGroupBy,
        "showSaleDetail": (supplier.configuration.rules.inOutGroupBy === "receipts") ? "hide" : supplier.configuration.rules.showSaleDetail,
        "showContainers": supplier.configuration.rules.showContainers,
        "netSales": supplier.configuration.rules.netSales,
        "commissionPct": supplier.configuration.rules.commissionPct
      }
    )
  }

  async onRefreshLotDataRequest() {
    var newLotData = await this.coreService.fetchLotLiquidationSummary(this.props.selectedLotId, this.props.userSession.token);
    var adjustments = newLotData.dataResult.adjustments;
    this.setState({ adjustments: adjustments });
  }

  async onSendPdfDialogRequest() {
    await this.setSupplierConfigInfo();
    this.setState({ showSendMsgDialog: true });
  }

  onSendMsgCancelRequest() {
    this.setState({ showSendMsgDialog: false })
  }

  async onSendPdfCompleteRequest(recipients, msg, includePdfLink, saveRecipients, saveMsg) {
    if (includePdfLink) {
      const blob = await pdf
        (<SummaryPdf
          lot={this.props.lotData.lot}
          summaryLotData={this.state.summaryLotData}
          totalBoxes={this.state.totalBoxes}
          totalPallets={this.state.totalPallets}
          totalSales={this.state.totalSales}
          summaryServiceData={this.state.summaryServiceData}
          totalServices={this.state.totalServices}
          saleLotData={this.state.saleLotData}
          lotReturnData={this.state.lotReturnData}
          showSaleDetail={this.state.showSaleDetail}
          showVarieties={this.state.showVarieties}
          showMethods={this.state.showMethods}
        />
        ).toBlob();
      console.log("building blob...");
      var blobStorageService = new BlobStorageService();
      console.log("uploading blob...");
      var blobStorageServiceResult = await blobStorageService.uploadPdf(blob, this.props.selectedLotId, this.props.userSession.token);
      console.log("uploaded blob...");
    }
    var emailRequest = {
      "gcid": this.props.userSession.gcid, "recipients": recipients, "subject": "Lot " + this.props.selectedLotId + " Liquidation",
      "rawBody": msg, "htmlBody": "",
      "links": (includePdfLink) ? [{ "description": "Lot Liquidation PDF Link", "url": this.props.selectedLotId + ".pdf" }] : []
    };
    var postEmailResponse = await this.notificationService.postEmailRequest(emailRequest, this.props.userSession.token);
    if (postEmailResponse.hasError) {
      this.props.onToastRequest("error", "Problem sending Email. " + postEmailResponse.messages.join(" "));
      return;
    }
    var supplierConfig = {
      "id": this.props.lotData.lot.supplier, "name": this.props.lotData.lot.supplier,
      "notificationRecipients": recipients,
      "msg": msg,
      "rules":
      {
        "inOutGroupBy": this.state.inOutGroupBy,
        "showSaleDetail": this.state.showSaleDetail,
        "showContainers": this.state.showContainers,
        "netSales": this.state.netSales,
        "commissionPct": this.state.commissionPct
      }
    };
    var supplierConfigEv = { "config": supplierConfig, "saveRecipients": saveRecipients, "saveMsg": saveMsg };
    var postSupplierConfigResponse = await this.coreService.postSupplierConfig(supplierConfigEv, this.props.userSession.token);
    if (postSupplierConfigResponse.hasError) {
      this.props.onToastResponse("error", "Error updating supplier information. " + postSupplierConfigResponse.messages.join(" "));
    }
    this.props.onToastRequest("success", "Lot " + this.props.selectedLotId + " PDF Notification successfully sent to recipients");
    // change the lot status automatically
    var newWorkflowStatusObj = new LotWorkflowStatus(this.props.userSession.gcid, this.props.selectedLotId, WorkflowStatus.REPORTSENT.id, JavascriptHelpers.isoNow(), this.props.userSession.uid);
    console.log("New Workflow Status: ");
    console.info(newWorkflowStatusObj);
    this.props.onLotWorkflowStatusChange(newWorkflowStatusObj, this.props.userSession.token);
  }

  async onViewPdfRequest(e) {
    try {
      console.log("building blob...");
      const blob = await pdf
        (<SummaryPdf
          lot={this.props.lotData.lot}
          summaryLotData={this.state.summaryLotData}
          totalBoxes={this.state.totalBoxes}
          totalPallets={this.state.totalPallets}
          totalSales={this.state.totalSales}
          summaryServiceData={this.state.summaryServiceData}
          totalServices={this.state.totalServices}
          saleLotData={this.state.saleLotData}
          lotReturnData={this.state.lotReturnData}
          showSaleDetail={this.state.showSaleDetail}
          showVarieties={this.state.showVarieties}
          showMethods={this.state.showMethods}
        />
        ).toBlob();
      var fileUrl = window.URL.createObjectURL(blob);
      console.log(fileUrl);
      let tab = window.open();
      tab.location.href = fileUrl;
      //console.log("uploading blob...");
      //await this.blobStorageService.uploadPdf(this.gcid, blob, obsToPostId);
    } catch (pdfErr) {
      console.log("Error creating or uploading PDF document");
      console.log(pdfErr);
      //this.props.onToastNotificationRequest('warning', "Error creating or uploading PDF doc for observation " + obsToPostId);
    }
  }

  onSingleRuleChange(changeProp, changeValue) {
    if (changeProp === "panelView") {
      this.setState({ panelView: changeValue });

    }
    else if (changeProp === "inOutGroupBy") {
      if (changeValue !== "receipts") {
        this.setState({ showSaleDetail: "show" });
      }
      this.setState({ inOutGroupBy: changeValue });
    }
    else if (changeProp === "showSaleDetail") {
      this.setState({ showSaleDetail: changeValue })
    }
    else if (changeProp === "showContainers") {
      this.setState({ showContainers: changeValue })
    }
    else if (changeProp === "netSales") {
      this.setState({ netSales: changeValue });
    }
  }


  fetchServiceLotData(lotData, chargeAdjustments) {
    var model = ServicesGridModel.toServicesGridModel(lotData, chargeAdjustments);
    return model;
  }

  fetchSaleLotData(lotData, adjustments) {
    if (!lotData.sales) return [];
    // we may never need to use this data, but let's go ahead and calc it anyway.
    var model = SalesConfigGridModel.toSalesConfigGridModel(lotData, adjustments);
    return model;
  }

  fetchLotReturnData(inOutData, servicesData) {
    var model = LotReturnModel.toLotReturnModel(inOutData, servicesData, this.state.netSales, this.state.commissionPct);
    var newLotReturnData = model.toLotReturnData();
    return newLotReturnData;

  }

  onUpdateStatusOpenRequest(e)
  {
      if (e)
      {
          e.preventDefault();
          e.stopPropagation();
      }
      if (this.state.groupOptions.length < 1) 
      {
        alert("You have no workflow status options to change to.")
        return;
      }
      this.setState({showUpdateStatusDialog: true});
  }

  onUpdateStatusCloseRequest(e)
  {
    if (e)
    {
        e.preventDefault();
        e.stopPropagation();
    }
    this.setState({showUpdateStatusDialog: false});
  }

  async onUpdateStatusOkRequest(existingWorkflowStatus, newWorkflowStatus)
  {
      var newWorkflowStatusObj = new LotWorkflowStatus(this.props.userSession.gcid, this.props.data.lotId, newWorkflowStatus, JavascriptHelpers.isoNow(), this.props.userSession.uid);
      console.log("New Workflow Status: ");
      console.info(newWorkflowStatusObj);
      this.props.onLotWorkflowStatusChange(newWorkflowStatusObj, this.props.userSession.token);
      this.onUpdateStatusCloseRequest();
      await this.refreshData();
  }




  render() {
    console.log("Show Sales? " + this.state.showSaleDetail);
    var salePanel =
      ((this.state.showSaleDetail === "show") && (this.state.inOutGroupBy !== "receipts")) ?
        (
          <SalesGrid data={this.state.saleLotData} />
        ) : (
          <span />
        );
    var containerPanel =
      this.state.showContainers === "show" ? (
        <ContainerGrid data={this.props.lotData.lot.containers} />
      ) : (
        <span style={{ width: "28vw", margin: "1vw" }} />
      );

    return (
      <div style={{ marginLeft: "2%", marginRight: "2%" }}>

        <Tooltip />

        <ModalSendMsgDialog
          show={this.state.showSendMsgDialog}
          onSendPdfCompleteRequest={this.onSendPdfCompleteRequest}
          onCloseRequest={this.onSendMsgCancelRequest}
          defaultRecipients={this.state.defaultRecipients}
          defaultMsg={this.state.defaultMsg}
        />

        <ModalWorkflowStatusDialog
          show={this.state.showUpdateStatusDialog}
          onModalCloseRequest={this.onUpdateStatusCloseRequest}
          onModalOkRequest={this.onUpdateStatusOkRequest}
          existingWorkflowStatus={this.props.data.workflowStatus.workflowStatus}
          groupOptions={(this.state.groupOptions) ? this.state.groupOptions : []}
        />

        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
          <p style={{ marginBottom: "5px" }} data-tip="Update Status of selected lot">
            <Button size="sm" variant="primary" onClick={(e) => this.onUpdateStatusOpenRequest(e)}>
              <FontAwesomeIcon icon={faFilePdf} />
              &nbsp;&nbsp;Update Status
            </Button>
          </p>
          &nbsp;
          <p style={{ marginBottom: "5px" }} data-tip="View PDF Preview">
            <Button size="sm" variant="primary" onClick={(e) => this.onViewPdfRequest(e)}>
              <FontAwesomeIcon icon={faFilePdf} />
              &nbsp;&nbsp;View PDF
            </Button>
          </p>
          &nbsp;
          <p style={{ marginBottom: "5px", marginRight: "5px" }} data-tip="Send PDF">
            <Button size="sm" variant="primary"  
              disabled={!RoleManager.hasRoleStatusRights(AppSecurityKey.SEND_REPORT, this.props.data.workflowStatus.workflowStatus, this.props.userSession.token)} 
              onClick={(e) => this.onSendPdfDialogRequest(e)}>
              <FontAwesomeIcon icon={faMailBulk} />
              &nbsp;&nbsp;Send PDF
            </Button>
          </p>
          <RulesCard
            onSingleRuleChange={this.onSingleRuleChange}
            inOutGroupBy={this.state.inOutGroupBy}
            showSaleDetail={this.state.showSaleDetail}
            showContainers={true}
            netSales={this.state.netSales}
            commissionPct={this.state.commissionPct}
          />
        </div>
        <div
          style={{
            display: "flex",
            //justifyContent: "space-evenly",
            height: "min-content",
          }}
        >
          <InOutGrid
            userSession={this.props.userSession}
            lot={this.props.lotData.lot}
            data={this.state.summaryLotData}
            totalBoxes={this.state.totalBoxes}
            totalPallets={this.state.totalPallets}
            totalSales={this.state.totalSales}
            showVarieties={this.state.showVarieties}
            showMethods={this.state.showMethods}
            showActiveQuantities={this.state.showActiveQuantities}
            showActiveSaleAmounts={this.state.showActiveSaleAmounts}
            onRefreshLotDataRequest={this.onRefreshLotDataRequest}
            onToastRequest={this.props.onToastRequest}
          />
        </div>
        <div
          style={{
            width: "90vw",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            height: "min-content",
            marginRight: "25px",
            marginLeft: "0",
            marginTop: "0px",
          }}
        >
          {containerPanel}

          {salePanel}

          <ServicesGrid
            userSession={this.props.userSession}
            lot={this.props.lotData.lot}
            data={this.state.summaryServiceData}
            totalServices={this.state.totalServices}
            onRefreshLotDataRequest={this.onRefreshLotDataRequest}
            onToastRequest={this.props.onToastRequest}
          />

          <LotReturnTable
            userSession={this.props.userSession}
            lot={this.props.lotData.lot}
            data={this.state.lotReturnData}
            onRefreshLotDataRequest={this.onRefreshLotDataRequest}
          />
        </div>
      </div>
    );
  }
}
