import React, { Component } from "react";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {toUsDollars} from "../../../components/lib/Javascript/jsUtils";

export default class ChargeAdjustCard extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
          adjExtAmount: null
        }

    }

    onAdjustedChargeAmountChange = (e) => {
      var adjChargeAmountDecimal = +(e.target.value);
      this.setState({
        adjExtAmount: adjChargeAmountDecimal
      });
      var result = {"id": "0", "lotId": this.props.dataRow.lotId, "adjustmentKey": this.props.dataRow.charge.serviceGroup, 
        "extAmount": this.props.dataRow.extAmount, "adjustedExtAmount": adjChargeAmountDecimal};
      console.log("Result: ");
      console.info(result);//if (!Number.isInteger(e.target.value)) return;
      this.props.onChargeAdjustment(result);
      
    };




  render() {

    var x = this.props.dataRow;
    console.info(x);

    return (
      <span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            backgroundColor: "lightgrey",
            color: "white",
            marginLeft: "1vw",
            marginRight: "1vw",
            borderRadius: "1px",
          }}
        >
          <div style={{ display: "block" }}>
            <div>Id</div>
            <div style={{ color: "grey", fontSize: "12px" }}>
              {this.props.dataRow.charge.serviceId}
            </div>
          </div>
          <div style={{ display: "block" }}>
            <div>Service Group</div>
            <div style={{ color: "grey", fontSize: "12px" }}>
              {this.props.dataRow.charge.serviceGroup}
            </div>
          </div>
          <div style={{ display: "block" }}>
            <div>Description</div>
            <div style={{ color: "grey", fontSize: "12px" }}>
              {this.props.dataRow.charge.description}
            </div>
          </div>
        </div>

        <div>
           <br/>
          <div>Original Values</div>
          <hr/>
          <Form style={{ display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
            <Form.Group>
              <Form.Label style={{color: "grey"}}>Orig Extended Amt$</Form.Label>
              <Form.Control
                id="chargeAmount"
                type="text"
                placeholder="Sale Ext Amt"
                readOnly
                disabled
                value={toUsDollars(this.props.dataRow.extAmount)}
              />
            </Form.Group>
          </Form>


          <div>Adjusted Values</div>
          <hr/>
          <Form style={{ display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
            <Form.Group>
              <Form.Label style={{color: "grey"}}>Adj Extended Amt$</Form.Label>
              <Form.Control
              id="adjustedChargeAmount"
                type="number"
                placeholder={toUsDollars(this.props.dataRow.activeExtAmount)}
                onChange={(e) => this.onAdjustedChargeAmountChange(e)}
              />
            </Form.Group>
          </Form>
        </div>
      </span>
    );
  }
}
