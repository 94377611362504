import IdentityService from "../services/IdentityService";


function queryStringToJSON(queryString) 
{            
    var pairs = queryString.slice(1).split('&');
    
    var result = {};
    pairs.forEach(function(pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
}


export default class UserSessionKey
{
    constructor(locationId, refreshKey)
    {
        this.locationId = locationId || "";
        this.queryString = refreshKey || IdentityService.makeUuid();
        this.refreshKey = IdentityService.makeUuid();

        this.toQueryString = this.toQueryString.bind(this);
        this.addToQueryString = this.addToQueryString.bind(this);
    }

    addToQueryString(p, name){
        if (!p) return; // if null, then ignore
        if (p==="") return;
        console.log("p=" + p);
        if (p.toUpperCase()==="ALL") return;
        if (p == null) return;
        if (p!==""){
            let qPiece = name + "=" + p;
            if (this.queryString !==""){
                this.queryString = this.queryString + "&" + qPiece;
            }
            else {
                this.queryString = qPiece;
            }
        }
    }

    toQueryString(){
        // there are definitely better ways!
        this.addToQueryString(this.locationId, "locationid");
        this.addToQueryString(this.refreshKey, "refreshKey")
        return this.queryString;
    }



    static fromUserSession(userSession)
    {
        var sessionKey = new UserSessionKey();
        sessionKey.locationId = userSession.currentLocationId;
        return sessionKey;
    }

    static fromQueryString(queryString)
    {
        var obj = queryStringToJSON(queryString);
        var result = new UserSessionKey(obj["locationId"], obj["refreshKey"]);
        return result;
    }

    updateRefreshKey()
    {
        this.refreshKey = IdentityService.makeUuid();
    }


    validate(){
        return true;
    }
}