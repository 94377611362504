var appsettings = [
    
        /* PRODUCTION */
        {key: "base.user.scope", value: ["lgs.app.user", "auth.role.reader"]}, 
        {key: "glasschain.auth.host.addr", value: "https://gc-auth-svc-east.azurewebsites.net"},
        {key: "glasschain.auth.resource.host.addr", value: "https://gc-auth-mgmt-svc-east.azurewebsites.net"},
        {key: "glasschain.core.host.addr", value: "https://gc-lgs-core-svc.azurewebsites.net"},
        {key: "glasschain.notification.host.addr", value: "https://gc-notifications-svc-east.azurewebsites.net"},
        {key: "glasschain.api.usebearertoken", value: true},
    
    /* DEV */
    /*
    {key: "base.user.scope", value: ["lgs.app.user", "auth.role.reader"]}, 
    {key: "glasschain.auth.host.addr", value: "http://localhost:10001"},
    {key: "glasschain.auth.resource.host.addr", value: "http://localhost:10005"},
    {key: "glasschain.core.host.addr", value: "http://localhost:10002"},
    {key: "glasschain.notification.host.addr", value: "http://localhost:13001"},
    {key: "glasschain.api.usebearertoken", value: true},
    */


    /* SHARED */
    {key: "userguide.file.addr", value: "https://gcblobstoredurable1.blob.core.windows.net/userguidesdurable/"},
    {key: "userguide.file.name", value: "glasschain-lgs-liquidation-userguide.pdf"},
    {key: "lgs.email.from.address", value: "lgs-account-of-sale@glassbit.com"},
    {key: "glasschain.image.store.addr", value: "https://gcblobstoredurable1.blob.core.windows.net/"},
    {key: "glasschain.report.host.addr", value: "https://gcblobstoredurable1.blob.core.windows.net/gcid"},
    {key: "lot.watch.interval.milliseconds", value: 30000},
    {key: "system.roles", value: ["lgs.adapter.client"]},
    {key: "system.users", value: ["lgs.super.admin", "lgs.identityserverapi.admin","lgs.adapter.client"]},
    {key: "version", value: "05.01.28"}

]


module.exports = {
    appsettings
}