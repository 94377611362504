import React, { Component } from "react";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Sidebar from "./Components/Sidebar.jsx";
import Navbar from "./Components/Navbar.jsx";
import MainPage from "../../pages/Main/MainPage.jsx";
import AuthPage from "../../pages/Auth/AuthPage.jsx";
import ErrorPage from "../../pages/Error/ErrorPage.jsx";
import ModalAbout from "./Components/ModalAbout.jsx";

import "../../assets/css/admin.css";

import ConfigService from "../../services/ConfigService.js";
import LocalStorageService from "../../services/LocalStorageService.js";
import DeviceInfo from "../../components/lib/device/DeviceInfo.jsx";
import UserSessionKey from "../../models/UserSessionKey.js";
import JwtToken from "../../components/lib/tokens/JwtToken.js";
import LiquidationPage from "../../pages/Liquidation/LiquidationPage.jsx";
import DataManagementPage from "../../pages/DataManagement/DataManagementPage.jsx";
import UserManagementPage from "../../pages/UserManagement/UserManagementPage.jsx";

import RoleManager from "../../models/RoleManager.js";
import { AppSecurityKey } from "../../models/AppSecurityKey.js";
import { SystemErrorLevelType } from "../../models/SystemErrorLevelType.js";
import LotWatcher from "./Components/LotWatcher.jsx";

function isOnline() {
  return navigator.onLine;
}

export default class HomeLayout extends Component 
{
  constructor(props) {
    super(props);

    this.deviceInfo = new DeviceInfo().getDeviceInfo();
    this.localStorageService = new LocalStorageService();
    this.configService = new ConfigService();

    let lastUserSession = this.localStorageService.hasLastUserSession()
      ? this.localStorageService.getLastUserSession()
      : {
          isValid: false,
          gcid: "",
          uid: "",
          name: "",
          title: "",
          token: "",
          app: "",
          roles: [],
          offlineTokenCanExpire: true,
          allowedScopes: [],
          isAirplaneMode: false,
          currentLocationId: null,
          locationInfo: {},
          selectedLotId: null,
          runLotWatcher: false,
          lotWatcherRunning: false,
          hasUpdatedLots: false
        };

    this.state = 
    {
      showFrames: false,
      systemErrorMsg: "",
      systemErrorLevel: SystemErrorLevelType.INFO,
      isLoggedIn: false,
      appInfo: {
        version: {
          build: ""
        },
      },
      onlineInfo: {
        isOnline: false, // user has network access
        isAirplaneMode: false, // user WANTS to be disconnected, even if isOnline
        isConnected: false,
      }, // are we accessing network resources (because we are online AND NOT in Airplane mode)
      userSession: lastUserSession,
      userSessionKey: "",
      isAboutOpen: false,
      sessionExpiration: null
    };

    this.setFramesVisibility = this.setFramesVisibility.bind(this);

    this.onSuccessfulLogin = this.onSuccessfulLogin.bind(this);
    this.onLogoutRequest = this.onLogoutRequest.bind(this);
    this.onToastRequest = this.onToastRequest.bind(this);

    this.onAboutOpen = this.onAboutOpen.bind(this);
    this.onAboutClose = this.onAboutClose.bind(this);
 

    this.onLotSelectionChange = this.onLotSelectionChange.bind(this);
    this.onHomePageRequest = this.onHomePageRequest.bind(this);
    this.onLiquidationPageRequest = this.onLiquidationPageRequest.bind(this);
    this.onDataManagementPageRequest = this.onDataManagementPageRequest.bind(this);
    this.onUserManagementPageRequest = this.onUserManagementPageRequest.bind(this);
    this.onExpiredToken = this.onExpiredToken.bind(this);
    this.updateSessionExpiration = this.updateSessionExpiration.bind(this);
    this.onUpdatedLotsEvent = this.onUpdatedLotsEvent.bind(this);
    this.onLotWatcherRunningStatusChange = this.onLotWatcherRunningStatusChange.bind(this);


  }

  async componentDidMount() {
    // if I am connected, then I MUST log in.
    // if I am NOT connected, then I MUST have userSessionInfo stored.
    // AND if offlineTokenCanExpire is TRUE, then the token cannot have expired.
    let onlineInfo = isOnline()
      ? {
          isOnline: true, // user has network access
          isAirplaneMode: false, // user WANTS to be disconnected, even if isOnline
          isConnected: true
        }
      : {
          isOnline: false, // user has network access
          isAirplaneMode: false, // user WANTS to be disconnected, even if isOnline
          isConnected: false,
        };
    this.setState({ onlineInfo: onlineInfo });

  
    // do we even HAVE a last userSession?
    if (!this.localStorageService.hasLastUserSession()) 
    {
      
      console.log("No previous user session found.");
      this.setState({
        SystemErrorLevel: SystemErrorLevelType.INFO,
        systemErrorMsg: "Please log in.",
      });
      this.setFramesVisibility(false);
      this.props.history.replace("/home/error-page");
      return;
    } 

    var userSession = this.localStorageService.getLastUserSession();
    var userSessionKey =
        UserSessionKey.fromUserSession(userSession).toQueryString();
    if (!JwtToken.isValidToken(userSession.token)) 
    {
        this.setFramesVisibility(false);
        this.setState({
          systemErrorLevel: SystemErrorLevelType.WARNING,
          systemErrorMsg: "Last Saved user Session has an invalid Jwt Token!"
        });
        this.props.history.replace("/home/error-page");
        return;
    }
    //if (this.state.userSession.offlineTokenCanExpire) {
    if (JwtToken.isTokenExpired(userSession.token)) 
    {
        this.setState({
          systemErrorMsg:
            "Your session has expired. Please log in again.",
        });
        this.setFramesVisibility(false);
        this.props.history.replace("/home/error-page");
        return;
    }
      

    let appInfo = this.localStorageService.hasAppInfo(userSession.gcid,userSession.uid)
        ? this.localStorageService.getAppInfo(userSession.gcid, userSession.uid)
        : {
            version: {
              build: "",
              app: "",
              core: ""
            },
      };
      // if we got here then the user is OFFLINE AND is allowed to continue offline as the last user session
      this.setState({
        isLoggedIn: true,
        userSession: userSession,
        userSessionKey: userSessionKey,
        appInfo: appInfo,
        runLotWatcher: true
      });
    
    this.updateSessionExpiration(JwtToken.toExpiredDateTime(this.state.userSession.token));
    this.addConnectionListeners();
    this.setFramesVisibility(true);
    this.props.history.replace("/home/home-page");
  }

  componentWillUnmount() {
    this.removeConnectionListeners();
  }

  setFramesVisibility(showFrames)
  {
    this.setState({showFrames: showFrames});
  }

  onToastRequest(notifyType, msg, toastClosePause) 
  {
    var newToastClosePause = (toastClosePause) ? toastClosePause: 5000;
    this.setState({toastClosePause: newToastClosePause});
    
    if (notifyType === 'error') {
      toast.error(msg);
    }
    else if (notifyType === 'warning') {
      toast.warning(msg);
    }
    else if (notifyType === 'success') {
      toast.success(msg);
    }
    else {
      toast.info(msg);
    }
  }


  updateSessionExpiration(newExpirationDateTime)
  {
    this.setState({sessionExpiration: newExpirationDateTime});
  }

  onLotWatcherRunningStatusChange(isRunning)
  {
    this.setState({lotWatcherRunning: isRunning});
  }

  onUpdatedLotsEvent(hasUpdatedLots)
  {
    this.setState({hasUpdatedLots: hasUpdatedLots});
  }

  onExpiredToken(token)
  {
    this.setFramesVisibility(false);
    this.localStorageService.removeLastUserSession();
    this.setState({
      userSession: {},
      systemErrorLevel: SystemErrorLevelType.ERROR,
      systemErrorMsg: "Your session has expired. Please log in again.",
      sessionExpiration: null

    });
    this.props.history.replace("/home/error-page");
  }

  onHomePageRequest() 
  {
    this.setFramesVisibility(true);
    this.props.history.replace("/home/home-page");
  }

  onErrorPageRequest(msgs) {
    if (!msgs) {
      msgs = [""];
    }
    this.setFramesVisibility(false);
    this.setState({ framesystemErrorMsg: msgs.join(" ") });
    this.props.history.replace("/home/error-page");
  }

  onLiquidationPageRequest()
  {
    if (!RoleManager.hasRoleRights(AppSecurityKey.VIEW_LIQUIDATION, this.state.userSession.token))
    {
        alert("You do not have rights to view Lot Liquidation.");
        return;
    }
    if (!this.state.selectedLotId) 
    {
      alert("Please select a Lot!")
      return;
    }

    this.props.history.replace("/home/liquidation-page");
  }

  onDataManagementPageRequest()
  {
    if (!RoleManager.hasRoleRights(AppSecurityKey.EDIT_SERVICEMAPS, this.state.userSession.token))
    {
        alert(AppSecurityKey.EDIT_SERVICEMAPS.errorMessage);
        return;
    }
    this.props.history.replace("/home/datamanagement-page");
  }

  onUserManagementPageRequest()
  {
    if (!RoleManager.hasRoleRights(AppSecurityKey.EDIT_USERS, this.state.userSession.token))
    {
        alert(AppSecurityKey.EDIT_USERS.errorMessage);
        return;
    }
    this.props.history.replace("/home/usermanagement-page");
  }


  async onSuccessfulLogin(loginInfo) {
    var userSession = {
      isValid: true,
      gcid: loginInfo.gcid,
      uid: loginInfo.uid,
      token: loginInfo.token,
      allowedLocations: loginInfo.allowedLocations,
      currentLocationId: "",
      locationInfo: loginInfo.currentWarehouse,
      app: loginInfo.app, 
      roles: loginInfo.roles
    };
    var appInfo = {
      version: {
        build: loginInfo.buildVer,
        app: loginInfo.appVer,
        core: loginInfo.coreVer
      },
    };
    var newUserSessionKey =
      UserSessionKey.fromUserSession(userSession).toQueryString();
    console.group(
      "In HomeLayout - onSuccessfulLogin after creating a userSession"
    );
    console.info(userSession);
    console.groupEnd();
    this.localStorageService.saveLastUserSession(userSession);
    this.setState({
      isLoggedIn: true,
      userSession: userSession,
      appInfo: appInfo,
      userSessionKey: newUserSessionKey, // needed to trigger header panel setting proper changed location
      runLotWatcher: true
    });
    this.localStorageService.saveAppInfo(
      loginInfo.gcid,
      loginInfo.uid,
      appInfo
    );
    this.addConnectionListeners();
    this.setFramesVisibility(true);
    this.props.history.replace("/home/home-page");
  }

  onLogoutRequest() {
    console.log("Logging out...");
    // clean up
    this.removeConnectionListeners();
    this.localStorageService.removeLastUserSession();
    this.setState({
      userSession: {},
      sessionExpiration: null,
      runLotWatcher: false
    });
    this.setFramesVisibility(false);
    this.props.history.replace("/home/auth-page");
  }

  addConnectionListeners() {
    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
  }

  removeConnectionListeners() {
    window.removeEventListener("online", this.handleConnectionChange);
    window.removeEventListener("offline", this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    let onlineInfo = {};
    const condition = navigator.onLine ? "online" : "offline";
    onlineInfo.isOnline = condition === "online";
    onlineInfo.isConnected =
      onlineInfo.isAirplaneMode !== true && onlineInfo.isOnline;
    console.group("OnlineInfo Change:");
    console.info(onlineInfo);
    console.groupEnd();
    this.setState({ onlineInfo: onlineInfo });
    return onlineInfo;
  };

  onAboutOpen() {
    this.setState({ isAboutOpen: true });
  }

  onAboutClose() {
    this.setState({ isAboutOpen: false });
  }

  onLotSelectionChange(lotId) 
  {
    this.setState({ selectedLotId: lotId });
  }

  onUserGuideRequest()
  {
    var userGuideFilePath = new ConfigService().getUserGuideFilePath();
    window.open(userGuideFilePath,"_blank");
  }

  render() {
    return (
      <ChakraProvider>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ModalAbout
          isOpen={this.state.isAboutOpen}
          onClose={this.onAboutClose}
          deviceInfo={this.deviceInfo}
          appInfo={this.state.appInfo}
          userSession={this.state.userSession}
        />

        <Navbar
          isVisible={this.state.showFrames}
          userSession={this.state.userSession}
          onHomePageRequest={this.onHomePageRequest}
          onAboutOpen={this.onAboutOpen}
          onLogoutRequest={this.onLogoutRequest}
          onUserGuideRequest={this.onUserGuideRequest}
          onLiquidationPageRequest={this.onLiquidationPageRequest}
          onDataManagementPageRequest={this.onDataManagementPageRequest}
          onUserManagementPageRequest={this.onUserManagementPageRequest}
          currentLotId={this.state.selectedLotId}
          sessionExpiration={this.state.sessionExpiration}
          alertSecondsLeft={120}
          lotWatcherRunning={this.state.lotWatcherRunning}
        />

        <Sidebar 
          isVisible={this.state.showFrames}
          userSession={this.state.userSession}
          onHomePageRequest={this.onHomePageRequest}
          onAboutOpen={this.onAboutOpen} 
          onLogoutRequest={this.onLogoutRequest}
          onUserGuideRequest={this.onUserGuideRequest}
          onLiquidationPageRequest={this.onLiquidationPageRequest}
          onDataManagementPageRequest={this.onDataManagementPageRequest}
          onUserManagementPageRequest={this.onUserManagementPageRequest}
        />

        <LotWatcher
          userSession={this.state.userSession}
          runLotWatcher={this.state.runLotWatcher}
          updateSessionExpiration={this.updateSessionExpiration}
          onExpiredToken={this.onExpiredToken}
          onToastRequest={this.onToastRequest}
          onUpdatedLotsEvent={this.onUpdatedLotsEvent}
          onLotWatcherRunningStatusChange={this.onLotWatcherRunningStatusChange}
        />

        <Box className={(this.state.showFrames) ? "adminview": "adminview-hidden"}>

          <Switch>
            <Route
              path="/home/home-page"
              render={(props) => (
                <MainPage
                  {...props}
                  deviceInfo={this.deviceInfo}
                  appInfo={this.state.appInfo}
                  onlineInfo={this.state.onlineInfo}
                  userSession={this.state.userSession}
                  userSessionKey={this.state.userSessionKey} // used to trigger changes
                  onErrorPageRequest={this.onErrorPageRequest}
                  onHomePageRequest={this.onHomePageRequest}
                  onLogoutRequest={this.onLogoutRequest}
                  onToggleAirplaneMode={this.onToggleAirplaneMode}
                  onRefreshUserSessionKey={this.onRefreshUserSessionKey}
                  onLotSelectionChange={this.onLotSelectionChange}
                  onLiquidationPageRequest={this.onLiquidationPageRequest}
                  onToastRequest={this.onToastRequest}
                  onUpdatedLotsEvent={this.onUpdatedLotsEvent}
                  hasUpdatedLots={this.state.hasUpdatedLots}
                />
              )}
            />
            <Route
              path="/home/auth-page"
              render={(props) => (
                <AuthPage
                  {...props}
                  deviceInfo={this.deviceInfo}
                  onSuccessfulLogin={this.onSuccessfulLogin}
                />
              )}
            />
            <Route
              path="/home/error-page"
              render={(props) => (
                <ErrorPage
                  {...props}
                  onLogoutRequest={this.onLogoutRequest}
                  systemErrorLevel={this.state.systemErrorLevel}
                  systemErrorMsg={this.state.systemErrorMsg}
                />
              )}
            />
            <Route
              path="/home/liquidation-page"
              render={(props) => (
                <LiquidationPage {...props}
                  onLoginExpire={this.onRequestLogout}
                  selectedLotId={this.state.selectedLotId}
                  onLotAdjustPageRequest={this.onRouteLotAdjustPageRequest}
                  onToastRequest={this.onToastRequest}
                  userSession={this.state.userSession}
                  onExpiredToken={this.onExpiredToken}
                />
              )}
            />
            <Route
              path="/home/datamanagement-page"
              render={(props) => (
                <DataManagementPage {...props}
                  onLoginExpire={this.onRequestLogout}
                  onToastRequest={this.onToastRequest}
                  userSession={this.state.userSession}
                  onExpiredToken={this.onExpiredToken}
                />
              )}
            />
            <Route
              path="/home/usermanagement-page"
              render={(props) => (
                <UserManagementPage {...props}
                  onLoginExpire={this.onRequestLogout}
                  onToastRequest={this.onToastRequest}
                  userSession={this.state.userSession}
                  onExpiredToken={this.onExpiredToken}
                />
              )}
            />


            <Redirect from="/" to="/home/home-page" />
            <Redirect from="/home" to="/home/home-page" />
          </Switch>

        </Box>
      </ChakraProvider>
    );
  }
}
