
  export function toUsDollars(flt){
    var val = (flt) ? flt: 0;
    var sign = '';
    var sansDec = val.toFixed(2);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return sign + `${formatted}`;
  }

  export function toUsComma(flt){
    var val = (flt) ? flt: 0;
    var sansDec = val.toFixed(0);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${formatted}`;
  }

  //https://stackoverflow.com/questions/15329503/conversion-of-a-hash-function-from-c-sharp-to-javascript-has-issues-with-bitwise
  export function getStableHash(s, hashlength) {
    var hash = 0;
    var bytes = Uint8Array.from(s);
    //var bytes = stringToBytes(s);   // this function just grabs a byte array for the given input string
    for (var i = 0; i < bytes.length; i++) {
      hash += bytes[i];
      hash += hash << 10;
      hash ^= hash >> 6;
    }
    // final avalanche
    hash += hash << 3;
    hash ^= hash >> 11;
    hash += hash << 15;

    //return Math.round(hash % hashlength);
    return (hash >>> 0) % hashlength;
  }




