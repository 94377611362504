import React, { Component } from "react";
import { Button, Modal, Row,Col,InputGroup, Form} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CreatableSelect from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import Dictaphone from "../../../components/lib/Dictaphone/GcDictaphone";




const createOption = (label) => ({
  label,
  value: label,
});


export default class ModalSendMsgDialog extends Component 
{
  constructor(props) {
    super(props);




    this.state = {
        dataLoaded: false,
        listening: false,
        sending: false,
        sendToInputValue: "",
        sendToValue: [],
        msg: "",
        includePdfLink: true,
        saveRecipients: true,
        saveMsg: true
        //(this.gcid, this.userName)  // {id: xxxxx, email: xxx@xxx.xxx, phone: +1xxx-xxx-xxxx}
    };

    this.onMsgChange = this.onMsgChange.bind(this);
    this.onTranscriptionChange = this.onTranscriptionChange.bind(this);
    this.onIncludePdfLinkChange = this.onIncludePdfLinkChange.bind(this);
    this.onSaveRecipientsChange = this.onSaveRecipientsChange.bind(this);
    this.onSaveMsgChange = this.onSaveMsgChange.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.setDefaultRecipients = this.setDefaultRecipients.bind(this);

  }

  componentDidMount(){
  
    console.log("Send Messgageadsgadsf mounted")
    var sendToValues = this.setDefaultRecipients();
    this.setState({
      sendToValue: sendToValues,
      defaultMsg: this.props.defaultMsg
    });
    console.info(this.state.sendToValue)
  }

componentDidUpdate(prevProps, prevState){
  
  if ((prevProps.defaultRecipients !== this.props.defaultRecipients) ||
    (prevProps.defaultMsg !== this.props.defaultMsg))
  {
    var sendToValues = this.setDefaultRecipients();
    this.setState({
      msg: this.props.defaultMsg,
      sendToValue: sendToValues,
      dataLoaded: true
    });
    console.log("SENDMSGCOMPONENTDIDUPDATE")
  }
  
}

  setDefaultRecipients()
  {
    var sendToValues = this.props.defaultRecipients.map(r =>{
      return {"label": r, "value": r};
    });
    return sendToValues;
  }

  onMsgChange(ev){
    this.setState({msg: ev.target.value});
  }

  onTranscriptionChange(newTranscription){
    var newValue = (this.state.msg) ? this.state.msg + " " + newTranscription : newTranscription;
    this.setState({msg: newValue});
  }

  onAddObsCommentRequest(){
    var comment = (this.props.currentRow.data.Environment.Comments) ? this.props.currentRow.data.Environment.Comments : "";
    var newValue = (this.state.msg) ? this.state.msg + " " + comment : comment;
    this.setState({msg: newValue});
  }

  onIncludePdfLinkChange(){
    this.setState({includePdfLink: (!this.state.includePdfLink)});
  }

  onSaveRecipientsChange(){
    this.setState({saveRecipients: (!this.state.saveRecipients)});
  }

  onSaveMsgChange(){
    this.setState({saveMsg: (!this.state.saveMsg)});
  }

  addressBookIdExists(addressBook, testId){
    if (!addressBook.addresses) return false;
    for (let i=0; i<addressBook.addresses.length; i++){
      let addrBookId = addressBook.addresses[i].id;
      if (addrBookId.toLowerCase() === testId.value.toLowerCase()) return true;
    }
    return false;
  }

  async sendMessage(){

    this.setState({sending: true}); // show that we are sending...

    // TBD - validate
    if ((!this.state.sendToValue) || (this.state.sendToValue.length<1))
    {
      alert("Please select a recipient");
      this.setState({
        sending: false
      })
      return;
    }
    if ((!this.state.includePdfLink) && ((!this.state.msg) || (this.state.msg.length<1)))
    {
      alert("If you are not going to include the PDF Link, then please include a message to the recipients!");
      this.setState({
        sending: false
      })
      return;
    }

      var recipients = this.state.sendToValue.map((recipient) =>{
        return recipient.value
      });
      await this.props.onSendPdfCompleteRequest(recipients, this.state.msg, this.state.includePdfLink, this.state.saveRecipients, this.state.saveMsg);

      console.log("posted email");
      this.setState({
        sending: false,
        msg: ""
      })
      this.props.onCloseRequest();    
  }

  handleSendToChange = (value, actionMeta) => {
    //if (actionMeta.action==="remove-value"){
    //  let tagValue = actionMeta.removedValue.value;
    //}
    var newValue = (value) ? value: [];
    this.setState({ sendToValue: newValue });
  };

  handleSendToInputChange = (sendToInputValue) => {
    var newValue = (sendToInputValue) ? sendToInputValue: "";
    this.setState({ sendToInputValue: newValue });
  };

  handleSendToKeyDown = (event) => {
    const { sendToInputValue, sendToValue } = this.state;
    if (!sendToValue) return;
    if (!sendToInputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        //console.group("Value Added");
        //console.log(sendToValue);
        //console.groupEnd();
        this.setState({
          sendToInputValue: "",
          sendToValue: [...sendToValue, createOption(sendToInputValue)],
        });
        event.preventDefault();
    }
  };


  render() {

    const { sendToInputValue, sendToValue } = this.state;

    const sendingDiv = (this.state.sending) ?
      <div><FontAwesomeIcon icon={faSyncAlt} spin={true} color={"green"}/>&nbsp; Sending...</div>
      : "";


    return (
      <Modal show={this.props.show} onHide={this.props.onCloseRequest}>
        <Modal.Header closeButton>
          <Modal.Title>Send Lot Liquidation PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row><Col>Send To</Col></Row>
          <Row>
            <Col>
            <CreatableSelect
                  //components={components}
                  inputValue={sendToInputValue}
                  isClearable
                  isMulti
                  isDisabled={false}
                  options={this.state.sendToValue}
                  onChange={this.handleSendToChange}
                  onInputChange={this.handleSendToInputChange}
                  onKeyDown={this.handleSendToKeyDown}
                  placeholder={"Enter an Email Address"}
                  value={this.state.sendToValue}
                />
            </Col>
          </Row>
          <Row><Col>Message</Col></Row>
          <Row>
            <Col>
            <InputGroup size="sm" className="textcard-input">
            <textarea
              rows={4}
              cols={50}
              autoFocus
              //textAlign="center"
              placeholder="Enter your message here..."
              aria-describedby="basic-addon2"
              name="msgComment"
              value={this.state.msg}
              onChange={this.onMsgChange}
            />

            <Dictaphone
              onTranscriptionChange={this.onTranscriptionChange}
            />

            </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Check
                type="checkbox"
                label="Include Lot Liquidation PDF link"
                checked={this.state.includePdfLink}
                onChange={this.onIncludePdfLinkChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                type="checkbox"
                label="Save Recipients"
                checked={this.state.saveRecipients}
                onChange={this.onSaveRecipientsChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                type="checkbox"
                label="Save Message"
                checked={this.state.saveMsg}
                onChange={this.onSaveMsgChange}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" 
            //disabled={!this.state.dataLoaded} 
            onClick={this.sendMessage}>
            Send
          </Button>
          <Button variant="secondary" onClick={this.props.onCloseRequest}>
            Cancel
          </Button>
          <div>
            {sendingDiv}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
