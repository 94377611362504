import React, { Component } from "react";
import { Box, Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@chakra-ui/react';

import gcLogo from "../../../assets/images/logos/glasschain-img-only.png";

import "../../../assets/css/sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faTable, faUsers } from "@fortawesome/free-solid-svg-icons";



export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    render() {
        return (
            <div className={(this.props.isVisible) ? "sidebody": "sidebody-hidden"}>

                <Accordion allowMultiple>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <img src={gcLogo} style={{ height: "50px" }} alt="glasschain logo"/>
                                <Box className="sidebartext" onClick={(e) => this.props.onHomePageRequest()}>
                                    Home
                                </Box>
                                {/* <AccordionIcon /> */}
                            </AccordionButton>
                        </h2>
                        <AccordionPanel >
                        <   Box className="sidebarlinktext" onClick={(e) => this.props.onLogoutRequest()}>
                                Log Out
                            </Box>
                        </AccordionPanel> 
                    </AccordionItem>

                    

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <FontAwesomeIcon icon={faTable} color="white" size="1x"/>
                                <Box className="sidebartext" onClick={(e) => this.props.onDataManagementPageRequest()}>
                                    Data Mgmt
                                </Box>
                                {/* <AccordionIcon /> */}
                            </AccordionButton>
                        </h2>
                        {/* <AccordionPanel >
                            <Box className="sidebarlinktext" onClick={(e) => this.props.onUserGuideRequest(e)}>
                                User Guide
                            </Box>
                            <br/>
                            <Box className="sidebarlinktext" onClick={(e) => this.props.onAboutOpen(e)}>
                                About..
                            </Box>
                        </AccordionPanel> */}
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <FontAwesomeIcon icon={faUsers} color="white" size="1x"/>
                                <Box className="sidebartext" onClick={(e) => this.props.onUserManagementPageRequest()}>
                                    User Mgmt
                                </Box>
                                {/* <AccordionIcon /> */}
                            </AccordionButton>
                        </h2>
                        {/* <AccordionPanel >
                            <Box className="sidebarlinktext" onClick={(e) => this.props.onUserGuideRequest(e)}>
                                User Guide
                            </Box>
                            <br/>
                            <Box className="sidebarlinktext" onClick={(e) => this.props.onAboutOpen(e)}>
                                About..
                            </Box>
                        </AccordionPanel> */}
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <FontAwesomeIcon icon={faCircleInfo} color="white" size="1x"/>
                                <Box className="sidebartext">
                                    Info
                                </Box>
                                {/* <AccordionIcon /> */}
                            </AccordionButton>
                        </h2>
                        <AccordionPanel >
                            <Box className="sidebarlinktext" onClick={(e) => this.props.onUserGuideRequest(e)}>
                                User Guide
                            </Box>
                            <br/>
                            <Box className="sidebarlinktext" onClick={(e) => this.props.onAboutOpen(e)}>
                                About..
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

            </div>
        )
    }
}