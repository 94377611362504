import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Box, Flex, Spacer, HStack, Card, CardBody, Text, SimpleGrid } from '@chakra-ui/react';

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import JavascriptHelpers from "../../../../components/lib/Javascript/JavascriptHelpers";
import BoolCellTrueOnlyRenderer from "../../../../components/lib/agGridRenderers/BoolCellTrueOnlyRenderer";
import { ifClosedDateOnlyFormatter, dateOnlyFormatter, dateTimeFormatter } from "../../../../components/lib/agGridRenderers/agGridFormatters";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faRedoAlt, faMoneyCheckAlt, faExternalLinkAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, TooltipProvider, TooltipWrapper } from 'react-tooltip';

import RoleManager from "../../../../models/RoleManager";
import { WorkflowStatus, getWorkflowStatusForegroundColor, getWorkflowStatusBackgroundColor } from "../../../../models/WorkflowStatusType";
import { AppSecurityKey } from "../../../../models/AppSecurityKey";

import AddLotDialog from "./AddLotDialog";
import CoreService from "../../../../services/CoreService";


/*
const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";
*/


const columnDefs = [
    { headerName: "Lot Id", field: "lotId", sortable: true, filter: true, resizable: true, maxWidth: 160 },
    {
        headerName: "Date Arrived", field: "dateArrived", sortable: true, filter: true, resizable: true, maxWidth: 180,
        cellStyle: params => {
            //if (params.data.activeSaleAmount !== params.data.saleAmount) {
            if (JavascriptHelpers.daysFromNow(params.data.dateArrived)<0)
                return { color: "black", backgroundColor: 'lightblue', "text-align": "left" }
            if (params.data.workflowStatus.workflowStatus === WorkflowStatus.REPORTSENT.id)
                return { color: "black", backgroundColor: 'lightgreen', "text-align": "left" }
            else if (JavascriptHelpers.areDaysFromNowBetween(params.data.dateArrived, 0, 29))
                return { color: "black", "text-align": "left" }
            else if (JavascriptHelpers.areDaysFromNowBetween(params.data.dateArrived, 30, 44))
                return { color: "black", backgroundColor: 'yellow', "text-align": "left" }
            else return { color: "white", backgroundColor: 'rgb(255, 71, 71)', "text-align": "left" }
        },
        cellRenderer: dateOnlyFormatter
    },
    {
        headerName: "Workflow Status", field: "workflowStatus.workflowStatus", sortable: true, filter: true, resizable: true, minWidth: 100, maxWidth: 180,
        cellStyle: params => { return { "text-align": "left" } }
    },
    //{ headerName: "Famous Id", field: "erpLotIdx", sortable: true, filter: true, resizable: true, maxWidth: 120 },
    { headerName: "Supplier", field: "supplier", sortable: true, filter: true, resizable: true, maxWidth: 350 },
    { headerName: "Vessel", field: "vessel", sortable: true, filter: true, resizable: true, minWidth: 130, maxWidth: 180 },
    { headerName: "Last Updated", field: "lastUpdated", sortable: true, filter: true, resizable: true, maxWidth: 200, cellRenderer: dateTimeFormatter },
    { headerName: "Closed", field: "isClosed", sortable: true, filter: true, resizable: true, maxWidth: 110, cellRendererFramework: BoolCellTrueOnlyRenderer },
    { headerName: "Date Closed", field: "closeDate", sortable: true, filter: true, resizable: true, maxWidth: 200, cellRenderer: ifClosedDateOnlyFormatter },
    // {
    //     headerName: "Workflow Status", field: "workflowStatus.workflowStatus", sortable: true, filter: true, resizable: true, minWidth: 130, maxWidth: 200,
    //     cellStyle: params => { return { color: getWorkflowStatusForegroundColor(params.data.workflowStatus.workflowStatus), backgroundColor: getWorkflowStatusBackgroundColor(params.data.workflowStatus.workflowStatus), "text-align": "right" } }
    // },
];



export default class LineGrid extends Component {
    constructor(props) {
        super(props);
        this.coreService = new CoreService();
        this.state = {
            gridSchema: [],
            schema: null,
            gridData: [],
            isLoading: true,
            selectedRow: null,
            showAddLotDialog: false
        }
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onPdfButtonClick = this.onPdfButtonClick.bind(this);
        this.onAddLotShowRequest = this.onAddLotShowRequest.bind(this);

        this.onRequestAddLotDialogClose = this.onRequestAddLotDialogClose.bind(this);
        this.onAddLotIdRequest = this.onAddLotIdRequest.bind(this);
        this.onUpdateLotRequest = this.onUpdateLotRequest.bind(this);
        this.onUpdateLotsClosedRequest = this.onUpdateLotsClosedRequest.bind(this);

        this.requestGridDataRefresh = this.requestGridDataRefresh.bind(this);
    }

    async componentDidMount() {

        if (this.props.isConnected) {
            if (!this.auth.loggedIn()) {
                console.log("ObservationsGridCard componentDidMount - not logged in");
                this.props.onSystemLogoutRequest();
            }
        }

        await this.refreshGridData();
    }


    async refreshGridData() {
        this.setState({ isLoading: true });
        this.props.onUpdatedLotsEvent(false); // once the user has refreshed, then there are no more updated lots.
        var gridDataResponse = await this.coreService.fetchWorkflowLotList(this.props.userSession.token);
        // TBD deal with error
        var newGridData = gridDataResponse.dataResult;
        console.log("the new grid data");
        console.log(newGridData);
        this.setState({
            gridData: newGridData,
            isLoading: false
        });
    }

    onRowDoubleClicked() {
        if (!RoleManager.hasRoleRights(AppSecurityKey.VIEW_LIQUIDATION, this.props.userSession.token)) {
            alert(AppSecurityKey.VIEW_LIQUIDATION.errorMessage);
            return;
        }
        this.props.onLiquidationPageRequest();
    }

    onSelectionChanged() {
        var selectedNodes = this.gridApi.getSelectedNodes();
        var rowData = selectedNodes[0].data;
        this.setState({ selectedRow: rowData });
        console.log("RowData: ");
        console.info(rowData);
        this.props.onLotSelectionChange(rowData.lotId);
    }

    onEditGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridColumnApi.applyColumnState({
            state: [
                {
                    colId: 'dateArrived',
                    sort: 'desc'
                }
            ]
        });
        this.gridApi.sizeColumnsToFit(params);
    };

    async requestGridDataRefresh(e) 
    {
        await this.refreshGridData();
        this.props.onToastRequest("information", "Lot List Refreshed");
    }

    onPdfButtonClick(e) {
        if (!RoleManager.hasRoleRights(AppSecurityKey.SEND_REPORT, this.props.userSession.token)) {
            alert(AppSecurityKey.SEND_REPORT.errorMessage);
            return;
        }
        if (!this.state.selectedRow) {
            //this.showAlertDialog("Select an Inspection", "Please select an observation first!", "error");
            alert('Please select a Lot first!');
            return;
        }
        if (!RoleManager.hasRoleStatusRights(AppSecurityKey.SEND_REPORT, this.state.selectedRow.workflowStatus.workflowStatus, this.props.userSession.token)) {
            alert(AppSecurityKey.SEND_REPORT.errorMessage);
            return;
        }
        console.log("selected row: ");
        console.info(this.state.selectedRow);
        //this.props.onPdfRequest(this.state.selectedRow.lotId);
    }

    async onAddLotIdRequest(currLotId) {
        if (!RoleManager.hasRoleRights(AppSecurityKey.ADD_LOT, this.props.userSession.token)) {
            alert(AppSecurityKey.ADD_LOT.errorMessage);
            return;
        }
        this.setState({ showAddLotDialog: false });
        await this.coreService.postLotAddRequest(currLotId, this.props.userSession.token);
        //this.props.onRequestToAddLotUpdateWatcher(currLotId, JavascriptHelpers.isoNow());
        this.props.onToastRequest("success", "Lot " + currLotId + " has been requested from Famous. You will get a notification when it's ready to view.");
    }

    async onUpdateLotRequest() {
        if (!RoleManager.hasRoleRights(AppSecurityKey.ADD_LOT, this.props.userSession.token)) {
            alert(AppSecurityKey.ADD_LOT.errorMessage);
            return;
        }
        if (!this.state.selectedRow) {
            alert('Please select a Lot first!');
            return;
        }
        console.log("selected row: ");
        console.info(this.state.selectedRow);
        var lotId = this.state.selectedRow.lotId;
        await this.coreService.postLotAddRequest(lotId, this.props.userSession.token);
        //this.props.onRequestToAddLotUpdateWatcher(lotId, this.state.selectedRow.lastUpdated);
        this.props.onToastRequest("success", "Lot " + lotId + " refresh has been requested from Famous. You will get a notification when it's ready to view.");
    }

    async onUpdateLotsClosedRequest(e) {
        if (!RoleManager.hasRoleRights(AppSecurityKey.ADD_LOT, this.props.userSession.token)) {
            alert(AppSecurityKey.ADD_LOT.errorMessage);
            return;
        }
        await this.coreService.postClosedLotsRequest(this.props.userSession.token);
        //this.props.onRequestToAddLotUpdateWatcher(lotId, this.state.selectedRow.lastUpdated);
        this.props.onToastRequest("success", "Closed Lots update request has been requested from Famous. If there are new closed lots you will get a notification when they are ready to view.");

    }



    onAddLotShowRequest(e) {
        this.setState({ showAddLotDialog: true });
    }

    onRequestAddLotDialogClose() {
        this.setState({ showAddLotDialog: false });
    }

    onLiquidationPageRequest(e) {
        if (!RoleManager.hasRoleRights(AppSecurityKey.VIEW_LIQUIDATION, this.props.userSession.token)) {
            alert(AppSecurityKey.VIEW_LIQUIDATION.errorMessage);
            return;
        }
        if (!this.state.selectedRow) {
            //this.showAlertDialog("Select an Inspection", "Please select an observation first!", "error");
            alert('Please select a lot first!');
            return;
        }
        this.props.onLiquidationPageRequest(this.state.selectedRow.lotId)
    }

    render() {

        const divStyle = {
            width: "100%",
            height: "500px",
        };

        const gridData = (this.state.gridData) ? this.state.gridData : [{}];
        const loadingIcon = this.state.isLoading ? (
            <FontAwesomeIcon icon={faSyncAlt} color="green" spin />
        ) : (
            ""
        );

        return (
            <Box p='4'>
                <Tooltip />
                <AddLotDialog
                    show={this.state.showAddLotDialog}
                    onRequestAddLotDialogClose={this.onRequestAddLotDialogClose}
                    onAddLotIdRequest={this.onAddLotIdRequest}
                />

                <Card 
                    overflow='hidden'
                    background='white'
                >
                    <Text style={{ textAlign: "left", marginTop: "5px", marginLeft: "10px", fontSize: '20px' }}>Lot List</Text>
                    <CardBody>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <p data-tip="Add a Lot">
                                <Button size="sm" variant="primary" disabled={!RoleManager.hasRoleRights(AppSecurityKey.ADD_LOT, this.props.userSession.token)} onClick={(e) => this.onAddLotShowRequest(e)}>

                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;&nbsp;Add Lot
                                </Button>
                            </p>
                            &nbsp;
                            <p data-tip="Update a Lot">
                                <Button size="sm" variant="primary" disabled={!RoleManager.hasRoleRights(AppSecurityKey.UPDATE_LOT, this.props.userSession.token)} onClick={(e) => this.onUpdateLotRequest(e)}>
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                    &nbsp;&nbsp;Update Lot
                                </Button>
                            </p>
                            &nbsp;
                            <p data-tip="Refresh List">
                                <Button size="sm" variant={(this.props.hasUpdatedLots) ? "success" : "primary"} disabled={!RoleManager.hasRoleRights(AppSecurityKey.REFRESH_LOTS, this.props.userSession.token)} onClick={(e) => this.requestGridDataRefresh(e)}>
                                    <FontAwesomeIcon icon={faRedoAlt} />
                                    &nbsp;&nbsp;Refresh Lot List
                                </Button>
                            </p>
                            &nbsp;
                            <p data-tip="Update Lot Close Statuses">
                                <Button size="sm" variant="primary" disabled={!RoleManager.hasRoleRights(AppSecurityKey.REFRESH_LOTS, this.props.userSession.token)} onClick={(e) => this.onUpdateLotsClosedRequest(e)}>
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                    &nbsp;&nbsp;Update Lots Closed Status
                                </Button>
                            </p>
                            &nbsp;
                            <p data-tip="View Lot Liquidation Summary">
                                <Button size="sm" variant="primary" disabled={!RoleManager.hasRoleRights(AppSecurityKey.VIEW_LIQUIDATION, this.props.userSession.token)} onClick={(e) => this.onLiquidationPageRequest(e)}>
                                    <FontAwesomeIcon icon={faMoneyCheckAlt} />
                                    &nbsp;&nbsp;View Liquidation
                                </Button>
                            </p>

                            <span style={{ marginLeft: "20vw" }}>{loadingIcon}</span>
                        </div>

                        <div className="ag-theme-balham" style={divStyle}>
                            <AgGridReact
                                alwaysShowVerticalScroll
                                rowSelection="single"
                                enableRangeSelection={false}
                                pagination={true}
                                columnDefs={columnDefs}
                                rowData={gridData}
                                animateRows={true}
                                onGridReady={this.onGridReady}
                                // domLayout="autoHeight"
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                                onRowDoubleClicked={this.onRowDoubleClicked.bind(this)}
                            />
                        </div>
                    </CardBody>
                </Card>

            </Box>
        );
    }
}