import axios from 'axios';
import ConfigService from "./ConfigService";
import HttpHelpers from "../components/lib/Http/HttpHelpers";
import _ from "lodash";


export default class AuthService
{
    constructor(authDomain)
    {
        this.configService = new ConfigService();
        this.authDomain = authDomain || this.configService.getAuthServiceHost();
        this.baseUserScope = this.configService.getBaseUserScope();
    }

    async isAuthorizedUserLogin(gcid, uid, pwd)
    {
        //var scope = _.cloneDeep(this.baseUserScope);
        var scope = this.baseUserScope;
        // requiredScope is represented by a string array
        //scope.push(gcid) 
        let scopeString = scope.join(" ");
        console.log("Scopes: ");
        console.log(scopeString);
        var params = {
            grant_type: 'client_credentials',
            client_id: uid,
            client_secret: pwd,
            scope: scopeString
            //scope: gcid +  " qainspector qacoreapi notificationapi" // note how multiple audiences (resources) are called by a string with space separating them
        }; 
        const formBody = Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
        try 
        {
            var config = {
                method: 'post',
                timeout: 15000,
                url: `${this.authDomain}/connect/token`,
                data: formBody,
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            }
            //let fullUrl = `${this.authDomain}/connect/token`;
            const response = await axios.post( config.url, formBody, config);
            if (HttpHelpers.HttpStatusOk(response.status))
            {
                return {"httpStatusCode": response.status, "hasError": false, "messages": [], "dataResult": true};
            }
            else 
            {
                return {"httpStatusCode": response.status, "hasError": false, "messages": [response.statusText], "dataResult": false};
            }
        } catch(err)
        {
            if (err.isAxiosError)
            {
                return {"httpStatusCode": "axios", "hasError": true, "messages": ["axios connection error.",err.message], "dataResult": null};

            }
            if (err.response.status === 400)
            {
                // we will assume this is an invalid attempt, not an actual error. We should change the auth service to return a better code!
                return {"httpStatusCode": err.response.status, "hasError": false, "messages": [err.name, err.message, err.response.statusText], "dataResult": false};
            }
            return {"httpStatusCode": err.response.status, "hasError": true, "messages": [err.name, err.message, err.response.statusText], "dataResult": null};
        }        
    }

    async getUserToken(gcid, uid, pwd, scopes)
    {
        //var scope =this.userScope;
        // requiredScope is represented by a string array
        //scope.push(gcid) 
        let scopeString = scopes.join(" ");
        console.log("Scopes: ");
        console.log(scopeString);
        var params = {
            grant_type: 'client_credentials',
            client_id: uid,
            client_secret: pwd,
            scope: scopeString
            //scope: gcid +  " qainspector qacoreapi notificationapi" // note how multiple audiences (resources) are called by a string with space separating them
        }; 
        const formBody = Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
        try 
        {
            var config = {
                method: 'post',
                timeout: 5000,
                url: `${this.authDomain}/connect/token`,
                data: formBody,
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            }
            //let fullUrl = `${this.authDomain}/connect/token`;
            const response = await axios.post( config.url, formBody, config);
            if (HttpHelpers.HttpStatusOk(response.status))
            {
                return {"httpStatusCode": response.status, "hasError": false, "messages": [], "dataResult": response.data.access_token};
            }
            else 
            {
                return {"httpStatusCode": response.status, "hasError": true, "messages": [response.statusText], "dataResult": null};
            }
        } catch(err)
        {
            if (err.response.status === 400)
            {
                // we will assume this is an invalid attempt, not an actual error. We should change the auth service to return a better code!
                return {"httpStatusCode": err.response.status, "hasError": false, "messages": [err.name, err.message, err.response.statusText], "dataResult": false};
            }
            return {"httpStatusCode": "", "hasError": true, "messages": [err.name, err.message], "dataResult": null};
        }
    }


    



}