import React, { Component } from "react";
import { Card } from "react-bootstrap";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { currencyFormatter, commaFormatter } from "../../../components/lib/agGridRenderers/agGridFormatters";

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";

const columnDefs = [
    { headerName: "Configuration", field: "configuration", sortable: true, filter: false, resizable: true, maxWidth: 100 },
    { headerName: "Qty Sold", headerClass: "ag-right-aligned-header", field: "displayQty", sortable: true, filter: true, resizable: true, 
        cellStyle: (params) => {
            if (params.data.displayQty !== params.data.qty) {
                return { color: "darkgreen", backgroundColor: "lightblue", "text-align": "right" };
            } else {
                return { "text-align": "right" };
            }
        },
        valueFormatter: commaFormatter },
    { headerName: "FOB $/Unit", headerClass: "ag-right-aligned-header", field: "price", sortable: true, filter: false, resizable: true, 
    cellStyle: (params) => {
        if (params.data.hasAdjustments) {
            return { color: "darkgreen", backgroundColor: "lightblue", "text-align": "right" };
        } else {
            return { "text-align": "right" };
        }
    },
        valueFormatter: currencyFormatter },
 ];



export default class SalesGrid extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            gridSchema: [],
            schema: null,
            gridData: [],
            isLoading: true,
            selectedRow: null
        }
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }



    onSelectionChanged() {
        var selectedNodes = this.gridApi.getSelectedNodes();
        var rowData = selectedNodes[0].data;
        this.setState({ selectedRow: rowData });
    }

    onEditGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit(params)
    };

 





    render() {

        const divStyle = {
            width: "100%",
            height: "32vh"
        };

        console.log("Sale Data Changed: ");
        console.info(this.props.data);

        const gridData = (this.props.data) ? this.props.data : [];
        //const totalUnits = (this.props.data) ? this.totalUnits(this.props.data): 0;
        //const totalExtAmount = (this.props.data) ? this.totalExtAmount(this.props.data): 0;


        return (
            <div style={{minWidth: "28vw", margin: "1vw"}}>
                <Card >
                    <Card.Header>Sales Configurations</Card.Header>
                    <Card.Body>
                        <div className="ag-theme-balham" style={divStyle}>
                            <AgGridReact
                                rowSelection="single"
                                enableRangeSelection={false}
                                pagination={true}
                                columnDefs={columnDefs}
                                rowData={gridData}
                                animateRows={true}
                                onGridReady={this.onGridReady}
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}