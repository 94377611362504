import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import { faWrench, faUnlink } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { currencyFormatter, commaFormatter, activeQuantityFormatter, activeSaleAmountFormatter } from "../../../components/lib/agGridRenderers/agGridFormatters";
import { toUsDollars, toUsComma } from "../../../components/lib/Javascript/jsUtils";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import ModalAdjustmentDialog from "./ModalAdjustmentDialog"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CoreService from "../../../services/CoreService";

import { AppSecurityKey } from "../../../models/AppSecurityKey";
import RoleManager from "../../../models/RoleManager";


const DelSwal = withReactContent(Swal);

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";

const columnDefs = [
  {
    headerName: "Commodity",
    field: "commodity",
    sortable: true,
    filter: true,
    resizable: true,
    minWidth: 80,
    
  },
  {
    headerName: "Size",
    field: "size",
    sortable: true,
    filter: true,
    resizable: true,
    
  },
  {
    headerName: "Style",
    field: "style",
    sortable: true,
    filter: true,
    resizable: true,
    
  },
  {
    headerName: "Variety",
    colId: "variety",
    field: "variety",
    sortable: true,
    filter: true,
    resizable: true,
    
  },
  {
    headerName: "Method",
    colId: "method",
    field: "method",
    sortable: true,
    filter: true,
    resizable: true,
    
  },
  {
    headerName: "Quantity",
    headerClass: "ag-right-aligned-header",
    field: "quantity",
    sortable: true,
    filter: false,
    resizable: true,
    
    cellStyle: params => {
      if (params.data.activeQuantity !== params.data.quantity) {
        return { color: "lightgrey", "text-align": "right" }
      }
      else {
        return { "text-align": "right" }
      }
    },
    cellRenderer: commaFormatter,
  },
  {
    headerName: "Adj Qty",
    headerClass: "ag-right-aligned-header",
    colId: "activeQuantity",
    field: "activeQuantity",
    sortable: true,
    filter: false,
    resizable: true,
    
    cellStyle: params => {
      if (params.data.activeQuantity !== params.data.quantity) {
        return { color: "darkgreen", backgroundColor: 'lightblue', "text-align": "right" }
      }
      else {
        return { "text-align": "right" }
      }
    },
    cellRenderer: activeQuantityFormatter,
  },
  {
    headerName: "Sale Amount ($)",
    headerClass: "ag-right-aligned-header",
    field: "saleAmount",
    sortable: true,
    filter: false,
    resizable: true,
    
    cellStyle: params => {
      if (params.data.activeSaleAmount !== params.data.saleAmount) {
        return { color: "lightgrey", "text-align": "right" }
      }
      else {
        return { "text-align": "right" }
      }
    },
    cellRenderer: currencyFormatter,
  },
  {
    headerName: "Adj Amount ($)",
    headerClass: "ag-right-aligned-header",
    colId: "activeSaleAmount",
    field: "activeSaleAmount",
    sortable: true,
    filter: false,
    resizable: true,
    
    cellStyle: params => {
      if (params.data.activeSaleAmount !== params.data.saleAmount) {
        return { color: "darkgreen", backgroundColor: 'lightblue', "text-align": "right" }
      }
      else {
        return { "text-align": "right" }
      }
    },
    cellRenderer: activeSaleAmountFormatter
  },
  {
    headerName: "Price",
    headerClass: "ag-right-aligned-header",
    field: "price",
    sortable: true,
    filter: false,
    resizable: true,
    
    cellStyle: { "text-align": "right" },
    cellRenderer: currencyFormatter
  }
];



export default class InOutGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedRow: null,
      propsDataChanged: false,
      showAdjust: false,
      hasAdjustment: false,
      gridParams: null
    };
    this.coreService = new CoreService();

    this.resetGridColumns = this.resetGridColumns.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.showAdjustmentDialog = this.showAdjustmentDialog.bind(this);
    this.hideAdjustmentDialog = this.hideAdjustmentDialog.bind(this);
    this.onAdjustmentComplete = this.onAdjustmentComplete.bind(this);
    this.onAdjustmentDeleteRequest = this.onAdjustmentDeleteRequest.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.showVarieties !== this.props.showVarieties) || (prevProps.showMethods !== this.props.showMethods)) {
      this.resetGridColumns();
    }
  }

  async onAdjustmentDeleteRequest() 
  {
    if (!RoleManager.hasRoleStatusRights(AppSecurityKey.ADJUST_LOT, this.props.lot.workflowStatus.workflowStatus, this.props.userSession.token))
    {
        alert(AppSecurityKey.ADJUST_LOT.errorMessage);
        return;
    }
    if (!this.state.selectedRow) {
      DelSwal.fire({ icon: "error", title: "Remove Adjustment", text: "Select a row first!" });
      return;
    }
    if (!this.state.hasAdjustment) {
      DelSwal.fire({ icon: "error", title: "Remove Adjustment", text: "It looks like this line doesn't have an adjustment to remove?" });
      return;
    }
    DelSwal.fire
      (
        {
          icon: "question",
          title: "Remove Adjustment?",
          text: "Confirm you want to permanently remove the existing adjustment",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel"
        }
      ).then(async (result) => {
        if (result.value) {
          var lotId = this.props.lot.lotId;
          var adjustmentKey = this.state.selectedRow.lineKey;
          var deleteResult = await this.coreService.PostAdjustDeleteRequest(lotId, adjustmentKey, this.props.userSession.token);
          await this.props.onRefreshLotDataRequest();
          this.props.onToastRequest("success", "Adjustment (" + adjustmentKey + ") has been removed");
          //return DelSwal.fire({icon: "success", text: "The adjustment has been removed"});
        }
      }
      );

  }

  onAdjustmentRequest() {
    if (!RoleManager.hasRoleStatusRights(AppSecurityKey.ADJUST_LOT, this.props.lot.workflowStatus.workflowStatus, this.props.userSession.token))
    {
        alert(AppSecurityKey.ADJUST_LOT.errorMessage);
        return;
    }
    if (!this.state.selectedRow) 
    {
      DelSwal.fire({ icon: "error", title: "Remove Adjustment", text: "Select a row first!" });
      return;
    }

    this.showAdjustmentDialog();
  }

  onRowDoubleClicked() 
  {
    if (!RoleManager.hasRoleStatusRights(AppSecurityKey.ADJUST_LOT, this.props.lot.workflowStatus.workflowStatus, this.props.userSession.token))
    {
        alert(AppSecurityKey.ADJUST_LOT.errorMessage);
        return;
    }
    this.showAdjustmentDialog();
  }

  showAdjustmentDialog() {
    this.setState({ showAdjust: true });
  }

  hideAdjustmentDialog() {
    this.setState({ showAdjust: false });
  }

  async onAdjustmentComplete(adjustment) {
    var origDataRow = this.state.selectedRow;
    this.setState({ selectedRow: origDataRow });
    if (adjustment.adjustType === "inOut") {
      await this.coreService.PostInOutAdjustRequest(this.props.lot, adjustment, this.props.userSession.token);
    }
    await this.props.onRefreshLotDataRequest();
    this.props.onToastRequest("success", "Adjustment has been created");
    this.hideAdjustmentDialog();
  }

  onSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();
    var rowData = selectedNodes[0].data;
    var hasAdjustment = ((rowData.quantity !== rowData.activeQuantity) || (rowData.saleAmount !== rowData.activeSaleAmount));
    this.setState({
      selectedRow: rowData,
      hasAdjustment: hasAdjustment
    });
  }

  onEditGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.setColumnVisible('variety', this.props.showVarieties);
    this.gridColumnApi.setColumnVisible("method", this.props.showMethods);
    this.gridApi.sizeColumnsToFit(params)
    //this.gridColumnApi.setColumnVisible("activeQuantity", this.props.showActiveQuantities);
    //this.gridColumnApi.setColumnVisible("activeSaleAmount", this.props.showActiveSaleAmounts);
    this.setState({ gridParams: params });
  };


  resetGridColumns() {
    var params = this.state.gridParams;
    if (params) {
      this.gridColumnApi = params.columnApi;
      this.gridColumnApi.setColumnVisible('variety', this.props.showVarieties);
      this.gridColumnApi.setColumnVisible("method", this.props.showMethods);
    }
  }



  render() {
    const divStyle = {
      width: "100%",
      height: "32vh",
    };

    const gridData = this.props.data ? this.props.data : [{}];
    console.log("InOut Data Rendering:");
    console.info(this.props.data);
    const totalUnits = this.props.data ? this.props.totalBoxes : 0;
    const totalPallets = this.props.data ? this.props.totalPallets : 0;
    const totalSales = this.props.data ? this.props.totalSales : 0;

    return (
      <div style={{ width: "90vw" }}>
        <ModalAdjustmentDialog
          show={this.state.showAdjust}
          onRequestModalAdjustmentDialogClose={this.hideAdjustmentDialog}
          adjustmentType="inOut"
          inOutType={this.props.inOutType}
          lot={this.props.lot}
          dataRow={this.state.selectedRow}
          onAdjustmentComplete={this.onAdjustmentComplete}
        />
        <Card style={{marginBottom: "0"}}>
          <Card.Header style={{ display: "flex", flexDirection: "row" }}>
            In/Out &nbsp;
            <p
              style={{ marginBottom: "5px", marginRight: "5px" }}
              data-tip="Adjust"
            >
              <Button
                size="sm"
                variant="primary"
                disabled={!RoleManager.hasRoleStatusRights(AppSecurityKey.ADJUST_LOT, this.props.lot.workflowStatus.workflowStatus, this.props.userSession.token)}
                onClick={(e) => this.onAdjustmentRequest(e)}
              >
                <FontAwesomeIcon icon={faWrench} />
                &nbsp;&nbsp;Adjust
              </Button>
            </p>
            <p
              style={{ marginBottom: "5px", marginRight: "5px" }}
              data-tip="Remove Adjustment"
            >
              <Button
                size="sm"
                variant="primary"
                hidden={!this.state.hasAdjustment}
                onClick={(e) => this.onAdjustmentDeleteRequest(e)}
              >
                <FontAwesomeIcon icon={faUnlink} />
                &nbsp;&nbsp;Remove Adjustment
              </Button>
            </p>
          </Card.Header>
          <Card.Body>
            <div className="ag-theme-balham" style={divStyle}>
              <AgGridReact
                rowSelection="single"
                enableRangeSelection={false}
                pagination={true}
                columnDefs={columnDefs}
                rowData={gridData}
                animateRows={true}
                onGridReady={this.onGridReady}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
                onRowDoubleClicked={this.onRowDoubleClicked.bind(this)}
              />
            </div>
          </Card.Body>
          <Card.Footer style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Total Pallets:{" "}<span style={{ fontWeight: "bold" }}>{toUsComma(totalPallets)}</span></span>
            <span>Total Boxes: <span style={{ fontWeight: "bold" }}>{toUsComma(totalUnits)}</span></span>
            <span>TotalSales:{" "}<span style={{ fontWeight: "bold" }}>{toUsDollars(totalSales)}</span></span>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}
