
export default class ServicesGridModel
{
    constructor(lotData, totalServices, totalRepackServices, totalAdvances, servicesGridLines)
    {
        this.lotData = lotData || [];
        this.totalServices = totalServices || 0;
        this.totalRepackServices = totalRepackServices || 0;
        this.totalAdvances = totalAdvances || 0;
        this.servicesGridLines = servicesGridLines || [];
    }

    static toServicesGridModel(lotData, serviceAdjustments)
    {
        var model = new ServicesGridModel(lotData);
        model.totalRepackServices = ServicesGridModel.toTotalRepackServices(lotData);
        var reducedServiceGroups = ServicesGridModel.reduceToServiceGroups(lotData, serviceAdjustments);
        model.servicesGridLines = reducedServiceGroups;
        model.totalServices = ServicesGridModel.toTotalServices(reducedServiceGroups);
        model.totalAdvances = ServicesGridModel.toTotalAdvances(lotData); 
        var defaultSortedServicesLines = model.servicesGridLines.sort((a, b) => (
            a.charge.serviceGroup.localeCompare(b.charge.serviceGroup) 
        ));
        model.servicesGridLines = defaultSortedServicesLines;
        return model;
    }

    static toTotalAdvances(lotData)
    {
        if (!lotData.services) return 0;
        var result = lotData.services.lines.reduce((t, o) => {
            return o.charge.serviceGroup === "Advances" ? t + o.extAmount : t + 0;
          }, 0);
        return result;
    }

    static toTotalRepackServices(lotData)
    {
        if (!lotData.services) return 0;
        var repackingLines = lotData.services.lines.filter(
          (s) => s.charge.serviceGroup === "Repacking"
        );
        var result = repackingLines.reduce((r, o) => {
          return r + o.extAmount;
        }, 0);
        return result;
    }

    static toTotalServices(reducedServiceGroups)
    {
        var result = reducedServiceGroups.reduce((r, o) => 
        {
            return r + o.activeExtAmount
        }, 0);
        return result;
    }

    static removeGrowerPayments(services)
    {
        var result = services.lines.filter(
            (s) =>
              s.charge.serviceGroup !== "Advances" &&
              (!s.charge.serviceGroup.startsWith("Grower Payment"))
        );
        return result; 
    }

    static lineHasAdjustments(lineKey, adjustments)
    {

        if (!adjustments) return false;
        if (adjustments.length < 1) return false;
        if (!adjustments.some(a => a.adjustmentKey === lineKey)) return false;
        return true;

        //return ((adjustments) && (adjustments.find(a => a.adjustmentKey === lineKey)));
    }

    static toLineAdjustment(lineKey, adjustments)
    {
        return adjustments.find(a => a.adjustmentKey === lineKey);
    }

    static toServiceGroupFactor(lineKey)
    {
        return (lineKey === "Expeditor") ? .5 : 1;
    }

    static reduceToServiceGroups(lotData, serviceAdjustments)
    {
        if (!lotData.services) return null;
        // remove any Grower payments and/or advances

        var withoutGrowerPayments = ServicesGridModel.removeGrowerPayments(lotData.services);
        var helper = {};
        var result = withoutGrowerPayments.reduce(function (r, o) {
          var key = o.charge.serviceGroup;
          var linePreviouslyExists = (helper[key]);
          var serviceGroupFactor = ServicesGridModel.toServiceGroupFactor(key);
          var lineHasAdjustments = ServicesGridModel.lineHasAdjustments(key, serviceAdjustments);
          if (!linePreviouslyExists)
          {
                // generate the line!
                helper[key] = Object.assign({}, o);
                helper[key].extAmount = (helper[key].extAmount * serviceGroupFactor);
          }
          else
          {
              // aggregate values
              helper[key].extAmount += (o.extAmount * serviceGroupFactor);
          }
          helper[key].activeExtAmount = (lineHasAdjustments) ? ServicesGridModel.toLineAdjustment(key, serviceAdjustments).adjustedExtAmount: helper[key].extAmount;
          if (!linePreviouslyExists)
          {
              // push it onto the return stack
              r.push(helper[key]);
          }
        return r;
        }, []);
        return result;
    }
}