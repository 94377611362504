import { WorkflowStatus } from "./WorkflowStatusType";

export const AppSecurityKey = Object.freeze({
    ADD_LOT:   { name: "Add Lot", roles: ["lgs.preparer"], workflowStatuses: [], errorMessage: "You do not have rights to add lots." },
    UPDATE_LOT: { name: "Request Lot Update", roles: ["lgs.preparer", "lgs.approver", "lgs.sender"], workflowStatuses: [], errorMessage: "You do not have rights to update lots." },
    REFRESH_LOTS: { name: "Refresh Lots", roles: ["lgs.preparer", "lgs.approver", "lgs.sender"], workflowStatuses: [], errorMessage: "You do not have rights to refresh lots." },
    VIEW_LIQUIDATION: { name: "View Lot Liquidation", roles: ["lgs.preparer", "lgs.approver", "lgs.sender"], workflowStatuses: [], errorMessage: "You do not have rights to view Lot Liquidations." },
    ADJUST_LOT:  { name: "Adjust Lot", roles: ["lgs.preparer", "lgs.approver"], workflowStatuses: [WorkflowStatus.PREPARING, WorkflowStatus.PENDING], errorMessage: "You do not have rights to adjust Lots." },
    SEND_REPORT: { name: "Send Report", roles: ["lgs.sender"], workflowStatuses: [WorkflowStatus.APPROVED, WorkflowStatus.REPORTSENT], errorMessage: "You do not have rights to send Reports." },
    EDIT_SUPPLIERS: { name: "Edit Suppliers", roles: ["lgs.data.admin"], workflowStatuses: [], errorMessage: "You do not have rights to View or Edit Supplier Information." },
    EDIT_SERVICEMAPS: { name: "Edit Service Maps", roles: ["lgs.data.admin"], workflowStatuses: [], errorMessage: "You do not have rights to edit Service Maps." },
    EDIT_USERS: { name: "Edit Users", roles: ["lgs.data.admin"], workflowStatuses: [], errorMessage: "You do not have rights to Edit Users." }
  });