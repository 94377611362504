import React, { Component } from "react";

import HealthFolder from "./Components/Health/HealthFolder";
import LineGrid from "./Components/LotGrid/LineGrid";
import CoreService from "../../services/CoreService";
import ConfigService from "../../services/ConfigService";

import "../../assets/css/main.css";
import JavascriptHelpers from "../../components/lib/Javascript/JavascriptHelpers";
import JwtToken from "../../components/lib/tokens/JwtToken";



export default class MainPage extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {
            watchListLastUpdated: JavascriptHelpers.isoUtcNow()
        }
        this.coreService = new CoreService();
        this.configService = new ConfigService();

    }






    


    render() {
        return (
            <div className="main">
                <HealthFolder 
                    userSession = {this.props.userSession}
                />

            <LineGrid
                userSession = {this.props.userSession}
                userGcid = {this.gcid}
                onPdfRequest = {this.props.onPdfPageRequest}
                onLiquidationPageRequest = {this.props.onLiquidationPageRequest}
                onLotSelectionChange={this.props.onLotSelectionChange}
                onToastRequest={this.props.onToastRequest}
                hasUpdatedLots={this.props.hasUpdatedLots}
                onUpdatedLotsEvent={this.props.onUpdatedLotsEvent}
            />

            </div>
        )

    }
}