import {v1 as uuidv1} from "uuid";

export default class IdentityService {

    static makeUuid() {
        return uuidv1();
    }




    
}

