import {appsettings} from "../AppSettings"


export default class ConfigService
{
    constructor()
    {
        this.appsettings = appsettings;
    }


    hasKey(key){
        return this.appsettings.some(o => o.key === key);
    }

    getValue(key){
        let obj = this.appsettings.find(o => o.key === key);
        return obj.value;
    }

    getAppVersion()
    {
        return this.getValue("version");
    }

    getAuthServiceHost()
    {
        return this.getValue("glasschain.auth.host.addr");
    }

    getAuthResourceServiceHost()
    {
        return this.getValue("glasschain.auth.resource.host.addr");
    }

    getCoreServiceHost()
    {
        return this.getValue("glasschain.core.host.addr");
    }


    getNotificationServiceHost()
    {
        return this.getValue("glasschain.notification.host.addr");
    }

    getCustomerLogoImagePath(gcid)
    {
        // https://gcblobstoredurable1.blob.core.windows.net/gcid/lgs/lgs-logo.png
        var result = this.getValue("glasschain.image.store.addr") +"gcid/" + gcid + "/" + gcid + "-logo.png";
        return result;
    }

    getUserGuideFilePath()
    {
        var result = this.getValue("userguide.file.addr") + this.getValue("userguide.file.name");
        return result;
    }

    getBaseUserScope()
    {
        return this.getValue("base.user.scope");
    }

    getLotReportHostAddr(gcid)
    {
        var result = this.getValue("glasschain.report.host.addr") + "/" + gcid + "/reports/";
        return result;
    }

    getDefaultEmailFromAddress()
    {
        return this.getValue("lgs.email.from.address");
    }

    getWatchInterval()
    {
        return this.getValue("lot.watch.interval.milliseconds");
    }

    // roles we do NOT want to be assigned to users
    getSystemRoles()
    {
        return this.getValue("system.roles");
    }

    // users we do NOT want clients to modify
    getSystemUsers()
    {
        return this.getValue("system.users");
    }

}