import React, { Component } from "react";
import { Card } from "react-bootstrap";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { currencyFormatter, decimalLengthFormatter } from "../../../components/lib/agGridRenderers/agGridFormatters";

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";

const columnDefs = [
    {headerName: "Receipts", headerClass: "ag-superheader", children: 
    [
    { headerName: "Description", field: "inputItem.item.name", sortable: true, filter: true, resizable: true, minWidth:280, maxWidth: 350 },
    { headerName: "Size", field: "inputItem.size.name", sortable: true, filter: true, resizable: true, maxWidth: 80 },
    { headerName: "Style", field: "inputItem.style.name", sortable: true, filter: true, resizable: true, maxWidth: 80 },
    { headerName: "Variety", field: "inputItem.variety.name", sortable: true, filter: true, resizable: true, maxWidth: 80 },
    { headerName: "Method", field: "inputItem.method.name", sortable: true, filter: true, resizable: true, maxWidth: 80 },

    { headerName: "Qty", headerClass: "ag-right-aligned-header", field: "receiptQty", sortable: true, filter: false, resizable: true, maxWidth: 80, cellStyle: {'text-align': 'right'} },
    { headerName: "Alloc", headerClass: "ag-right-aligned-header", field: "receiptQtyAllocPct", sortable: true, filter: false, resizable: true, maxWidth: 100, cellStyle: {'text-align': 'right'}, cellRenderer: decimalLengthFormatter },  
    { headerName: "Input", headerClass: "ag-right-aligned-header", field: "inputQty", sortable: true, filter: false, resizable: true, maxWidth: 80, cellStyle: {'text-align': 'right'} },
    ]},
    
    { headerName: "In/Out Key", field: "inOutKey", sortable: true, filter: true, resizable: true, maxWidth: 120 },

    {headerName: "Sales", headerClass: "ag-superheader", children: 
    [
    { headerName: "Output", headerClass: "ag-right-aligned-header", field: "outputQty", sortable: true, filter: false, resizable: true, maxWidth: 80, cellStyle: {'text-align': 'right'} },
    { headerName: "Alloc", headerClass: "ag-right-aligned-header", field: "receiptQtyAllocPct", sortable: true, filter: false, resizable: true, maxWidth: 100, cellStyle: {'text-align': 'right'}, cellRenderer: decimalLengthFormatter },  
    { headerName: "Qty", headerClass: "ag-right-aligned-header", field: "saleQty", sortable: true, filter: false, resizable: true, maxWidth: 80, cellStyle: {'text-align': 'right'} },
    { headerName: "Price", headerClass: "ag-right-aligned-header", field: "unitPrice", sortable: true, filter: false, resizable: true, maxWidth: 80, cellStyle: {'text-align': 'right'}, cellRenderer: currencyFormatter },
    { headerName: "Sale Amount ($)", headerClass: "ag-right-aligned-header", field: "saleExtAmount", sortable: true, filter: false, resizable: true, maxWidth: 140, cellStyle: {'text-align': 'right'}, cellRenderer: currencyFormatter},
    { headerName: "Size", field: "outputItem.size.name", sortable: true, filter: true, resizable: true, maxWidth: 80 },
    { headerName: "Style", field: "outputItem.style.name", sortable: true, filter: true, resizable: true, maxWidth: 80 },
    { headerName: "Variety", field: "outputItem.variety.name", sortable: true, filter: true, resizable: true, maxWidth: 80 },
    { headerName: "Method", field: "outputItem.method.name", sortable: true, filter: true, resizable: true, maxWidth: 80 },
    { headerName: "Description", field: "outputItem.item.invoiceName", sortable: true, filter: true, resizable: true, minWidth:280, maxWidth: 300 }
    ]}
 ];



export default class InOutDetailGrid extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            selectedRow: null,
            propsDataChanged: false
        }
        this.onSelectionChanged = this.onSelectionChanged.bind(this);

    }




    onSelectionChanged() {
        var selectedNodes = this.gridApi.getSelectedNodes();
        var rowData = selectedNodes[0].data;
        this.setState({ selectedRow: rowData });
    }

    onEditGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };


    render() {

        const divStyle = {
            width: "100%",
            height: "32vh"

        };

        const gridData = (this.props.data) ? this.props.data : [{}];
        console.log("InOut Data Changed:");
        console.info(this.props.data);

        return (
            <div style={{width: "90vw"}}>
                <Card >
                    <Card.Header>In/Out Detail</Card.Header>
                    <Card.Body>
  
                        <div className="ag-theme-balham" >
                            <AgGridReact
                                rowSelection="single"
                                enableRangeSelection={false}
                                pagination={true}
                                columnDefs={columnDefs}
                                rowData={gridData}
                                animateRows={true}
                                onGridReady={this.onGridReady}
                                domLayout="autoHeight"
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                    </Card.Footer>

                </Card>
            </div>
        );
    }
}