import React, { Component } from "react";

import ConfigService from "../../../services/ConfigService";
import CoreService from "../../../services/CoreService";

import JavascriptHelpers from "../../../components/lib/Javascript/JavascriptHelpers";
import JwtToken from "../../../components/lib/tokens/JwtToken";

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default class LotWatcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRunning: false,
      watchListLastUpdated: JavascriptHelpers.isoUtcNow(),
    };

    this.coreService = new CoreService();
    this.watchInterval = new ConfigService().getWatchInterval(); // milliseconds

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.refreshWatchListQueue = this.refreshWatchListQueue.bind(this);
    this.interval = null;
  }

  componentWillUnmount() {
    this.stop();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.runLotWatcher !== prevProps.runLotWatcher) {
      console.log(
        "Lot Watcher was commanded to " + this.props.runLotWatcher
          ? "start"
          : "stop"
      );
      this.stop();
      if (this.props.runLotWatcher) {
        this.start();
      }
    }
  }

  async start() {
    if (this.state.isRunning) return;
    console.log("Starting Lot Watcher...");
    this.interval = setInterval(() => {
      this.refreshWatchListQueue();
    }, this.watchInterval);
  }

  stop() {
    console.log("Stopping Lot Watcher");
    this.setState({ isRunning: false });
    clearInterval(this.interval);
  }

  async refreshWatchListQueue() {
    try {
      this.props.onLotWatcherRunningStatusChange(true);
      this.setState({ isRunning: true });
      if (!this.props.userSession) return;
      this.props.updateSessionExpiration(JwtToken.toExpiredDateTime(this.props.userSession.token));
      if (JwtToken.isTokenExpired(this.props.userSession.token)) {
        console.log("The user token has expired.");
        console.info(this.props.userSession.token);
        console.groupEnd();
        this.stop();
        this.props.onExpiredToken(this.props.userSession.token);
        this.props.onUpdatedLotsEvent(false);
        this.setState({ isRunning: false });
        return;
      }
      const MAX_LOTS_TO_TOAST = 5;
      var refreshResponse = await this.coreService.fetchUpdatedLotList(
        this.state.watchListLastUpdated,
        MAX_LOTS_TO_TOAST,
        this.props.userSession.token
      );
      // TBD - look for error
      if (refreshResponse.hasError) {
        this.onUpdatedLotsEvent(false);
        this.props.onToastRequest(
          "error",
          "There was a problem looking for updated lots. " +
            refreshResponse.messages[0]
            ? "[" + refreshResponse.messages[0] + "]"
            : ""
        );
        return;
      }
      // refresh our flag upon success
      this.setState({ watchListLastUpdated: JavascriptHelpers.isoUtcNow() });
      var lotUpdateFlag = refreshResponse.dataResult;
      this.props.onUpdatedLotsEvent(lotUpdateFlag.updatedCount > 0);
      if (lotUpdateFlag.updatedCount > 0) {
        lotUpdateFlag.lots.forEach((lot) => {
          let lotMsg ="Lot " +lot.lotId +" (" +lot.supplier +") has been updated. Refresh history grid to see changes.";
          this.props.onToastRequest("success", lotMsg);
        });
      }
      this.setState({ isRunning: false });
    } finally {
      this.props.onLotWatcherRunningStatusChange(false);
    }
  }

  render() {
    return <span />;
  }
}
