import React, { Component } from "react";
import { Card } from "react-bootstrap";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";


const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";

const columnDefs = [
    { headerName: "Id", field: "item.item.id", sortable: true, filter: true, resizable: true, minWidth:80,  },
    { headerName: "Description", field: "item.item.name", sortable: true, filter: true, resizable: true, minWidth:200,  },
    { headerName: "Size", field: "item.size.name", sortable: true, filter: true, resizable: true,  },
    { headerName: "Style", field: "item.style.name", sortable: true, filter: true, resizable: true,  },
    { headerName: "Variety", field: "item.variety.name", sortable: true, filter: true, resizable: true,  },
    { headerName: "Method", field: "item.method.name", sortable: true, filter: true, resizable: true,  },
    { headerName: "Uom", field: "item.uom.name", sortable: true, filter: false, resizable: true,  },
    { headerName: "Received Qty", headerClass: "ag-right-aligned-header", field: "qty", sortable: true, filter: false, resizable: true,  cellStyle: {'text-align': 'right'}},
];


export default class ReceiptsDetailGrid extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            selectedRow: null,
            propsDataChanged: false
        }
        this.onSelectionChanged = this.onSelectionChanged.bind(this);


    }




    onSelectionChanged() {
        var selectedNodes = this.gridApi.getSelectedNodes();
        var rowData = selectedNodes[0].data;
        this.setState({ selectedRow: rowData });
    }



    onEditGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit(params)
    };


    render() {

        const divStyle = {
            width: "100%",
            height: "32vh"

        };

        const gridData = (this.props.data) ? this.props.data : [{}];
        console.log("InOut Data Changed:");
        console.info(this.props.data);

        return (
            <div>
                <Card >
                <Card.Header>Receipts Detail</Card.Header>
                    <Card.Body>
                        <div className="ag-theme-balham">
                            <AgGridReact
                                rowSelection="single"
                                enableRangeSelection={false}
                                pagination={true}
                                columnDefs={columnDefs}
                                rowData={gridData}
                                animateRows={true}
                                onGridReady={this.onGridReady}
                                domLayout="autoHeight"
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                    </Card.Footer>

                </Card>
            </div>
        );
    }
}