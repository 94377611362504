
export default class HealthFunctions
{

    static getHealthColor(healthStatus)
    {
        switch (healthStatus)
        {
            case "Healthy":
                return "green";
            case "Warning":
                return "yellow";
            case "Problem":
                return "red";
            default:
                return "purple";
        }
    }
}