import React, { Component } from "react";

import CoreService from "../../services/CoreService";

import HeaderCard from "./Components/HeaderCard";
import LotLiquidationPanel from "./Components/LotLiquidationSumPanel";
import LotDetailPanel from "./Components/LotDetailPanel";
import DetailsToggleCard from "./Components/DetailsToggleCard";

import "../../assets/css/main.css";
import { WorkflowStatus } from "../../models/WorkflowStatusType";
import LotWorkflowStatus from "../../models/LotWorkflowStatus";
import JavascriptHelpers from "../../components/lib/Javascript/JavascriptHelpers";


export default class LiquidationPage extends Component 
{

    constructor(props) 
    {
        super(props);
   
        this.state = {
          lotData: {},
          workflowData: {},
          // default rules
          panelView: "liquidation",
          serviceMap: null,
          workflowStatus: ""
        };

      this.coreService = new CoreService();

      this.refreshLotData = this.refreshLotData.bind(this);
      this.onLotWorkflowStatusChange = this.onLotWorkflowStatusChange.bind(this);
      this.onSingleRuleChange = this.onSingleRuleChange.bind(this);
    }

    async componentDidMount()
    {
        console.log("mounting lotpage")
        await this.refreshLotData(this.props.selectedLotId);
        console.log(this.state.lotData)
      }
  
      async componentDidUpdate(prevProps, prevState)
      {
        if (prevProps.selectedLotId !== this.props.selectedLotId){
          await this.refreshLotData(this.props.selectedLotId);
        }
      }
      async refreshLotData(lotId)
       {
        var newLotDataResponse = await this.coreService.fetchLotLiquidationSummary(lotId, this.props.userSession.token);
        // TBD - what if there is an error?
        var newLotData = newLotDataResponse.dataResult;
        // sort containers
        if (newLotData.lot)
        {
          var defaultSortedContainers = newLotData.lot.containers.sort((a,b) => (
            a.receivedDate.localeCompare(b.receivedDate) ||
            a.containerId.localeCompare(b.containerId)
          ));
          newLotData.lot.containers = defaultSortedContainers;
        }
        console.log("refreshing Workflow Data:");
        var newWorkflowStatusResponse = await this.coreService.fetchLotWorkflow(lotId, this.props.userSession.token);
        console.log("Workflow Status Response: ");
        console.info(newWorkflowStatusResponse);
        var newWorkflowStatus = {};
        if (newWorkflowStatusResponse.isRecordNotFound)
        {
          // this is probably a "legacy" lot which hasn't been updated with workflow status data.
          var initializingWorkflowStatus = new LotWorkflowStatus(this.props.userSession.gcid, lotId, WorkflowStatus.PREPARING.id, JavascriptHelpers.isoNow(),  this.props.userSession.uid);
          var postNewWorkflowStatusResponse = await this.coreService.postLotWorkflow(initializingWorkflowStatus, this.props.userSession.token);
          if (postNewWorkflowStatusResponse.hasError)
          {
            console.error("Error posting initialized workflow status.")
            this.props.onToastRequest("error", "Problem initializing workflow status for lot that did not have status previously.");
            return;
          }
          newWorkflowStatusResponse = await this.coreService.fetchLotWorkflow(lotId, this.props.userSession.token);
          // should be there now!
          if (newWorkflowStatusResponse.hasError)
          {
            console.error("Problem fetching newly initialized workflow status.");
            this.props.onToastRequest("error", "Problem fetching newly initialized workflow status for lot that did not have status previously.");
            return;
          }
        }

        newWorkflowStatus = newWorkflowStatusResponse.dataResult;
        console.log("This should just be the workflow status!");
        console.info(newWorkflowStatus);
        newLotData.lot.workflowStatus = newWorkflowStatus;
        console.log("With workflow data");
        console.info(newLotData);
        this.setState({
          lotData: newLotData,
          workflowStatus: newWorkflowStatus.workflowStatus
        });
        var newServiceMapDataResponse = await this.coreService.fetchServiceMap(this.props.userSession.token);
        // TBD - error handling
        var newServiceMapData = newServiceMapDataResponse.dataResult;
        console.log("refreshing Service Map Data: ");
        console.info(newServiceMapData);
        this.setState({serviceMap: newServiceMapData});
      }


      async onLotWorkflowStatusChange(newWorkflowStatus)
      {
        var response = await this.coreService.postLotWorkflow(newWorkflowStatus, this.props.userSession.token);
        var toastMsg = (response.hasError) ? 
          "There was an error attempting to post workflow change. " + response.messages[0]
          : "The lot workflow status was changed successfully";
        this.props.onToastRequest((response.hasError) ? "error": "success", toastMsg);
        await this.refreshLotData(this.props.selectedLotId);
        return response;
      }
  
  
  
      onSingleRuleChange(changeProp, changeValue)
      {
        if (changeProp === "panelView"){
            this.setState({panelView: changeValue});
  
        }
        else if (changeProp === "inOutGroupBy"){
          this.setState({inOutGroupBy: changeValue});
        }
        else if (changeProp === "showSaleDetail"){
          this.setState({showSaleDetail: changeValue})
        }
        else if (changeProp === "showContainers"){
          this.setState({showContainers: changeValue})
        }
        else if (changeProp === "netSales"){
          this.setState({netSales: changeValue});
        }
      }
    


    render() {

        if (!this.props.selectedLotId || !this.state.lotData.lot) 
        {
          return <span/>
        }
        const panel = (this.state.panelView === "liquidation") ?
          <LotLiquidationPanel 
            userSession = {this.props.userSession}
            userGcid = {this.props.userGcid}
            lotData={(this.state.lotData) ? this.state.lotData: {}} 
            selectedLotId={this.props.selectedLotId}
            onToastRequest={this.props.onToastRequest}
            workflowStatus={this.state.workflowStatus}
            onLotWorkflowStatusChange={this.onLotWorkflowStatusChange}
            data={this.state.lotData.lot} 
          />
        :
          <LotDetailPanel 
            userSession = {this.props.userSession}
            userGcid = {this.props.userGcid}
            lotData={(this.state.lotData) ? this.state.lotData: {}} 
            selectedLotId={this.props.selectedLotId}
            serviceMap={(this.state.serviceMap) ? this.state.serviceMap: []}
            onToastRequest={this.props.onToastRequest}
          />

        return (
            <div className="main">
                <div >
                    <HeaderCard 
                      userSession={this.props.userSession}
                      data={this.state.lotData.lot} 
                      workflowStatus={this.state.workflowStatus}
                      onLotWorkflowStatusChange={this.onLotWorkflowStatusChange}
                    />
                    <DetailsToggleCard
                        onSingleRuleChange={this.onSingleRuleChange}
                        panelView={this.state.panelView}
                    />
                    {panel}
                </div>
            </div>
        )

    }
}