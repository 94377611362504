import packageJson from "../../../../package.json";
import JavascriptHelpers from "../Javascript/JavascriptHelpers";


export default class CacheBuster{
    constructor(){
        this.cachedVer = "";
        this.buildVer  = "";
    }


    async refreshCacheAndReload() {
        if (caches) {
            caches.keys().then(async function(names) {
                await Promise.all(names.map(name => caches.delete(name)));
            });
            window.location.reload(true);
        }
    };


    async isLatestBuild(){
        var meta = await fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' });
        var metaJson = await meta.json();
        const latestVersionDate = metaJson.buildDate;
        const currentVersionDate = packageJson.buildDate;
        this.cachedVer = currentVersionDate;
        this.buildVer = latestVersionDate;  

        const shouldForceRefresh = JavascriptHelpers.isoDateIsAfter(latestVersionDate, currentVersionDate);
        //const shouldForceRefresh = this.buildDateGreaterThan(latestVersionDate,currentVersionDate); 
        return (!shouldForceRefresh);
    }

}