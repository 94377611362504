import React, { Component } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment";
import { toUsDollars, toUsComma } from "../../../components/lib/Javascript/jsUtils";
import lgsLogo from "../../../assets/images/logos/lgs-logo.png";

const styles = StyleSheet.create({

    page: {
        paddingRight: "5vw",
        paddingLeft: "5vw",
        paddingTop: "2vh"
    },

    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "3vh",
        //height: "10vh",
        width: "90vw"
    },

    headerLogo: {
        height: "77px",
        width: "102px"
    },

    headerAddress: {
        // fontSize: 9,
        width: "15vw",
        display: "block",
        textAlign: "center"
    },

    headerTable: {
        display: "table",
        // borderStyle: "solid",
        // borderColor: "black",
        // borderWidth: 1,
        // borderRightWidth: 0,
        // borderBottomWidth: 0,
        marginBottom: "2vh",
        width: "24vw",
        // fontSize: 10,
    },

    headerCol: {
        width: "12vw",
        textAlign: "justify"
        // borderStyle: "solid",
        // borderColor: '#bfbfbf',
        // borderWidth: 1,
        // // borderLeftWidth: 0,
        // // borderTopWidth: 0
    },

    headerCell: {
        // width: "auto",
        // margin: "auto",
        // margin: 5,
        // fontSize: 10,
        textAlign: "left",
        // paddingLeft: ".8vw"
    },
    table: {
        display: "table",
        width: "90vw",
        borderStyle: "solid",
        borderColor: "black",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginBottom: "2vh"
    },
    tableRow: {
        // margin: "auto",
        flexDirection: "row"
    },
    tableColHeader: {
        width: "15vw",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 1,
        backgroundColor: "#BED3F3",

    },
    tableCol: {
        width: "15vw",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        width: "15vw",
        margin: "auto",
        // margin: 5,
        // fontSize: 12,
        fontWeight: 500,
        textAlign: "left"
    },
    tableNumCellHeader: {
        width: "15vw",
        margin: "auto",
        // margin: 5,
        // fontSize: 12,
        fontWeight: 500,
        textAlign: "right",
        paddingRight: "1vw"
    },
    tableCell: {
        width: "15vw",
        margin: "auto",
        // margin: 5,
        // fontSize: 10,
        textAlign: "left",
        paddingLeft: ".2vw"
    },
    tableNumCell: {
        width: "15vw",
        margin: "auto",
        // margin: 5,
        // fontSize: 10,
        textAlign: "right",
        paddingRight: "1vw"
    },
    configTable: {
        display: "table",
        width: "35vw",
        borderStyle: "solid",
        borderColor: "black",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },

    expenseLine: {
        flexDirection: "row",
        justifyContent: "space-between"
    },

    totalsLine: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "1vh"
    },

    totalsCell: {
        borderStyle: "solid",
        borderColor: "lightgrey",
        borderBottomWidth: "1"
    },

    expenseList: {
        width: "25vw",
        // fontSize: 10
    },

    netTotal: {
        fontStyle: "bold"
    },

    containerTable: {
        display: "table",
        width: "28vw",
        borderStyle: "solid",
        borderColor: "black",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0

    }
});

const newStyles = StyleSheet.create({
    page: {

    },

    headerLogo: {
        height: "77px",
        width: "102px"
    },

    baseTable: {
        display: "table",
        borderStyle: "solid",
        borderWidth: "2px",
        borderCollapse: "collapse",
        fontSize: "12px",
        padding: "5px"
    },

    headerCell: {
        backgroundColor: "#BED3F3",
    },

    headerRow: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#BED3F3",
        fontSize: "15px",
        fontWeight: "bold",
        borderBottomStyle: "solid",
        borderBottomWidth: "2px"
    },

    tableRow: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
    },

    tableCell: {
        justifyContent: "center",
    }
});

export default class ObservationPdf extends Component {


    componentDidMount() {
        console.log("this.props.lot:")
        console.info(this.props.lot);
        console.log("this.props.summaryLotData:")
        console.info(this.props.summaryLotData);
        console.log("this.props.summaryServiceData:")
        console.info(this.props.summaryServiceData);
        console.log("this.props.saleLotData:")
        console.info(this.props.saleLotData);
        console.log("this.props.lotReturnData:")
        console.info(this.props.lotReturnData);
        console.log("summary lot data prop:")
        console.info(this.props.summaryLotData);
    }

    commodityRows() {

        var totalQty = 0;
        var rows = [];
        var descriptWidth = "";
        var varietyWidth = "";
        var methodWidth = "";
        var sizeWidth = "";
        var qtyWidth = "";
        var avgPriceWidth = "";
        var styleWidth = "";
        if (this.props.showVarieties && this.props.showMethods) {
            descriptWidth = "20vw";
            styleWidth = "12vw";
            varietyWidth = "10vw";
            methodWidth = "10vw";
            sizeWidth = "7vw";
            qtyWidth = "7vw";
            avgPriceWidth = "14vw";
        }
        else if ((!this.props.showVarieties && this.props.showMethods) || (this.props.showVarieties && !this.props.showMethods)) {
            descriptWidth = "22vw";
            styleWidth = "10vw";
            varietyWidth = "12vw";
            methodWidth = "12vw";
            sizeWidth = "10vw";
            qtyWidth = "10vw";
            avgPriceWidth = "14vw";

        }
        else {
            descriptWidth = "28vw";
            styleWidth = "14vw";
            varietyWidth = "11vw";
            methodWidth = "11vw";
            sizeWidth = "8vw";
            qtyWidth = "11vw"
        }

        // Header
        rows.push
            (
                <View style={newStyles.headerRow}>

                    <View style={[newStyles.headerCell, { width: descriptWidth }]} >
                        <Text style={[styles.tableCellHeader, { width: descriptWidth }]}>Commodity</Text>
                    </View>

                    {this.props.showVarieties && (
                        <View style={[newStyles.headerCell, { width: varietyWidth }]} >
                            <Text style={[styles.tableCellHeader, { width: varietyWidth }]}>Variety</Text>
                        </View>
                    )}

                    {this.props.showMethods && (
                        <View style={[newStyles.headerCell, { width: methodWidth }]} >
                            <Text style={[styles.tableCellHeader, { width: methodWidth }]}>Method</Text>
                        </View>
                    )}

                    <View style={[newStyles.headerCell, { width: sizeWidth }]}>
                        <Text style={[styles.tableCellHeader, { width: sizeWidth }]}>Size</Text>
                    </View>

                    <View style={[newStyles.headerCell, { width: styleWidth }]} >
                        <Text style={[styles.tableCellHeader, { width: styleWidth }]}>Style</Text>
                    </View>

                    <View style={[newStyles.headerCell, { width: qtyWidth }]}>
                        <Text style={[styles.tableNumCellHeader, { width: qtyWidth }]}>Qty</Text>
                    </View>

                    <View style={newStyles.headerCell}>
                        <Text style={styles.tableNumCellHeader}>Sale Amount $</Text>
                    </View>

                    <View style={[newStyles.headerCell, { width: avgPriceWidth }]}>
                        <Text style={[styles.tableNumCellHeader, { width: avgPriceWidth }]}>Avg Price $</Text>
                    </View>

                </View>
            )

            // Sales Rows
            // Filter for zero quantity
            this.props.summaryLotData.filter(ln => ln.activeQuantity > 0).forEach(element => {
                totalQty += element.activeQuantity;

                rows.push
                (
                        <View style={newStyles.tableRow}>
                            <View style={[newStyles.tableCell, { width: descriptWidth }]}>
                                <Text style={[styles.tableCell, { width: descriptWidth }]}>{element.commodity}</Text>
                        </View>

                        {this.props.showVarieties && (
                            <View style={[newStyles.tableCell, { width: varietyWidth }]}>
                                <Text style={[styles.tableCell, { width: varietyWidth }]}>{element.variety}</Text>
                            </View>
                        )}

                        {this.props.showMethods && (
                            <View style={[newStyles.tableCell, { width: methodWidth }]}>
                                <Text style={[styles.tableCell, { width: methodWidth }]}>{element.method}</Text>
                            </View>
                        )}

                        <View style={[newStyles.tableCell, { width: sizeWidth }]}>
                            <Text style={[styles.tableCell, { width: sizeWidth }]}>{element.size}</Text>
                        </View>

                        <View style={[newStyles.tableCell, { width: styleWidth }]}>
                            <Text style={[styles.tableCell, { width: styleWidth }]}>{element.style}</Text>
                        </View>

                        <View style={[newStyles.tableCell, { width: qtyWidth }]}>
                            <Text style={[styles.tableNumCell, { width: qtyWidth }]}> {toUsComma(element.activeQuantity)}</Text>
                        </View>

                        <View style={newStyles.tableCell}>
                            <Text style={styles.tableNumCell}>{toUsDollars(element.activeSaleAmount)}</Text>
                        </View>

                        <View style={[newStyles.tableCell, { width: avgPriceWidth }]}>
                            <Text style={[styles.tableNumCell, { width: avgPriceWidth }]}>{toUsDollars(element.price)}</Text>
                        </View>

                    </View>
                
                )
            }
        );

        // Totals
        rows.push
            (
                <View>

                    <View style={newStyles.tableRow}>

                        <View style={[newStyles.tableCell, { width: descriptWidth }]}>
                            <Text style={[styles.tableCell, { width: descriptWidth }]}></Text>
                        </View>

                        {this.props.showVarieties && (
                            <View style={[newStyles.tableCell, { width: varietyWidth }]}>
                                <Text style={[styles.tableCell, { width: varietyWidth }]}></Text>
                            </View>
                        )}

                        {this.props.showMethods && (
                            <View style={[newStyles.tableCell, { width: methodWidth }]}>
                                <Text style={[styles.tableCell, { width: methodWidth }]}></Text>
                            </View>
                        )}

                        <View style={[newStyles.tableCell, { width: sizeWidth }]}>
                            <Text style={[styles.tableCell, { width: sizeWidth }]}></Text>
                        </View>

                        <View style={[newStyles.tableCell, { width: styleWidth }]}>
                            <Text style={[styles.tableCell, { width: styleWidth, fontSize: "11px", textAlign: "left", paddingRight: "3px", fontWeight: "bolder" }]}>Total</Text>
                        </View>

                        <View style={[newStyles.tableCell, { width: qtyWidth }]}>
                            <Text style={[styles.tableNumCell, { width: qtyWidth, backgroundColor: "#BED3F3", borderBottom: 1, borderTop: .5 }]}>{toUsComma(totalQty)}</Text>
                        </View>

                        <View style={newStyles.tableCell}>
                            <Text style={[styles.tableNumCell, { backgroundColor: "#BED3F3", borderBottom: 1, borderTop: .5 }]}>{toUsDollars(this.props.totalSales)}</Text>
                        </View>

                        <View style={[newStyles.tableCell, { width: avgPriceWidth }]}>
                            <Text style={[styles.tableNumCell, { width: avgPriceWidth }]}></Text>
                        </View>

                    </View>

                    <View style={newStyles.tableRow}>

                        <View style={[newStyles.tableCell, { width: descriptWidth }]}>
                            <Text style={[styles.tableCell, { width: descriptWidth }]}></Text>
                        </View>

                        {this.props.showVarieties && (
                            <View style={[newStyles.tableCell, { width: varietyWidth }]}>
                                <Text style={[styles.tableCell, { width: varietyWidth }]}></Text>
                            </View>
                        )}

                        {this.props.showMethods && (
                            <View style={[newStyles.tableCell, { width: methodWidth }]}>
                                <Text style={[styles.tableCell, { width: methodWidth }]}></Text>
                            </View>
                        )}

                        <View style={[newStyles.tableCell, { width: sizeWidth }]}>
                            <Text style={[styles.tableCell, { width: sizeWidth }]}></Text>
                        </View>

                        <View style={[newStyles.tableCell, { width: styleWidth }]}>
                            <Text style={[styles.tableCell, { width: styleWidth, fontSize: "11px", textAlign: "left", paddingRight: "3px", fontWeight: "bolder" }]}>Total Pallets</Text>
                        </View>

                        <View style={[newStyles.tableCell, { width: qtyWidth }]}>
                            <Text style={[styles.tableNumCell, { width: qtyWidth, backgroundColor: "#BED3F3", borderBottom: 1 }]}>{toUsComma(this.props.totalPallets)}</Text>
                        </View>

                        <View style={newStyles.tableCell}>
                            <Text style={styles.tableNumCell}></Text>
                        </View>

                        <View style={[newStyles.tableCell, { width: avgPriceWidth }]}>
                            <Text style={[styles.tableNumCell, { width: avgPriceWidth }]}></Text>
                        </View>
                        
                    </View>

                </View>
            );

        return rows;
    }

    // Sales Configuration
    // filter out zero quantity lines 
    configRows() {
        var rows = [];
        this.props.saleLotData.filter(ln => ln.activeQuantity > 0).forEach(element => {
            rows.push(
                <View style={newStyles.tableRow}>
                    <Text style={[newStyles.tableCell, { width: "13vw" }]}>{element.configuration}</Text>
                    <Text style={[newStyles.tableCell, { width: "11vw", textAlign: "right" }]}>{toUsComma(element.activeQuantity)}</Text>
                    <Text style={[newStyles.tableCell, { width: "11vw", textAlign: "right" }]}>{toUsDollars(element.price)}</Text>
                </View>
            )
        });

        return rows;
    }

    expenseRows() {
        var rows = [];
        this.props.summaryServiceData.forEach(element => {
            rows.push(
                <View style={styles.expenseLine}>
                    <Text>{element.charge.serviceGroup}</Text>
                    <Text>{toUsDollars(element.activeExtAmount)}</Text>
                </View>
            )
        });

        return rows;
    }

    containerRows() {
        var rows = [];
        this.props.lot.containers.forEach(el => {
            rows.push(

                <View style={[newStyles.tableRow, { justifyContent: "space-evenly" }]}>
                    <Text style={[newStyles.tableCell, { width: "12vw", textAlign: "left" }]}>{el.containerId}</Text>
                    <Text style={[newStyles.tableCell, { width: "12vw", textAlign: "left" }]}> {moment(el.receivedDate).format("MM/DD/YYYY")}</Text>
                </View>

            )
        });

        return rows;
    }

    render() {
        var today = moment().format("MM/DD/YYYY");
        var salesTable = (this.props.showSaleDetail === "show") ?
            <View style={[newStyles.baseTable, { width: "35vw", minHeight: "" }]} >

                <View style={newStyles.headerRow} >

                    <Text style={[newStyles.headerCell, { width: "13vw" }]} >Configuration</Text>
                    <Text style={[newStyles.headerCell, { width: "11vw", textAlign: "right" }]}>Qty Sold</Text>
                    <Text style={[newStyles.headerCell, { width: "11vw", textAlign: "right" }]}>FOB $/Unit</Text>
                </View>
                {this.configRows()}
            </View>
            : <View style={{ width: "35vw" }}><View><Text>&nbsp;</Text></View></View>

        return (
            <Document>
                <Page size="A3" style={styles.page}>

                    {/* title div */}
                    <View><Text style={{ textAlign: "center", fontSize: "20px", color: "#203765", fontWeight: 900 }}>ACCOUNT OF SALE</Text></View>

                    {/* header table 1 row 4 columns */}
                    <View style={[newStyles.baseTable, { marginBottom: "20px", borderWidth: 0, justifyContent: "space-evenly" }]} >

                        {/* Row 1 */}
                        <View style={newStyles.tableRow}>

                            {/* Col 1, Logo */}
                            <View style={newStyles.tableRow}>
                                <View style={newStyles.tableCell}>
                                    <Image src={lgsLogo} style={newStyles.headerLogo} />
                                </View>

                                {/* Col 2, company address */}
                                <View style={[newStyles.tableCell, { alignSelf: "flex-end", marginLeft: "2px" }]}>
                                    <Text>LGS SPECIALTY SALES LTD </Text>
                                    <Text>1 Radisson Plaza, Suite 1022</Text>
                                    <Text>New Rochelle, NY 10801</Text>
                                    <Text>718-542-2200</Text>
                                </View>
                            </View>

                            {/* Col 3 centered ref and date */}
                            <View style={newStyles.tableCell}>
                                {/* blue color to use: 212d67 rgb(33, 45, 103) */}

                                {/* Inner Table 2 col 2 rows */}
                                <View style={[newStyles.baseTable, { borderWidth: 0, marginRight: "30px" }]}>

                                    {/* Row 1  */}
                                    <View style={newStyles.tableRow}>
                                        <Text >Reference </Text>
                                        <Text style={{ fontSize: 13, fontWeight: "bolder", color: "#203765", marginLeft: "2px" }}> {this.props.lot.lotId}</Text>
                                    </View>

                                    {/* Row 2 */}
                                    <View style={[newStyles.tableRow]}>
                                        <Text style={{ textAlign: "left" }}>As Of </Text>
                                        <Text style={{ textAlign: "left", marginLeft: "2px", minWidth: "14px" }}> {today}</Text>
                                    </View>
                                </View>

                            </View>


                            {/* Col 4, Shipping Info */}
                            <View style={newStyles.tableCell}>

                                {/* mini table 3 rows 2 cols */}
                                <View style={[newStyles.baseTable, { width: "20vw", borderWidth: 0 }]}>
                                    {/* Row 1 */}
                                    <View style={newStyles.tableRow}>
                                        <Text >Shipper </Text>
                                        <Text style={{ textAlign: "left", marginLeft: "2px", minWidth: "14px" }}> {this.props.lot.supplier}</Text>
                                    </View>

                                    {/* Row 2 */}
                                    <View style={newStyles.tableRow}>
                                        <Text >Vessel </Text>
                                        <Text style={{ textAlign: "left", marginLeft: "2px", minWidth: "14px" }}> {this.props.lot.vessel}</Text>
                                    </View>

                                    {/* Row 3 */}
                                    <View style={newStyles.tableRow}>
                                        <Text > Arrived</Text>
                                        <Text style={{ textAlign: "left", marginLeft: "2px", minWidth: "14px" }}> {moment(this.props.lot.dateArrived).format("MM/DD/YYYY")}</Text>
                                    </View>

                                    {/* End of mini table */}
                                </View>

                            </View>

                            {/* End of Row 1 */}
                        </View>

                        {/* End of Header Table */}
                    </View>

                    {/* Start of Commodity Table */}
                    <View style={[newStyles.baseTable, { marginBottom: "20px" }]}>
                        {this.commodityRows()}
                    </View>
                    {/* end of commodity table */}



                    {/* Section 2 Container config and Expenses */}
                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>

                        {/* Container Table */}
                        <View style={[newStyles.baseTable]}>

                            {/* Container header row */}
                            {/* Container table is somehow growing larger w/o new data for the lines and i cant tell why, extra loops perhaps */}
                            <View style={newStyles.headerRow}>

                                <Text style={[styles.tableCellHeader, { width: "12vw" }]}>Container</Text>
                                <Text style={[styles.tableCellHeader, { width: "12vw" }]}>Date</Text>
                            </View>

                            {/* Generated Container Rows */}
                            {this.containerRows()}

                        </View>


                        {/* Config Table */}
                        <View >
                            {salesTable}
                        </View>

                        {/* Expense list */}
                        <View style={[newStyles.baseTable, { width: "29vw" }]}>
                            <View style={newStyles.headerRow}><Text>Expenses</Text><Text>Amount $</Text></View>
                            {this.expenseRows()}

                            <View style={[styles.totalsLine, { borderBottom: 1 }]}>
                                <Text>Total Expenses</Text>
                                <Text style={styles.totalsCell}>{toUsDollars(this.props.lotReturnData.totalExpenses)}</Text>
                            </View>

                            <View style={[styles.totalsLine, { borderBottom: 0 }]}>
                                <Text>Total Return</Text>
                                <Text >{toUsDollars(this.props.lotReturnData.growerReturn)}</Text>
                            </View>

                            <View style={[styles.totalsLine, { borderBottom: 0 }]}>
                                <Text>Less: Marketing Fee</Text>
                                <Text style={styles.totalsCell}>{toUsDollars(this.props.lotReturnData.commission)}</Text>
                            </View>

                            <View style={[styles.totalsLine, { borderBottom: 1 }]}>
                                <Text>Less: Advances</Text>
                                <Text style={styles.totalsCell}>{toUsDollars(this.props.lotReturnData.advances)}</Text>
                            </View>

                            <View style={[newStyles.headerRow, { borderBottom: 3, borderBottomStyle: "double", fontSize: "15" }]}>
                                <Text style={styles.netTotal}>Net Proceeds</Text>
                                <Text style={styles.netTotal}>{toUsDollars(this.props.lotReturnData.netProceeds)}</Text>
                            </View>
                        </View>
                        {/* End of Expense List */}

                        {/* end of container config and expense row  */}
                    </View>

                    {/* end of doc */}
                </Page>
            </Document >

        )
    }
}