import axios from 'axios';
import HttpHelpers from "../components/lib/Http/HttpHelpers";


export default class HttpService
{

    static async get(domain, url, token)
    {
        try{
            var config = {method: 'GET'};
            if (token)
            {
                config.headers = {"Authorization": "bearer " + token};
            }
            var fullUrl = domain + url;
            const response = await axios( fullUrl, config);
            if (HttpHelpers.HttpStatusOk(response.status))
            {
                return {"httpStatusCode": response.status, "hasError": false, "messages": response.data.messages, "isRecordNotFound": response.data.isRecordNotFound, "dataResult": (response.data.data) ? response.data.data: response.data};
            }
            else 
            {
                var errMsgs = [response.statusText];
                errMsgs.push(response.data.messages);
                return {"httpStatusCode": response.status, "hasError": true, "messages": errMsgs, "isRecordNotFound": (!response.data) ? false: response.data.isRecordNotFound, "dataResult": (response.data.data) ? response.data.data: response.data};
            }
        }
        catch(err){
            return {"httpStatusCode": "", "hasError": true, "messages": [err.name, err.message], "isRecordNotFound": (!err.response.data) ? false: err.response.data.isRecordNotFound, "dataResult": null};
        }
        finally{

        }
    }


    static async postJson(domain, url, token, postObj, overrideConfig) {
        try {
            var config = (overrideConfig) ? overrideConfig: {method: 'POST'};
            if (token) {
                config = {
                    method: 'POST',
                    headers: {
                        "Authorization": "bearer " + token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'              
                    }
                }
            }
            var fullUrl = domain + url;
            console.group("Posting Info:");
            console.log(fullUrl);
            console.log(token);
            console.info(postObj);
            console.groupEnd();
            //let fullUrl = 'https://jsonplaceholder.typicode.com/users';
            const response = await axios.post(fullUrl, postObj, config);
            if (HttpHelpers.HttpStatusOk(response.status))
            {
                return {"httpStatusCode": response.status, "hasError": false, "messages": response.data.messages, "dataResult": response.data};
            }
            else 
            {
                var errMsgs = [response.statusText];
                errMsgs.push(response.data.messages);
                return {"httpStatusCode": response.status, "hasError": true, "messages": errMsgs, "dataResult": response.data};
            }
;        }
        catch(err){
            return {"httpStatusCode": "", "hasError": true, "messages": [err.name, err.message], "dataResult": null};
        }
        finally{

        }

    }


    static async fetchJson(domain, url, token) {
        try {
            var config = {
                method: 'GET'
            }
            if (token) {
                config = {
                    method: 'GET',
                    headers: {
                        "Authorization": "bearer " + token
                    }
                }
            }
            var fullUrl = domain + url;
            //let fullUrl = 'https://jsonplaceholder.typicode.com/users';
            const response = await axios(fullUrl, config);
            if (HttpHelpers.HttpStatusOk(response.status))
            {
                return {"httpStatusCode": response.status, "hasError": false, "messages": response.data.messages, "dataResult": response.data};
            }
            else 
            {
                var errMsgs = [response.statusText];
                errMsgs.push(response.data.messages);
                return {"httpStatusCode": response.status, "hasError": true, "messages": errMsgs, "dataResult": response.data};
            }
        }
        catch(err){
            return {"httpStatusCode": "", "hasError": true, "messages": [err.name, err.message], "dataResult": null};
        }
        finally{

        }
    }




}