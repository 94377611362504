import React, { Component } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
//import "bootstrap/dist/css/bootstrap.min.css";
import CreatableSelect from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt} from "@fortawesome/free-solid-svg-icons";

const createOption = (label) => ({
  label,
  value: label,
});

export default class ModalWorkflowStatusDialog extends Component 
{
  constructor() {
    super();
    this.state = {
      mapValues: null,
      newValue: null,
      dropOptions: [],
      errMsg: "",
      saving: false
    };

    this.onOkButtonClick = this.onOkButtonClick.bind(this);
    
  }

  componentDidUpdate(prevProps, prevState)
  {
    if (prevProps.show !== this.props.show)
    {
      if (this.props.show)
      {
        // initializing the dialog
        var newDropOptions = this.props.groupOptions.map(g => {
          return createOption(g);
        })
        this.setState({
          dropOptions: newDropOptions,
          errMsg: "",
          saving: false
        });
      }
    }
  }

  handleChange = (newValue, actionMeta) => {
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    if ((actionMeta.action === 'select-option') || (actionMeta.action === 'create-option') || (actionMeta.action === 'clear'))
    {
      this.setState({
        errMsg: "",
        newValue: newValue
      });
    }
  };
  handleInputChange = (inputValue, actionMeta) => {
    console.group('Input Changed');
    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    this.setState({errMsg: ""});
  };



  async onOkButtonClick(e) 
  {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.newValue || this.state.newValue.value.trim().toLowerCase()==="")
    {
      this.setState({errMsg: "Select a new Workflow Status for this lot."})
      return;
    }
    if (this.state.newValue.value.trim().toLowerCase() === this.props.existingWorkflowStatus.toLowerCase())
    {
      this.setState({errMsg: "It looks like you didn't change the workflow status? Cancel if you do not want to continue"});
      return;
    }
    this.setState({
      errMsg: "",
      saving: true
    });
    await this.props.onModalOkRequest(this.props.existingWorkflowStatus, this.state.newValue.value);
  }

  render() {
    console.info(this.props.dataRow);

    const savingDiv = (this.state.saving) ?
    <div><FontAwesomeIcon icon={faSyncAlt} spin={true} color={"green"}/>&nbsp; Saving...</div>
    : "";

    return (
      <Modal
        show={this.props.show} 
        onHide={this.props.onModalCloseRequest}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Lot Workflow Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row><Col>Current Lot Status: {this.props.existingWorkflowStatus}</Col></Row>
        <Row>&nbsp;</Row>
          <Row>
            <Col>
            <CreatableSelect
            autoFocus
            isClearable
            placeholder="Select LGS Service Group"
            defaultValue={createOption(this.props.existingWorkflowStatus)}
            onChange={this.handleChange}
            onInputChange={this.handleInputChange}
            options={this.state.dropOptions}
      />
            </Col>
          </Row>
          <Row style={{color: "red", fontSize: "11px"}}>{this.state.errMsg}</Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => this.onOkButtonClick(e)}
          >
            Ok
          </Button>
          <Button
            variant="secondary"
            onClick={(e) => this.props.onModalCloseRequest(e)}
          >
            Cancel
          </Button>
          <div>
            {savingDiv}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
