/*
                Gcid = gcid, 
                App = app,
                ClientId = clientId, 
                ClientName = clientName,
                AccessTokenLifetime = accessTokenLifetime,
                Enabled = enabled,
                Description = description,
                AllowedScopes = allowedScopes,
                Secret = secret,
                EncryptorPassword = encryptorPassword,
                Roles = roles ?? new List<string>(),
                Locations = locations ?? new List<string>()

*/

export default class AuthUser 
{
    constructor(gcid, clientId, clientName, description, secret, app, enabled, roles, locations, accessTokenLifetime)
    {
        this.gcid = gcid || "";
        this.app = app || "";
        this.clientId = clientId || "";
        this.clientName = clientName || "";
        this.description = description || "";
        this.secret = secret || "";
        this.enabled = enabled || true;
        this.roles = roles || [];
        this.locations = locations || [];
        this.accessTokenLifetime = accessTokenLifetime || 48000;
        this.encryptorPassword = "";
        this.allowOfflineAccess = false;
        this.alwaysSendClientClaims = true;
        this.allowedScopes = [];
    }


}