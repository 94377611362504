import React, { Component } from "react";

import ServiceMapGrid from "./Components/ServiceMapGrid";
import SupplierConfigGrid from "./Components/SupplierConfigGrid";

import "../../assets/css/main.css";



export default class DataManagementPage extends Component 
{
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    render() {
        return (
            <div className="main">
             
                <ServiceMapGrid
                    userSession={this.props.userSession}
                />
                <SupplierConfigGrid
                    userSession={this.props.userSession}
                />
            </div>
        )

    }
}